import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import authHeader from "../auth-header";

export default function HistoPerception() {

  const [histovhl, setHistoVhl] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/historiqueperception/" + id, {
        headers: authHeader(),
      })
      .then((response) => {
        setHistoVhl(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const filteredVehicles = histovhl.filter(
    (histovhl) => currentUser && histovhl.service === currentUser.service
  );
  
  const sortedVehicles = filteredVehicles.sort((a, b) => {
    // Tri décroissant par date
    return new Date(b.date) - new Date(a.date);
  });
  
  const groupedVehicles = {};
  
  const months = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ];
  
  sortedVehicles.forEach((vhl) => {
    const date = new Date(vhl.date);
    const day = date.getDate();
    const monthNumber = date.getMonth(); // Obtenez le numéro du mois (0-11)
    const year = date.getFullYear();
  
    // Obtenir le nom du mois à partir du tableau "months"
    const monthName = months[monthNumber];
  
    // Formater la date sous forme de chaîne "jour mois année"
    const formattedDate = `${day} ${monthName} ${year}`;
  
    if (!groupedVehicles[formattedDate]) {
      groupedVehicles[formattedDate] = [];
    }
    groupedVehicles[formattedDate].push(vhl);
  });
  
  
  

  return (
    <div>
  <h3 className="text-2xl font-bold text-center mb-6">Historique perceptions : {histovhl.length > 0 ? histovhl[0].nomvhl : ""}</h3>
  <nav className="h-full overflow-y-auto" aria-label="Directory">
    {Object.keys(groupedVehicles).map((date) => (
      <div key={date} className="relative">
        <div className="text-center sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900">
          <h3>{date}</h3>
        </div>
        <ul role="list" className="divide-y divide-gray-100">
          {groupedVehicles[date].map((vhl) => (
            <Link to={`/historiqueperception/detail/${vhl._id}`} className="block">
              <li key={vhl._id} className="flex gap-x-4 px-3 py-5">
                <div className="min-w-0 flex-1 font-semibold flex items-center justify-between">
                  <div>
                    <p className="text-base leading-6 text-gray-900">{vhl.utilisateur}</p>
                    <p className="mt-1 text-sm italic leading-5 text-gray-500">{vhl.emploi}</p>
                  </div>
                  <div className="text-sm italic leading-5 text-gray-500">{vhl.position}</div>
                </div>
                <ChevronRightIcon className="self-center h-5 w-5 text-gray-400" aria-hidden="true" />
              </li>
            </Link>
          ))}
        </ul>
      </div>
    ))}
  </nav>
</div>
  );
}
