import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import authHeader from "../auth-header";
import {TrashIcon } from '@heroicons/react/20/solid'


export default function RDVVhlService() {
  const [vhl, setVhl] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/", {
        headers: authHeader(),
      })
      .then((response) => {
        setVhl(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", {
        headers: authHeader(),
      })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const sortedVehicles = vhl
    .filter(
      (vhl) =>
        currentUser &&
        vhl.service === currentUser.service &&
        vhl.daterdv !== null &&
        vhl.daterdv !== undefined &&
        vhl.daterdv !== ""
    )
    .sort((a, b) => new Date(a.daterdv) - new Date(b.daterdv));

  const groupedVehicles = {};

  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  sortedVehicles.forEach((vhl) => {
    const date = new Date(vhl.daterdv);
    const day = date.getDate();
    const monthNumber = date.getMonth();
    const year = date.getFullYear();

    const monthName = months[monthNumber];
    const formattedDate = `${day} ${monthName} ${year}`;

    if (!groupedVehicles[formattedDate]) {
      groupedVehicles[formattedDate] = [];
    }
    groupedVehicles[formattedDate].push(vhl);
  });

  function handleListItemClick(Id) {
    const vehicleToUpdate = vhl.find((vehicle) => vehicle._id === Id);
  
    if (!vehicleToUpdate) {
      console.error(`Vehicle with ID ${Id} not found.`);
      return;
    }
  
    if (vehicleToUpdate.idusers.includes(currentUser.username)) {
      navigate(`/rdvvhlservice`);
    } else {
      // Créer un nouvel objet avec tous les champs du véhicule, mais avec `idusers` mis à jour
      const updatedVehicle = {
        ...vehicleToUpdate,
        idusers: [...vehicleToUpdate.idusers, currentUser.username],
      };
  
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/rdvgarage/update/${Id}`,
          updatedVehicle, // Envoyer l'objet complet
          { headers: authHeader() }
        )
        .then((res) => {
          // Mettre à jour l'état local
          const updatedRdvGarage = vhl.map((vehicle) =>
            vehicle._id === Id ? updatedVehicle : vehicle
          );
  
          setVhl(updatedRdvGarage);
          navigate(`/rdvvhlservice`);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  

  const deleteRDV = (id) => {
    axios
          .delete(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/" + id, { headers: authHeader() })
          .then(() => window.location = '/rdvvhlservice')
          .catch((error) => {
            console.log(error);
          });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto flex items-center">
          <h1 className="mt-6 text-base font-semibold text-xl leading-6 text-gray-900">
            RDV Garage à venir
          </h1>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    VHL
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Type de RDV
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Remarques
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Supprimer
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {Object.keys(groupedVehicles).map((date) => (
                  <React.Fragment key={date}>
                    {groupedVehicles[date].map((vhl) => (
                      <tr key={vhl._id} onClick={() => handleListItemClick(vhl._id)} className={!vhl.idusers.includes(currentUser.username) ? 'bg-green-200/40' : ''}>
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <td className="whitespace-nowrap px-3 py-5 font-semibold text-sm text-red-700">{date}</td>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="flex items-center">
                          <div className="h-11 w-11 flex-shrink-0">
                            <img className="h-10 w-10 flex-none rounded-full bg-gray-50 object-cover" src={vhl.image} alt="" />
                          </div>
                          <div className="ml-4">
                            <div className="font-medium text-gray-900">{vhl.nomvhl}</div>
                            <div className="font-semibold text-gray-900">{vhl.dossardorigine}</div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">{vhl.typerdv}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className={`text-gray-900 whitespace-normal max-w-xl`}>
                          {vhl.remarque}
                        </div>
                      </td>
                      <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    <div className='flex flex-col items-center'>
                      <a
                        onClick={() => {
                          if (window.confirm('Êtes-vous sûr de vouloir supprimer ce RDV ?')) {
                            deleteRDV(vhl._id);
                          }
                        }}
                        className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-red-700 focus:outline-none"
                      >
                        <TrashIcon
                className="w-6 h-6 text-red-500 cursor-pointer"
              /><span className="sr-only">, {vhl._id}</span>
                      </a>
                      </div>
                    </td>
                    </tr>
                    
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
