import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import authHeader from "../auth-header";

function EditMateriel(props) {
  const [materiel, setMateriel] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});

  const { id } = useParams();

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/materiels/" + id, { headers: authHeader() })
        .then((response) => {
          setMateriel(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setisLoading(false);
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", {
          headers: authHeader(),
        })
        .then((response) => {
          setCurrentUser(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isLoading, id]);

  function onChangeNom(e) {
    let updatedValue = { nom: e.target.value };
    setMateriel((materiel) => ({
      ...materiel,
      ...updatedValue,
    }));
  }

  function onChangeCategorie(e) {
    let updatedValue = { categorie: e.target.value };
    setMateriel((materiel) => ({
      ...materiel,
      ...updatedValue,
    }));
  }

  function onChangeAlerte(e) {
    let updatedValue = { alerte: e.target.value };
    setMateriel((materiel) => ({
      ...materiel,
      ...updatedValue,
    }));
  }

  function onChangeEmplacement(e) {
    let updatedValue = { emplacement: e.target.value };
    setMateriel((materiel) => ({
      ...materiel,
      ...updatedValue,
    }));
  }

  function onChangeRemarques(e) {
    let updatedValue = { remarques: e.target.value };
    setMateriel((materiel) => ({
      ...materiel,
      ...updatedValue,
    }));
  }

  function onChangeImage(e) {
    let updatedValue = { image: e.target.value };
    setMateriel((materiel) => ({
      ...materiel,
      ...updatedValue,
    }));
  }

  function onChangeImagePerception(e) {
    let updatedValue = { imageperception: e.target.value };
    setMateriel((materiel) => ({
      ...materiel,
      ...updatedValue,
    }));
  }

  function onSubmit(e) {
    e.preventDefault();

    console.log(materiel);

    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/materiels/update/" + id, materiel, {
        headers: authHeader(),
      })
      .then((res) => {
        window.location = "/materiel/toutmateriel";
        console.log(res.data)});

    
  }

  return (
    <div className="centered-page">
      <h2 style={{ marginBottom: "20px", fontWeight: "bold" }}>Editer un matériel :</h2>
      <form onSubmit={onSubmit}>
        <TextField
          id="nom-materiel"
          label="Nom du matériel"
          type="text"
          autoComplete="current-password"
          size="small"
          required
          value={materiel.nom}
          onChange={onChangeNom}
          className="field-margin"
          sx={{ marginBottom: "10px", width: "95%" }}
        />

        <FormControl fullWidth className="field-margin">
          <InputLabel id="categorie-label">Catégorie</InputLabel>
          <Select
            labelId="categorie-label"
            id="categorie-select"
            value={materiel.categorie || ""}
            label="Catégorie"
            onChange={onChangeCategorie}
            sx={{ marginBottom: "10px", width: "95%" }}
          >
            <MenuItem value="CNP">CNP</MenuItem>
            <MenuItem value="COF / Perben">COF / Perben</MenuItem>
            <MenuItem value="Divers">Divers</MenuItem>
            <MenuItem value="Rens aérien / Drones">Rens aérien / Drones</MenuItem>
            <MenuItem value="Image">Image</MenuItem>
            <MenuItem value="Interpel / Armement">Interpel / Armement</MenuItem>
            <MenuItem value="Micro">Micro</MenuItem>
            <MenuItem value="Mobilité">Mobilité</MenuItem>
            <MenuItem value="SIC">SIC</MenuItem>
            <MenuItem value="Track">Track</MenuItem>
            <MenuItem value="Verte">Verte</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth className="field-margin">
          <InputLabel id="alerte-label">Alerte</InputLabel>
          <Select
            labelId="alerte-label"
            id="alerte-select"
            value={materiel.alerte || ""}
            label="Alerte"
            onChange={onChangeAlerte}
            sx={{ marginBottom: "10px", width: "95%" }}
          >
            <MenuItem value="Alerte 1">Alerte 1</MenuItem>
            <MenuItem value="Alerte 2">Alerte 2</MenuItem>
            <MenuItem value="Spare">Spare</MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="emplacement-materiel"
          label="Emplacement"
          type="text"
          autoComplete="current-password"
          size="small"
          required
          value={materiel.emplacement}
          onChange={onChangeEmplacement}
          className="field-margin"
          sx={{ marginBottom: "10px", width: "95%" }}
        />

        <TextField
          id="remarques-materiel"
          label="Remarques"
          type="text"
          autoComplete="current-password"
          size="small"
          multiline
          rows={4}
          value={materiel.remarques}
          onChange={onChangeRemarques}
          className="field-margin"
          sx={{ marginBottom: "10px", width: "95%" }}
        />

        <TextField
          id="image-materiel"
          label="Image"
          type="text"
          autoComplete="current-password"
          size="small"
          value={materiel.image}
          onChange={onChangeImage}
          className="field-margin"
          sx={{ marginBottom: "10px", width: "95%" }}
        />

        <TextField
          id="image-materiel"
          label="Image Perception"
          type="text"
          autoComplete="current-password"
          size="small"
          value={materiel.imageperception}
          onChange={onChangeImagePerception}
          className="field-margin"
          sx={{ marginBottom: "10px", width: "95%" }}
        />

        <div className="form-group text-center">
          <input type="submit" value="Valider" className="btn btn-primary" />
        </div>
      </form>
    </div>
  );
}

export default EditMateriel;
