import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { TrashIcon } from '@heroicons/react/20/solid';
import authHeader from "../auth-header";

function PageHisto() {
  const [histovhl, setHistoVhl] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/historiqueperception/detail/" + id, {
        headers: authHeader(),
      })
      .then((response) => {
        setHistoVhl(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const deleteP = (id) => {
    console.log(histovhl._id);
    console.log(id);
    axios
      .delete(process.env.REACT_APP_BASE_URL + "/api/historiqueperception/" + id, { headers: authHeader() })
      .then((res) => {
        setIsLoading(true);
        window.location = `/historiqueperception/${histovhl.idvhl}`;
        console.log(res.data);
      })
  }

  return (
<div>
<h3 className="text-2xl font-bold text-center mb-6">Perception du {new Date(histovhl.date).toLocaleDateString()}</h3>

    <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Position
        </dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {histovhl.position}
        </dd>
      </div>
      <div className="bg-white px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Emploi
        </dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {histovhl.emploi}
        </dd>
      </div>
      <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">Utilisateur</dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {histovhl.utilisateur}
        </dd>
      </div>
      <div className="bg-white px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">
          Date
        </dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
        {new Date(histovhl.date).toLocaleDateString()}
        </dd>
      </div>
      <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
        <dt className="text-sm font-medium leading-6 text-gray-900">Kilométrage</dt>
        <dd className="ml-auto text-sm leading-6 text-gray-700">
          {histovhl.km}
        </dd>
      </div>

      {currentUser.roles === "Admin" && (
        <div className="flex justify-center items-center mt-8">
          <button
            type="button"
            onClick={() => {
              if (window.confirm('Êtes-vous sûr de vouloir supprimer cette perception ?')) {
                deleteP(histovhl._id);
              }
            }}
            className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-red-800 border-2 border-red-800 shadow-sm hover:bg-red-800 focus:outline-none"
          >
            <TrashIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            Supprimer la perception
          </button>
        </div>
      )}
      </div>

      

  );
}

export default PageHisto;
