import React, { useEffect, useState } from "react";
import axios from "axios";
import List from "@mui/material/List";
import { useParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { ListItemButton, ListItemSecondaryAction } from "@mui/material";
import authHeader from "../auth-header";

export default function HistoMateriels() {

  const [histomateriel, setHistoMateriel] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/histoperceptionmateriel/", {
        headers: authHeader(),
      })
      .then((response) => {
        const filteredEntries = response.data.filter(entry =>
          JSON.stringify(entry).includes(id)
        );
        setHistoMateriel(filteredEntries);
      })
      .catch((error) => {
        console.log(error);
      });
  
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);  
  

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {histomateriel
      .sort((a, b) => new Date(b.date) - new Date(a.date)) // Tri par date décroissante
      .map((histomateriel) => (
        <React.Fragment key={histomateriel._id}>
          <ListItemButton
            alignItems="flex-start"
            component="a"
            href={`/detailhistoperception/${histomateriel._id}`}
          >
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    sx={{ fontWeight: "bold" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {histomateriel.nomperception}
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {histomateriel.typeperception}
                  </Typography>
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.secondary"
              >
                {new Date(histomateriel.date).toLocaleDateString()}
              </Typography>
            </ListItemSecondaryAction>
          </ListItemButton>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </List>
  );
}
