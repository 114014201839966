import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ResponsiveAppBar from "./components/NavBar";
import EditVHL from "./components/PageAdmin/edit-vhl.component";
import CreateVHL from "./components/PageAdmin/create-vhl.component";
import CreateUser from "./components/PageAdmin/create-user.component";
import ListUser from "./components/PageAdmin/user-list.component";
import Home from "./components/Home";
import ListetousVHL from "./components/ListVHL";
import PageVHL from "./components/PageVHL/PageVHL";
import PageAdmin from "./components/PageAdmin/PageAdmin";
import Perception from "./components/PageVHL/Perception";
import Disponibles from "./components/Disponible";
import Mission from "./components/Missions";
import PageDivers from "./components/PagesDivers/PageDivers";
import Exercice from "./components/PagesDivers/Exercices";
import ListeAdminVHL from "./components/PageAdmin/ListAdminVHL";
import HistoPerception from "./components/PageVHL/HistoPerception";
import PageHisto from "./components/PageVHL/PageHisto";
import DmdGarage from "./components/DmdGarage";
import ListDmdGarage from "./components/PagesDivers/ListDmdGarage";
import DetailDmdGarage from "./components/PagesDivers/DetailDmdGarage";
import Liaison from "./components/PagesDivers/Liaison";
import Garage from "./components/PagesDivers/Garage";
import SIC from "./components/PagesDivers/SIC";
import AccueilReservation from "./components/Reservations/AccueilReservation";
import ReservationForm from "./components/Reservations/ReservationForm";
import ReservationList from "./components/Reservations/ReservationList";
import Login from "./components/login";
import axios from "axios";
import authHeader from "./components/auth-header";
import { React, useState, useEffect } from "react";
import DetailResa from "./components/Reservations/ReservationDetail";
import Tutos from "./components/Tutos";
import CalendrierResa from "./components/Reservations/CalendrierResa";
import HomeMateriel from "./components/MaterielsFOR/HomeMateriel";
import ToutMateriel from "./components/MaterielsFOR/ToutMateriel";
import ListeCNP from "./components/MaterielsFOR/PagesMateriels/CNP";
import DetailMateriel from "./components/MaterielsFOR/DetailMateriel";
import ListeCOF from "./components/MaterielsFOR/PagesMateriels/COF";
import ListeDivers from "./components/MaterielsFOR/PagesMateriels/Divers";
import ListeImage from "./components/MaterielsFOR/PagesMateriels/Image";
import ListeInterpel from "./components/MaterielsFOR/PagesMateriels/Interpel";
import ListeMicro from "./components/MaterielsFOR/PagesMateriels/Micro";
import ListeMobilite from "./components/MaterielsFOR/PagesMateriels/Mobilite";
import ListeDrone from "./components/MaterielsFOR/PagesMateriels/Drone";
import ListeSIC from "./components/MaterielsFOR/PagesMateriels/SIC";
import ListeTrack from "./components/MaterielsFOR/PagesMateriels/Track";
import ListeVerte from "./components/MaterielsFOR/PagesMateriels/Verte";
import HistoRevision from "./components/PageVHL/HistoRevision";
import NewRevision from "./components/PageVHL/NewRevision";
import NewTache from "./components/Garage/CreateTache";
import CalendrierTachesV2 from "./components/Garage/CalendrierTachesV2";
import DetailTache from "./components/Garage/DetailTache";
import CreateMateriel from "./components/MaterielsFOR/CreateMateriel";
import EditMateriel from "./components/MaterielsFOR/EditMateriel";
import NewPerception from "./components/MaterielsFOR/Perception/NewPerception";
import ListPerceptionCours from "./components/MaterielsFOR/Perception/ListPerceptionCours";
import DetailPerception from "./components/MaterielsFOR/Perception/DetailPerception";
import EditPerception from "./components/MaterielsFOR/Perception/EditPerception";
import EditUser from "./components/PageAdmin/edit-user";
import Reintegration from "./components/MaterielsFOR/Perception/Reintegration";
import ListHistoPerceptions from "./components/MaterielsFOR/ListHistoPerceptions";
import DetailHistoPerception from "./components/MaterielsFOR/DetailHistoPerception";
import HistoMateriels from "./components/MaterielsFOR/HistoMateriels";
import RDVVhl from "./components/Garage/RDVVhl";
import CreateRDV from "./components/Garage/CreateRDVVHL";
import RDVVhlService from "./components/PageAdmin/RDVVhlService";
import VhlsGarage from "./components/Garage/VhlsGarage";
import PageHistoArmoire from "./components/PageAdmin/PageHistoArmoire";
import ReservationFormTime from "./components/Reservations/ReservationFormTime";
import { Notifications } from 'react-push-notifiy';
import EditTache from "./components/Garage/EditTache";
import ListForGarageDmdInterv from "./components/Garage/ListForGarageDmdInterv";
import CreateRevisionVHL from "./components/Garage/CreateRevisionVHL";

const theme = createTheme({
  palette: {
    primary: {
      main: "#06257E", // Couleur principale
    },
  },
});

const locale = "fr"; // Set the locale you imported or use the default
dayjs.locale(locale);


function App() {
  const [currentUser, setCurrentUser] = useState({});
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
        if (response.data) {
          setConnected(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale={locale}>
      <Router>
      <Notifications />
        <ThemeProvider theme={theme}>
          <ResponsiveAppBar />
          <br />
          {connected ? (
            <Routes>
              <Route path="/accueil" element={<Home />} />
              <Route path="/" element={<Home />} exact />

              <Route path="/listvhl" element={<ListetousVHL />} />
              <Route path="/pageadmin" element={<PageAdmin />} />
              <Route path="/histoarmoire" element={<PageHistoArmoire />} />
              <Route path="/pagevhl/:id" element={<PageVHL />} />
              <Route path="/perception/:id" element={<Perception />} />
              <Route path="/historiquerevision/:id" element={<HistoRevision />} />
              <Route path="/newrevision/:id" element={<NewRevision />} />
              <Route path="/create" element={<CreateVHL />} />
              <Route path="/listuser" element={<ListUser />} />
              <Route path="/disponibles" element={<Disponibles />} />
              <Route path="/mission" element={<Mission />} />
              <Route path="/reservations" element={<AccueilReservation />} />
              <Route path="/reservations/form" element={<ReservationForm />} />
              <Route path="/reservations/formtime" element={<ReservationFormTime />} />
              <Route path="/reservations/list" element={<ReservationList />} />
              <Route path="/reservations/calendrier" element={<CalendrierResa />} />
              <Route path="/reservations/:id" element={<DetailResa />} />
              <Route path="/divers" element={<PageDivers />} />
              <Route path="/divers/exercice" element={<Exercice />} />
              <Route path="/divers/liaison" element={<Liaison />} />
              <Route path="/divers/garage" element={<Garage />} />
              <Route path="/divers/sic" element={<SIC />} />
              <Route path="/tutos" element={<Tutos/>} />
              <Route
                path="/pageadmin/listadminvhl"
                element={<ListeAdminVHL />}
              />
              <Route
                path="/historiqueperception/:id"
                element={<HistoPerception />}
              />
              <Route
                path="/historiqueperception/detail/:id"
                element={<PageHisto />}
              />
              <Route path="/dmdintervgarage" element={<DmdGarage />} />
              <Route path="/listdmdgarage" element={<ListDmdGarage />} />
              <Route path="/listdmdgarageall" element={<ListForGarageDmdInterv />} />
              <Route path="/newtache" element={<NewTache />} />
              <Route path="/calendriertachesv2" element={<CalendrierTachesV2 />} />
              <Route path="/planninggarage/:id" element={<DetailTache />} />
              <Route path="edittache/:id" element={<EditTache />} />
              <Route path="/rdvvhl" element={<RDVVhl />} />
              <Route path="/newrdvvhl" element={<CreateRDV />} />
              <Route path="/rdvvhlservice" element={<RDVVhlService />} />
              <Route path="/vhlsgarage" element={<VhlsGarage />} />
              <Route path="/revisionvhlgarage" element={<CreateRevisionVHL />} />

              <Route
                path="/dmdintervgarage/:id"
                element={<DetailDmdGarage />}
              />

              <Route path="/edit/:id" element={<EditVHL />} />
              <Route path="/edituser/:id" element={<EditUser />} />


              <Route path="/user" element={<CreateUser />} />

              <Route path="/login" element={<Login />} />


              <Route path="/materiel" element={<HomeMateriel />} />
              <Route path="/newmateriel" element={<CreateMateriel />} />
              <Route path="/editmateriel/:id" element={<EditMateriel />} />
              <Route path="/materiel/toutmateriel" element={<ToutMateriel />} />
              <Route path="/pagemateriel/:id" element={<DetailMateriel />} />
              <Route path="/listing-cnp" element={<ListeCNP />} />
              <Route path="/listing-cof" element={<ListeCOF />} />
              <Route path="/listing-divers" element={<ListeDivers />} />
              <Route path="/listing-image" element={<ListeImage />} />
              <Route path="/listing-interpel" element={<ListeInterpel />} />
              <Route path="/listing-micro" element={<ListeMicro />} />
              <Route path="/listing-vhl" element={<ListeMobilite />} />
              <Route path="/listing-drone" element={<ListeDrone />} />
              <Route path="/listing-sic" element={<ListeSIC />} />
              <Route path="/listing-track" element={<ListeTrack />} />
              <Route path="/listing-verte" element={<ListeVerte />} />
              <Route path="/newperception" element={<NewPerception />} />
              <Route path="/listperceptions" element={<ListPerceptionCours />} />
              <Route path="/perceptionmateriel/:id" element={<DetailPerception />} />
              <Route path="/update/:id" element={<EditPerception />} />
              <Route path="/reintegration/:id" element={<Reintegration />} />
              <Route path="/listhistoperceptions" element={<ListHistoPerceptions />} />
              <Route path="/detailhistoperception/:id" element={<DetailHistoPerception />} />
              <Route path="/histoperceptionmateriel/:id" element={<HistoMateriels />} />


            </Routes>
          ) : (
            <Routes>
              {" "}
              <Route path="*" element={<Login />} />
            </Routes>
          )}
        </ThemeProvider>
      </Router>
    </LocalizationProvider>
  );
}

export default App;
