import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import authHeader from '../auth-header';

export default function ListForGarageDmdInterv() {
  const [dmdGarage, setDmdGarage] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + '/api/dmdintervgarage/', { headers: authHeader() })
      .then((response) => {
        setDmdGarage(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + '/api/users/CurrentUser', { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function handleListItemClick(Id) {
    const vehicleToUpdate = dmdGarage.find((vehicle) => vehicle._id === Id);

    if (!vehicleToUpdate) {
      console.error(`Vehicle with ID ${Id} not found.`);
      return;
    }

    if (vehicleToUpdate.idusers.includes(currentUser.username)) {
      navigate(`/dmdintervgarage/${Id}`);
    } else {
      const updatedIdUsers = [...vehicleToUpdate.idusers, currentUser.username];

      axios.post(`${process.env.REACT_APP_BASE_URL}/api/dmdintervgarage/update/${Id}`, { idusers: updatedIdUsers }, { headers: authHeader() })
        .then((res) => {
        console.log(res.data);
        const updatedDmdGarage = dmdGarage.map((vehicle) =>
            vehicle._id === Id ? { ...vehicle, idusers: updatedIdUsers } : vehicle
          );

        setDmdGarage(updatedDmdGarage)
        navigate(`/dmdintervgarage/${Id}`);
        
        })
        .catch((error) => {
        console.error(error);
        });  
    }
  }


  return (
  <div>
      <h3 className="text-2xl font-bold text-center mb-6">Demandes d'interventions</h3>
      <nav className="h-full overflow-y-auto" aria-label="Directory">
        {dmdGarage.slice().reverse().map((vhl) => (
          <div key={vhl._id} onClick={() => handleListItemClick(vhl._id)} className="relative">
            <ul role="list" className="divide-y divide-gray-100">
              <Link to={`/dmdintervgarage/${vhl._id}`} className="block">
                <li className="flex gap-x-4 px-3 py-5">
                {!vhl.idusers.includes(currentUser.username) && (
                    <span className="absolute left-2 top-2 block h-3.5 w-3.5 rounded-full bg-red-400 ring-2 ring-white" />
                  )}                
                  <img className="h-12 w-12 flex-none rounded-full bg-gray-50 object-cover" src={vhl.image} alt="" />
                  <div className="min-w-0 flex-1">
                    <p className="text-base font-semibold leading-6 text-gray-900">{vhl.nomvhl}</p>
                    <p className="mt-1 truncate text-sm italic leading-5 text-gray-500">{vhl.dossardorigine}</p>
                  </div>
                  <div className='flex items-center'>
                  <p className="mr-4 text-base leading-6 text-gray-900">{vhl.service}</p>
                  <ChevronRightIcon className="self-center h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </li>
              </Link>
            </ul>
          </div>
        ))}
      </nav>
    </div>

  );
}
