import React, { useState, useEffect } from 'react';
import axios from 'axios';
import authHeader from "../auth-header";
import Datepicker from "tailwind-datepicker-react";

const ReservationForm = () => {
  const [idvhl, setIdVHL] = useState(null);
  const [nomvhl, setVHL] = useState('');
  const [service, setService] = useState('');
  const [imagevhl, setImage] = useState('');
  const [dossardorigine, setDossard] = useState('');
  const [datedebut, setDateDebut] = useState('');
  const [datefin, setDateFin] = useState('');
  const [typereservation, setTypeResa] = useState('');
  const [nom, setNomResa] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [show, setShow] = useState(false);
  const [showEnd, setShowEnd] = useState(false);

  const options = {
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    maxDate: new Date("2030-01-01"),
    minDate: new Date("2023-01-01"),
    theme: {
      background: "bg-gray-50 dark:bg-gray-700",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "",
      input: "",
      inputIcon: "",
      selected: "bg-gray-100"
    },
    icons: {
      prev: () => <span>Previous</span>,
      next: () => <span>Next</span>,
    },
    datepickerClassNames: "top-12 w-64",
    defaultDate: new Date(),
    language: "fr",
    disabledDates: [],
    weekDays: ["Lu", "Mar", "Mer", "Jeu", "Ve", "Sa", "Di"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Selectionner la date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  };

  const handleChangeTypeResa = (e) => {
    setTypeResa(e.target.value);
  };

  const handleChangeNom = (e) => {
    setNomResa(e.target.value);
  };

  const filterVehicles = () => {
    const filteredVehicles = vehicles.filter((vehicle) => {
      const overlappingReservations = reservations.filter((reservation) => {
        const reservationStartDate = new Date(reservation.datedebut);
        const reservationEndDate = new Date(reservation.datefin);
        const selectedStartDate = new Date(datedebut);
        const selectedEndDate = new Date(datefin);
        return (
          (reservationStartDate <= selectedStartDate && reservationEndDate >= selectedStartDate) ||
          (reservationStartDate <= selectedEndDate && reservationEndDate >= selectedEndDate) ||
          (reservationStartDate >= selectedStartDate && reservationEndDate <= selectedEndDate)
        );
      });
      return !overlappingReservations.some((reservation) => reservation.idvhl === vehicle._id);
    });

    const filterVehicles2 = filteredVehicles.filter((vehicle) => {
      if (currentUser.service === "FOR") {
        return vehicle.service === currentUser.service && vehicle.typedevhl === "Véhicules de liaison";
      }
      if (currentUser.service === "CNFIS") {
        return vehicle.service === currentUser.service && vehicle.typedevhl === "CPI";
      }
      if (currentUser.service === "CAB") {
        return vehicle.service === currentUser.service && vehicle.typedevhl === "Véhicules de liaison";
      }
      return vehicle.service === currentUser.service;
    });

    setFilteredVehicles(filterVehicles2);
  };

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + '/api/vhl/', { headers: authHeader() })
      .then((response) => {
        setVehicles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + '/api/reservations/', { headers: authHeader() })
      .then((response) => {
        setReservations(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    filterVehicles();
  }, [vehicles, reservations, datedebut, datefin]);

  const handleDateStartClick = () => {
    setShow(true);
  };

  const handleDateEndClick = () => {
    setShowEnd(true);
  };

  const handleDateChange = (date, field) => {
    if (field === 'datedebut') {
      setDateDebut(date);
    } else if (field === 'datefin') {
      setDateFin(date);
    }
  };

  const handleVehicleSelect = (vehicle) => {
    setIdVHL(vehicle._id);
    setVHL(vehicle.nomvhl);
    setImage(vehicle.image);
    setDossard(vehicle.dossardorigine);
    setService(vehicle.service);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (idvhl && typereservation && nom) {
      const reservation = {
        idvhl,
        nomvhl,
        service,
        imagevhl,
        dossardorigine,
        typereservation,
        datedebut,
        datefin,
        nom,
      };

      axios.post(process.env.REACT_APP_BASE_URL + '/api/reservations/add', reservation, { headers: authHeader() })
        .then((res) => {
          console.log(res.data);
          window.location = '/reservations';
        })
        .catch((error) => {
          console.error(error);
        });

      
    } else {
      console.log('Veuillez remplir tous les champs obligatoires');
    }
  };

  return (
    <div className="centered-page">
      <h3 className="text-2xl font-bold text-center mb-6">Réservation d'un véhicule</h3>
      <form onSubmit={handleSubmit}>
      <div>
			<Datepicker 
      options={options} 
      value={datedebut} 
      onChange={(date) => handleDateChange(date, 'datedebut')} 
      show={show} 
      setShow={setShow} 
      onClick={handleDateStartClick}
      />
		</div>
    <h3 className="text-2xl font-semibold text-center mb-2 mt-2"> - </h3>

      <div>
			<Datepicker 
      options={options} 
      value={datefin} 
      onChange={(date) => handleDateChange(date, 'datefin')} 
      show={showEnd} 
      setShow={setShowEnd} 
      onClick={handleDateEndClick}
      />
		</div>
    {datedebut && datefin && (
            <fieldset className="mt-12 mb-5">
  <legend className="text-base font-bold leading-6 text-gray-900">Véhicules disponibles :</legend>
  <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
    {filteredVehicles.map((vhl, vhlID) => (
      <div key={vhlID} className="relative flex items-center py-4">
        <img src={vhl.image} alt="" className="h-10 w-10 ml-12 flex-none rounded-full bg-gray-50 object-cover" />
        <div className="min-w-0 ml-4 flex-1 text-sm leading-6 flex items-center">
          <label htmlFor={`vhl-${vhl._id}`} className="select-none text-gray-900 font-semibold">
            {vhl.nomvhl}
          </label>
        </div>
        <div className="ml-3 flex h-6 items-center mr-12">
          <input
            id={`vhl-${vhl._id}`}
            name="selectedVehicle"
            type="radio"
            className="h-4 w-4 rounded border-gray-300 text-blue-900 focus:ring-blue-900"
            onChange={() => handleVehicleSelect(vhl)}
          />
        </div>
      </div>
            ))}
          </div>
        </fieldset>
    )}

<div className="relative mt-5">
      <label
        htmlFor="vhl"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Type de réservation
      </label>
      <input
        type="text"
        name="resa"
        id="resa"
        required
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={typereservation}
        onChange={handleChangeTypeResa}
      />
    </div>

    <div className="relative mt-5">
      <label
        htmlFor="vhl"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Nom
      </label>
      <input
        type="text"
        name="nom"
        id="nom"
        required
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={nom}
        onChange={handleChangeNom}
      />
    </div>
        <div className="form-group text-center">
        <button
    type="submit"
    className="mt-8 mx-auto rounded-lg bg-blue-900 px-3 py-2 text-base font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
  >
    Valider
  </button>
        </div>
      </form>
    </div>
  );
};

export default ReservationForm;
