import React, { useState, useEffect } from "react";
import { PlusCircleIcon, PencilSquareIcon, UserGroupIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import axios from "axios";
import authHeader from "../auth-header";

function PageAdmin() {
  const [currentUser, setCurrentUser] = useState({});
  const buttonStyle = "mb-4 inline-flex items-center justify-center rounded-md bg-white border-2 border-indigo-900 px-4 py-1.5 text-indigo-900 font-semibold shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600";

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
    <div className="flex flex-col items-center">
      <button
        type="button"
        onClick={() => (window.location.href = "/create")}
        className={buttonStyle}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          Créer un véhicule
          <PlusCircleIcon className="ml-1 -mr-0.5 h-5 w-5" aria-hidden="true" />
        </span>
      </button>

      <button
        type="button"
        onClick={() => (window.location.href = "/pageadmin/listadminvhl")}
        className={buttonStyle}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          Editer / Supprimer un VHL
          <PencilSquareIcon className="ml-1 -mr-0.5 h-5 w-5" aria-hidden="true" />
        </span>
      </button>

      <button
        type="button"
        onClick={() => (window.location.href = "/listuser")}
        className={buttonStyle}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          Liste des utilisateurs
          <UserGroupIcon className="ml-1 -mr-0.5 h-5 w-5" aria-hidden="true" />
        </span>
      </button>
      </div>

    
      <div className="relative mb-4">
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t border-gray-300" />
    </div>
    <div className="relative flex justify-center">
      <span className="bg-white px-2 text-gray-500">
        <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
      </span>
    </div>
  </div>

      {currentUser.service === "FOR" ? (
    <div className="flex flex-col items-center">
      <button
        type="button"
        onClick={() => (window.location.href = "/histoarmoire")}
        className={"mb-4 text-sm font-bold uppercase inline-flex items-center justify-center rounded-lg bg-white opacity-90 border-2 border-indigo-900 px-4 py-1.5 text-indigo-900 shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          Historique Armoire Clés
        </span>
      </button>
    </div>
  ) : (
    ""
  )}

    </div>
  );
}

export default PageAdmin;
