import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import Datepicker from "tailwind-datepicker-react";
import authHeader from "../auth-header";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const CreateRDV = () => {
  const [selectedRDV, setselectedRDV] = useState("");
  const [typerdv, setTypeRDV] = useState("");
  const [daterdv, setDateRDV] = useState("");
  const [remarque, setRemarque] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [vhl, setVhl] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [show, setShow] = useState(false);


  const typeRDVOptions = [
    { id: 1, name: 'Check VHL' },
    { id: 2, name: 'Visite' },
    { id: 3, name: 'Accident' },
    { id: 4, name: 'Confection de plaques' },
    { id: 5, name: 'Liquide de frein' },
    { id: 6, name: 'Pneumatique' },
    { id: 7, name: 'Frein' },
    { id: 8, name: 'Distribution' },
    { id: 9, name: 'Embrayage' },
    { id: 10, name: 'Recherche de panne' },
    { id: 11, name: 'Récupération de VHL' },
    { id: 12, name: 'Autre' },
  ];

  const options = {
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    maxDate: new Date("2030-01-01"),
    minDate: new Date("2023-01-01"),
    theme: {
      background: "bg-gray-50 dark:bg-gray-700",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "",
      input: "",
      inputIcon: "",
      selected: "bg-gray-100"
    },
    icons: {
      prev: () => <span>Previous</span>,
      next: () => <span>Next</span>,
    },
    datepickerClassNames: "top-12 w-64",
    defaultDate: new Date(),
    language: "fr",
    disabledDates: [],
    weekDays: ["L", "M", "M", "J", "V", "S", "D"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Selectionner la date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/", { headers: authHeader() })
      .then((response) => {
        setVehicles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const [query, setQuery] = useState('')
  const [selectedVHL, setSelectedVHL] = useState([]);

  const filteredVHL = query === ''
    ? vehicles
    : vehicles.filter((vhl) => {
        const lowerCaseQuery = query.toLowerCase();
        return (
          (vhl.nomvhl && vhl.nomvhl.toLowerCase().includes(lowerCaseQuery)) ||
          (vhl.dossardorigine && vhl.dossardorigine.toLowerCase().includes(lowerCaseQuery))
        );
      });

        const handleChangeTypeRDV = (value) => {
            setTypeRDV(value.name);
            setselectedRDV(value);
          };
          
          const onChangeDateRDV = (date) => {
              setDateRDV(date);
          };

          const onChangeVHL = (vhl) => {
            setSelectedVHL(vhl);
            setVhl(vhl);
            setQuery("");
          };

  const handleDateClick = () => {
    setShow(true);
  };

  const handleChangeRemarque = (e) => {
    setRemarque(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const rdv = {
      idvhl: vhl._id,
      nomvhl: vhl.nomvhl,
      service: vhl.service,
      dossardorigine: vhl.dossardorigine,
      daterdv,
      remarque,
      image: vhl.image,
      typerdv,
      idusers: currentUser.username,
    };

    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/add", rdv, {
        headers: authHeader(),
      })
      .then((res) => {
        window.location = "/rdvvhl";
      })
      .catch((error) => {
        console.error(error);
      }); 
  };
  

  return (

      <div className="max-w-full md:max-w-xl mx-auto">
        <h3 className="text-2xl font-bold text-center mb-6">Créer un RDV</h3>
      
      <form onSubmit={onSubmit}>

      <Combobox as="div" value={selectedVHL} onChange={onChangeVHL}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">Véhicule</Combobox.Label>
      <div className="relative mt-2">
      <Combobox.Input
        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        onChange={(event) => setQuery(event.target.value)}
        displayValue={(vhl) => `${selectedVHL?.nomvhl || ''} - ${selectedVHL?.dossardorigine || ''} - ${selectedVHL?.service || ''}`}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredVHL.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredVHL.map((vhl) => (
              <Combobox.Option
                key={vhl.id}
                value={vhl}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex">
                      <img src={vhl.image} alt="" className="h-6 w-6 flex-shrink-0 rounded-full mr-5 object-cover" />
                      <span className={classNames('truncate', selected && 'font-bold')}>{vhl.nomvhl}</span>
                      <span
                        className={classNames(
                          'ml-5 truncate text-gray-500 font-medium italic',
                          active ? 'text-indigo-200' : 'text-gray-500'
                        )}
                      >
                        {vhl.dossardorigine}
                      </span>
                      <span
                        className={classNames(
                          'ml-5 truncate text-gray-500',
                          active ? 'text-indigo-200' : 'text-gray-500'
                        )}
                      >
                        {vhl.service}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>


    <Listbox value={selectedRDV} onChange={handleChangeTypeRDV}>
      {({ open }) => (
        <>
          <Listbox.Label className="mt-5 block text-sm font-medium leading-6 text-gray-900">Type de RDV</Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-4 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">{selectedRDV.name}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {typeRDVOptions.map((rdv) => (
                  <Listbox.Option
                    key={rdv.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={rdv}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {rdv.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>

    <div className="mt-2">
        <textarea
          rows={2}
          name="remarque"
          id="remarque"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
          placeholder={'Détails / Explications'}
          value={remarque}
          onChange={handleChangeRemarque}
        />
      </div>

      <div className="mt-4">
      <Datepicker 
        options={options} 
        value={vhl.daterdv} 
        onChange={(date) => onChangeDateRDV(date)} 
        show={show} 
        setShow={setShow} 
        onClick={handleDateClick}
        />
        </div>

<div className="form-group text-center">
  <button
    type="submit"
    className="mt-4 mx-auto rounded-lg bg-blue-900 px-3 py-2 text-base font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
  >
    Valider
  </button>
</div>
      </form>
    </div>
  );
};

export default CreateRDV;
