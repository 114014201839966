import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, MenuItem, FormControl, Select, InputLabel }from '@mui/material';
import authHeader from "../auth-header";

const CreateMateriel = () => {
  const [nom, setNom] = useState('');
  const [categorie, setCategorie] = useState('');
  const [alerte, setAlerte] = useState('');
  const [remarques, setRemarques] = useState('');
  const [emplacement, setEmplacement] = useState('');
  const [image, setImage] = useState('');
  const [imageperception, setImagePerc] = useState('');
  const [currentUser, setCurrentUser] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);


  const handleChangeNom = (e) => {
    setNom(e.target.value);
  };

  const handleChangeCategorie = (e) => {
    setCategorie(e.target.value);
  };

  const handleChangeAlerte = (e) => {
    setAlerte(e.target.value);
  };

  const handleChangeRemarques = (e) => {
    setRemarques(e.target.value);
  };

  const handleChangeEmplacement = (e) => {
    setEmplacement(e.target.value);
  };

  const handleChangeImage = (e) => {
    setImage(e.target.value);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    console.log(selectedFile);
  };

  useEffect(() => {
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (nom && categorie) {
      let imagePerceptionValue = imageperception;
      if (alerte === "Alerte 1") {
        imagePerceptionValue = 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/68/A1_Logo_Red.jpg/480px-A1_Logo_Red.jpg';
      }
      if (alerte === "Alerte 2") {
        imagePerceptionValue = 'https://upload.wikimedia.org/wikipedia/commons/thumb/1/1f/Autostrada_A2_Italia.svg/300px-Autostrada_A2_Italia.svg.png';
      }
      if (alerte === "Spare") {
        imagePerceptionValue = 'https://st2.depositphotos.com/5943796/11310/v/600/depositphotos_113102904-stock-illustration-initial-letter-s-swoosh-blue.jpg';
      }
  
        const materiel = {
          nom,
          categorie,
          alerte,
          remarques,
          emplacement,
          image,
          imageperception: imagePerceptionValue,
          //noticeUrl: selectedFile ? [fileId] : [] // Ajouter le fileId dans l'array noticeUrl si un fichier a été importé
        };
  
        axios.post(process.env.REACT_APP_BASE_URL + '/api/materiels/add', materiel, { headers: authHeader() })
          .then((res) => {
            console.log(res.data);
            window.location = '/materiel/toutmateriel';
          })
          .catch((error) => {
            console.error(error);
          });  
    } else {
      console.log('Veuillez remplir tous les champs obligatoires');
    }
  };
  

  return (
    <div className="centered-page">
     <h2 style={{ marginBottom: '20px', fontWeight: 'bold' }}>Ajouter un matériel :</h2>
     <form onSubmit={handleSubmit} enctype="multipart/form-data">

        <TextField
          id="outlined-password-input"
          label="Nom du matériel"
          type="text"
          autoComplete="current-password"
          size="small"
          required
          value={nom}
          onChange={handleChangeNom}
          className="field-margin"
          sx={{ marginBottom: '10px', width: '95%' }}
        />

            <FormControl fullWidth className="field-margin">
              <InputLabel id="demo-simple-select-label">
                Catégorie
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={categorie}
                label="categorie"
                onChange={handleChangeCategorie}
                sx={{ marginBottom: "10px", width: "95%" }}
              >
                <MenuItem value="CNP">CNP</MenuItem>
                <MenuItem value="COF / Perben">COF / Perben</MenuItem>
                <MenuItem value="Divers">Divers</MenuItem>
                <MenuItem value="Rens aérien / Drones">Rens aérien / Drones</MenuItem>
                <MenuItem value="Image">Image</MenuItem>
                <MenuItem value="Interpel / Armement">Interpel / Armement</MenuItem>
                <MenuItem value="Micro">Micro</MenuItem>
                <MenuItem value="Mobilité">Mobilité</MenuItem>
                <MenuItem value="SIC">SIC</MenuItem>
                <MenuItem value="Track">Track</MenuItem>
                <MenuItem value="Verte">Verte</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth className="field-margin">
              <InputLabel id="demo-simple-select-label">
                Alerte
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={alerte}
                label="alerte"
                onChange={handleChangeAlerte}
                sx={{ marginBottom: "10px", width: "95%" }}
              >
                <MenuItem value="Alerte 1">Alerte 1</MenuItem>
                <MenuItem value="Alerte 2">Alerte 2</MenuItem>
                <MenuItem value="Spare">Spare</MenuItem>
              </Select>
            </FormControl>

            <TextField
          id="outlined-password-input"
          label="Emplacement"
          type="text"
          autoComplete="current-password"
          size="small"
          required
          value={emplacement}
          onChange={handleChangeEmplacement}
          className="field-margin"
          sx={{ marginBottom: '10px', width: '95%' }}
        />

        <TextField
          id="outlined-password-input"
          label="Remarques"
          type="text"
          autoComplete="current-password"
          size="small"
          multiline
          rows={4}
          value={remarques}
          onChange={handleChangeRemarques}
          className="field-margin"
          sx={{ marginBottom: '10px', width: '95%' }}
        />

        <TextField
          id="outlined-password-input"
          label="Image (Url)"
          type="text"
          autoComplete="current-password"
          size="small"
          value={image}
          onChange={handleChangeImage}
          className="field-margin"
          sx={{ marginBottom: '10px', width: '95%' }}
        />

        {/* <input
          type="file"
          onChange={handleFileChange}
          accept=".jpg, .jpeg, .png"
        /> */}

        <div className="form-group text-center">
          <input type="submit" value="Valider" className="btn btn-primary" />
        </div>
      </form>
    </div>
  );
};

export default CreateMateriel;
