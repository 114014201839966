import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Calendar, DateLocalizer, momentLocalizer } from 'react-big-calendar';
import * as dates from '../../utils/dates';
import authHeader from "../auth-header";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/fr'
import { useNavigate } from 'react-router-dom';
const mLocalizer = momentLocalizer(moment);



const ColoredDateCellWrapper = ({ children }) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  });

export default function CalendrierResa() {
  const [reservations, setReservations] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  const navigate = useNavigate();


  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + '/api/reservations/', { headers: authHeader() })
      .then(response => {
        setReservations(response.data);
      })
      .catch(error => {
        console.log(error);
      });
      
    axios.get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const filteredVehicles = reservations.filter(
    (reservation) => currentUser && reservation.service === currentUser.service
  );

  const { components, defaultDate, max, views } = {
    components: {
      timeSlotWrapper: ColoredDateCellWrapper,
    },
    defaultDate: new Date(),
    max: dates.add(dates.endOf(new Date(), 'day'), -1, 'hours'),
    views: ['month'] ,
  };

  const events = filteredVehicles.map((reservation) => ({
    title: reservation.nomvhl,
    id: reservation._id,
    start: new Date(reservation.datedebut),
    end: new Date(reservation.datefin),
  }));

  return (
    <>
      <div style={{ height: "100%" }}>
        <Calendar
          components={components}
          defaultDate={defaultDate}
          events={events}
          onSelectEvent={(event) => navigate(`/reservations/${event.id}`)}
          localizer={mLocalizer}
          max={max}
          showMultiDayTimes
          step={60}
          views={views}
          style={{ minHeight: 600, height: "100%" }}
        />
      </div>
    </>
  );
}
