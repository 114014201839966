import React, { Fragment, useState } from "react";
import axios from "axios";
import authHeader from "../auth-header";
import 'tailwindcss/tailwind.css';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

function CreateUser() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const serviceOptions = [
    { id: 1, name: 'FOR' },
    { id: 2, name: 'FI' },
    { id: 3, name: 'FSP' },
    { id: 4, name: 'SMS' },
    { id: 5, name: 'DT' },
    { id: 6, name: 'CNFIS' },
    { id: 7, name: 'EMOPS' },
    { id: 8, name: 'EMSF' },
    { id: 9, name: 'GARAGE' },
    { id: 10, name: 'MAG' },
    { id: 11, name: 'FA' },
    { id: 12, name: 'R&D' },
    { id: 13, name: 'CAB' },
    { id: 14, name: 'AMS' },
    { id: 15, name: 'CYNO' },
    { id: 16, name: 'DPA' },
    { id: 17, name: 'CIO' },
    { id: 18, name: 'CTAO' },
    { id: 19, name: 'GAV' },
    { id: 20, name: 'XPRH' },
  ];

  const roleOptions = [
    { id: 1, name: 'Admin' },
    { id: 2, name: 'Normal' },
  ];

  const [selectedService, setSelectedService] = useState(serviceOptions[0]);
  const [selectedRole, setSelectedRole] = useState(roleOptions[0]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const onChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const user = {
      username,
      service: selectedService.name,
      password,
      roles: selectedRole.name,
    };
    console.log(user);

    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/users/add", user, {
        headers: authHeader(),
      })
      .then((res) => {
        setUsername("");
        setPassword("");
        window.location = "/listuser";
      })
  };

  return (
    <div>
      <h3 className="text-2xl font-bold text-center mb-6">Créer un nouvel utilisateur</h3>
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
            Nom d'utilisateur
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="username"
              id="username"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Nom d'utilisateur"
              value={username}
              onChange={onChangeUsername}
            />
          </div>
        </div>

        <div className="mb-4">
        <Listbox value={selectedService} onChange={setSelectedService}>
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Service</Listbox.Label>
              <div className="relative mt-2">
                <Listbox.Button
                  className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <span className="block truncate">{selectedService.name}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Listbox.Options
  as="ul"
  className="absolute z-10 max-w-xs w-full py-1 text-base bg-white border border-gray-300 divide-y divide-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
>
  {serviceOptions.map((option) => (
    <Listbox.Option
      key={option.id}
      value={option}
    >
      {({ active, selected }) => (
        <li
          onClick={() => setSelectedService(option)}
          className={classNames(
            active ? 'bg-indigo-600 text-white' : 'text-gray-900',
            'cursor-pointer select-none relative px-4 py-2'
          )}
        >
          {option.name}
          {selected ? (
            <span
              className={classNames(
                active ? 'text-white' : 'text-indigo-600',
                'absolute inset-y-0 left-0 flex items-center pl-3'
              )}
            >
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          ) : null}
        </li>
      )}
    </Listbox.Option>
  ))}
</Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        </div>

        <div className="mb-4">
        <Listbox value={selectedRole} onChange={setSelectedRole}>
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Rôle</Listbox.Label>
              <div className="relative mt-2">
                <Listbox.Button
                  className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <span className="block truncate">{selectedRole.name}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Listbox.Options
                    as="ul"
                    className="absolute z-10 mt-1 max-h-60 w-full py-1 text-base bg-white border border-gray-300 divide-y divide-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    {roleOptions.map((option) => (
                      <Listbox.Option
                        key={option.id}
                        value={option}
                      >
                        {({ active, selected }) => (
                          <li
                            onClick={() => setSelectedRole(option)}
                            className={classNames(
                              active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                              'cursor-pointer select-none relative px-4 py-2'
                            )}
                          >
                            {option.name}
                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 left-0 flex items-center pl-3'
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </li>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        </div>

        <div className="mb-4">
          <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
            Mot de passe
          </label>
          <div className="mt-2">
            <input
              type="password"
              name="password"
              id="password"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Mot de passe"
              value={password}
              onChange={onChangePassword}
            />
          </div>
        </div>

        <div className="form-group text-center">
  <button
    type="submit"
    className="mx-auto rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
  >
    Enregistrer
  </button>
</div>
      </form>
    </div>
  );
}

export default CreateUser;
