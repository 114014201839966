import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TrashIcon } from '@heroicons/react/20/solid';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import authHeader from "../auth-header";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function PageHistoArmoire(props) {
  const [histo, setHisto] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});

  const fetchHistoData = () => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/histoarmoire/", { headers: authHeader() })
      .then((response) => {
        setHisto(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchCurrentUser = () => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", {
        headers: authHeader(),
      })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteHisto = (id) => {
    axios
      .delete(process.env.REACT_APP_BASE_URL + "/api/histoarmoire/" + id, { headers: authHeader() })
      .then()
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isLoading) {
      fetchHistoData();
      fetchCurrentUser();
      setisLoading(false);
    }
  }, [isLoading]);

  return (
    <div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto flex items-center">
            <h1 className="mt-6 text-xl font-semibold leading-6 text-gray-900">Historiques d'ouverture Armoire FOR</h1>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-0">
                      Utilisateur
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Service
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Date
                    </th>
                    {currentUser.username === "Dje" ? (
                    <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                      Action
                    </th>
                    ) : (
                        ""
                    )}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {histo.map((histoarmoire) => (
                    <tr key={histoarmoire._id}>
                      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <div className="text-gray-900">{histoarmoire.user}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">{histoarmoire.service}</div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="text-gray-900">
                        {format(new Date(histoarmoire.date), 'dd/MM/yyyy HH:mm', { locale: fr })}
                        </div>
                      </td>
                      {currentUser.username === "Dje" ? (
                      <td className="whitespace-nowrap text-sm text-gray-500">
                        <div className='flex flex-col items-center'>
                          <button
                            onClick={() => {
                              if (window.confirm('Êtes-vous sûr de vouloir supprimer cette ligne ?')) {
                                deleteHisto(histoarmoire._id);
                              }
                            }}
                            className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-red-700 focus:outline-none"
                          >
                            <TrashIcon
                                className="w-6 h-6 text-red-500 cursor-pointer"
                            />
                          </button>
                        </div>
                      </td>
                      ) : (
                        ""
                    )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHistoArmoire;
