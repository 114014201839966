import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar,
  Box,
  Button,
  Typography,
  ListItemButton,
  ListItemText,
  Tabs,
  Tab,
  Card,
  CardMedia,
  CardContent,
  List
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import KeyIcon from '@mui/icons-material/Key';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import MicIcon from '@mui/icons-material/Mic';
import ForestIcon from '@mui/icons-material/Forest';
import MasksIcon from '@mui/icons-material/Masks';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ConstructionIcon from '@mui/icons-material/Construction';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import authHeader from "../../auth-header";

const label = { inputProps: { 'aria-label': 'Switch' } };

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const initialPerception = {
    image: [],
    cof: [],
    drone: [],
    micro: [],
    verte: [],
    cnp: [],
    interpel: [],
    mobilite: [],
    divers: [],
    sic: [],
    track: [],
  };

function Reintegration(props) {
  const [perception, setPerception] = useState(initialPerception);
  const [isLoading, setisLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedItems, setSelectedItems] = useState([]);


  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/perceptionmateriel/" + id, { headers: authHeader() })
        .then((response) => {
          setPerception(response.data);

        })
        .catch((error) => {
          console.log(error);
        });
      setisLoading(false);
    }
  }, [id, isLoading]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleReintegrateSelected = () => {
    const filteredPerception = { ...perception };
  
    switch (value) {
      case 0:
        filteredPerception.image = [];
        break;
      case 1:
        filteredPerception.cof = [];
        break;
      case 2:
        filteredPerception.drone = [];
        break;
      case 3:
        filteredPerception.micro = [];
        break;
      case 4:
        filteredPerception.verte = [];
        break;
      case 5:
        filteredPerception.cnp = [];
        break;
      case 6:
        filteredPerception.interpel = [];
        break;
      case 7:
        filteredPerception.mobilite = [];
        break;
      case 8:
        filteredPerception.divers = [];
        break;
      case 9:
        filteredPerception.sic = [];
        break;
      case 10:
        filteredPerception.track = [];
        break;        
      default:
        break;
    }
  
    setPerception(filteredPerception);
    setSelectedItems([]);
    
    axios
        .post(process.env.REACT_APP_BASE_URL + '/api/perceptionmateriel/update/' + id, filteredPerception, {
          headers: authHeader(),
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.error(error);
        });

  };

 const handleDeletePerception = () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette perception ?")) {
        axios
      .delete(process.env.REACT_APP_BASE_URL + "/api/perceptionmateriel/" + id, { headers: authHeader() })
      .then((res) => {
        window.location = "/listperceptions";
        console.log(res.data);
      })
      }
 }
  

  const PerceptionList = ({ list }) => {
    return (
        <div>
      <List>
        {list &&
          list.map((item, index) => (
            <ListItemButton key={index} alignItems="flex-start" href={`/pagemateriel/${item.materiel._id}`}>
              {item && item.materiel && (
                <Avatar
                  alt={item.materiel.nom}
                  src={item.materiel.imageperception}
                  sx={{ width: 32, height: 32, mr: 1 }}
                />
              )}
              {item && item.materiel && (
                <ListItemText
                  primary={item.materiel.nom}
                  primaryTypographyProps={{ variant: 'body2' }}
                  sx={{ alignSelf: 'flex-start' }}
                />
              )}
            </ListItemButton>
          ))}
      </List>
      </div>
    );
  };

  return (
    <div style={{ display: "grid", gridTemplateRows: "1fr auto" }}>

<Card sx={{ maxWidth: '100vh', textAlign: 'center' }}>
      <CardMedia
        sx={{ height: 100 }}
        image="/images/homemateriel.png"
        title="materiels"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Réintégration des matériels : <strong>{perception.nomperception}</strong>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Type de perception : {perception.typeperception}
        </Typography>
      </CardContent>
    </Card>

    <Button
        variant="contained"
        color="primary"
        onClick={handleReintegrateSelected}
      >
        Réintégrer les matériels
      </Button>

      <Button
        variant="outlined"
        color="error"
        onClick={handleDeletePerception}
        disabled={
            perception.image.length !== 0 ||
            perception.cof.length !== 0 ||
            perception.drone.length !== 0 ||
            perception.micro.length !== 0 ||
            perception.verte.length !== 0 ||
            perception.cnp.length !== 0 ||
            perception.interpel.length !== 0 ||
            perception.mobilite.length !== 0 ||
            perception.divers.length !== 0 ||
            perception.sic.length !== 0 ||
            perception.track.length !== 0
        }
        >
        Supprimer la perception        
        </Button>

<div>
  <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
  >
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={value}
      onChange={handleChange}
      aria-label="Matériels perçus"
      sx={{ borderRight: 1, borderColor: 'divider', width: '200px', height: '100vh'}}
    >
<Tab
  label={
    <Box>
      {perception.image.length === 0 && <CheckCircleIcon sx={{ color: 'green', marginRight: '10px' }} />}
      <CameraAltIcon sx={{ marginRight: "8px" }} />
      Image
    </Box>
  }
  {...a11yProps(0)}
/>
<Tab
  label={
    <Box>
    {perception.cof.length === 0 && <CheckCircleIcon sx={{ color: 'green', marginRight: '10px' }} />}
      <KeyIcon sx={{ marginRight: "8px" }} />
      COF
    </Box>
  }
  {...a11yProps(1)}
/>
  <Tab
    label={
      <Box>
        {perception.drone.length === 0 && <CheckCircleIcon sx={{ color: 'green', marginRight: '10px' }} />}
          <AirplanemodeActiveIcon sx={{ marginRight: "8px" }} />
        Rens aérien / Drone
      </Box>
    }
    {...a11yProps(2)}
  />
<Tab
    label={
      <Box>
        {perception.micro.length === 0 && <CheckCircleIcon sx={{ color: 'green', marginRight: '10px' }} />}
        <MicIcon sx={{ marginRight: "8px" }} />
        Micro
      </Box>
    }
    {...a11yProps(3)}
  />
  <Tab
    label={
      <Box>
        {perception.verte.length === 0 && <CheckCircleIcon sx={{ color: 'green', marginRight: '10px' }} />}
        <ForestIcon sx={{ marginRight: "8px" }} />
        Verte
      </Box>
    }
    {...a11yProps(4)}
  />
  <Tab
    label={
      <Box>
        {perception.cnp.length === 0 && <CheckCircleIcon sx={{ color: 'green', marginRight: '10px' }} />}
        <MasksIcon sx={{ marginRight: "8px" }} />
        CNP
      </Box>
    }
    {...a11yProps(5)}
  />
  <Tab
    label={
      <Box>
        {perception.interpel.length === 0 && <CheckCircleIcon sx={{ color: 'green', marginRight: '10px' }} />}
        <LocalFireDepartmentIcon sx={{ marginRight: "8px" }} />
        Interpel / Armement
      </Box>
    }
    {...a11yProps(6)}
  />
  <Tab
    label={
      <Box>
        {perception.mobilite.length === 0 && <CheckCircleIcon sx={{ color: 'green', marginRight: '10px' }} />}
        <DirectionsCarIcon sx={{ marginRight: "8px" }} />
        Mobilité
      </Box>
    }
    {...a11yProps(7)}
  />
  <Tab
    label={
      <Box>
        {perception.divers.length === 0 && <CheckCircleIcon sx={{ color: 'green', marginRight: '10px' }} />}
        <ConstructionIcon sx={{ marginRight: "8px" }} />
        Divers
      </Box>
    }
    {...a11yProps(8)}
  />
  <Tab
    label={
      <Box>
        {perception.sic.length === 0 && <CheckCircleIcon sx={{ color: 'green', marginRight: '10px' }} />}
        <SettingsInputAntennaIcon sx={{ marginRight: "8px" }} />
        SIC
      </Box>
    }
    {...a11yProps(9)}
  />
  <Tab
    label={
      <Box >
        {perception.track.length === 0 && <CheckCircleIcon sx={{ color: 'green', marginRight: '10px' }} />}
        <LocationOnIcon sx={{ marginRight: "8px" }} />
        Track
      </Box>
    }
    {...a11yProps(10)}
  />
    </Tabs>
    <TabPanel value={value} index={0}>
      <PerceptionList list={perception.image} />
    </TabPanel>
    <TabPanel value={value} index={1}>
      <PerceptionList list={perception.cof} />
    </TabPanel>
    <TabPanel value={value} index={2}>
      <PerceptionList list={perception.drone} />
    </TabPanel>
    <TabPanel value={value} index={3}>
      <PerceptionList list={perception.micro} />
    </TabPanel>
    <TabPanel value={value} index={4}>
      <PerceptionList list={perception.verte} />
    </TabPanel>
    <TabPanel value={value} index={5}>
      <PerceptionList list={perception.cnp} />
    </TabPanel>
    <TabPanel value={value} index={6}>
      <PerceptionList list={perception.interpel} />
    </TabPanel>
    <TabPanel value={value} index={7}>
      <PerceptionList list={perception.mobilite} />
    </TabPanel>
    <TabPanel value={value} index={8}>
      <PerceptionList list={perception.divers} />
    </TabPanel>
    <TabPanel value={value} index={9}>
      <PerceptionList list={perception.sic} />
    </TabPanel>
    <TabPanel value={value} index={10}>
      <PerceptionList list={perception.track} />
    </TabPanel>
  </Box>
  </div>
</div>

  );
}

export default Reintegration;
