import React, { useState, useEffect } from 'react';
import authHeader from "../auth-header";
import axios from 'axios';
import { CalendarDaysIcon, ListBulletIcon } from '@heroicons/react/20/solid';

function AccueilReservation() {

  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <img className="home-img" src='images/reservation.jpeg'/>
      <div className="text-center mt-4">
          <button
            type="button"
            onClick={() => (window.location.href = "reservations/list")}
            className="mb-4 w-3/4 inline-flex justify-center uppercase items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-blue-900 shadow-sm hover:bg-blue-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-900 border border-blue-900"
          >
            <ListBulletIcon className="mr-2 h-5 w-5" aria-hidden="true" />
            Réservations en cours
          </button>
        </div>

        {currentUser.service === "CAB" || currentUser.service === "EMOPS" || currentUser.service === "R&D" || currentUser.service === "DT" ? (
        <div className="text-center mt-4 w-full">
          <button
            type="button"
            onClick={() => (window.location.href = "reservations/formtime")}
            className="mb-4 w-3/4 justify-center inline-flex uppercase items-center gap-x-1.5 rounded-md bg-blue-900 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-900 border border-blue-900"
          >
            <CalendarDaysIcon className="mr-2 h-5 w-5" aria-hidden="true" />
            Réserver un VHL
          </button>
        </div>
        ) : (
        <div className="text-center mt-4 w-full">
          <button
            type="button"
            onClick={() => (window.location.href = "reservations/form")}
            className="mb-4 w-3/4 justify-center inline-flex uppercase items-center gap-x-1.5 rounded-md bg-blue-900 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-900 border border-blue-900"
          >
            <CalendarDaysIcon className="mr-2 h-5 w-5" aria-hidden="true" />
            Réserver un VHL
          </button>
        </div>
        )}
    </div>
  );
}

export default AccueilReservation;
