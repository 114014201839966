import React, { useEffect, useState } from "react";
import axios from "axios";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { ListItemButton, Chip } from "@mui/material";
import authHeader from "../../auth-header";

export default function ListeDrone() {
  const [materiels, setMateriels] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    axios
  .get(process.env.REACT_APP_BASE_URL + "/api/materiels/", { headers: authHeader() })
  .then((response) => {
    const filteredMateriels = response.data.filter(
      (materiel) => materiel.categorie === "Rens aérien / Drones"
    );
    setMateriels(filteredMateriels);
  })
  .catch((error) => {
    console.log(error);
  });

    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        bgcolor: "background.paper",
        marginTop: "-25px",
      }}
    >
      {materiels
        .sort((a, b) => a.alerte.localeCompare(b.alerte))
        .map((materiel, index, array) => (
          <React.Fragment key={materiel._id}>
            {index === 0 && (
              <>
                <Divider>
                  <Typography variant="h3" fontWeight="bold">
                    <Chip label={materiel.alerte} />
                  </Typography>
                </Divider>
              </>
            )}

            {index > 0 && materiel.alerte !== array[index - 1].alerte && (
              <>
                <Divider>
                  <Typography variant="h3" fontWeight="bold">
                    <Chip label={materiel.alerte} />
                  </Typography>
                </Divider>
              </>
            )}
            <ListItemButton
              alignItems="center"
              component="a"
              href={`/pagemateriel/${materiel._id}`}
            >
              <ListItemAvatar>
                <Avatar alt={materiel.nom} src={materiel.image} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {materiel.nom}
                    </Typography>
                  </div>
                }
              />
            </ListItemButton>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        ))}
    </List>
  );
}
