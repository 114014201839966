import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import "react-datepicker/dist/react-datepicker.css";
import {
  Avatar,
  Box,
  Typography,
  ListItemButton,
  ListItemText,
  Tabs,
  Tab,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  List
} from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteIcon from "@mui/icons-material/Delete";
import KeyIcon from '@mui/icons-material/Key';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import MicIcon from '@mui/icons-material/Mic';
import ForestIcon from '@mui/icons-material/Forest';
import MasksIcon from '@mui/icons-material/Masks';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ConstructionIcon from '@mui/icons-material/Construction';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import authHeader from "../auth-header";

const label = { inputProps: { 'aria-label': 'Switch' } };

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function DetailHistoPerception(props) {
  const [perception, setPerception] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const { id } = useParams();
  

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/histoperceptionmateriel/" + id, { headers: authHeader() })
        .then((response) => {
          setPerception(response.data);

        })
        .catch((error) => {
          console.log(error);
        });
      setisLoading(false);
    }
  }, [id, isLoading]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const deletePerception = (id) => {
    axios
      .delete(process.env.REACT_APP_BASE_URL + "/api/histoperceptionmateriel/" + id, { headers: authHeader() })
      .then((res) => {
        window.location = "/listhistoperceptions";
      })
  };


  const PerceptionList = ({ list }) => {
    return (
      <List>
        {list &&
          list.map((item, index) => (
            <ListItemButton key={index} alignItems="flex-start" href={`/pagemateriel/${item.materiel._id}`}>
              {item && item.materiel && (
                <Avatar
                  alt={item.materiel.nom}
                  src={item.materiel.imageperception}
                  sx={{ width: 32, height: 32, mr: 1 }}
                />
              )}
              {item && item.materiel && (
                <ListItemText
                  primary={item.materiel.nom}
                  primaryTypographyProps={{ variant: 'body2' }}
                  sx={{ alignSelf: 'flex-start' }}
                />
              )}
            </ListItemButton>
          ))}
      </List>
    );
  };

  return (
    <div style={{ display: "grid", gridTemplateRows: "1fr auto" }}>

<Card sx={{ maxWidth: '100vh', textAlign: 'center' }}>
      <CardMedia
        sx={{ height: 100 }}
        image="/images/homemateriel.png"
        title="materiels"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Matériels perçus pour : <strong>{perception.nomperception}</strong>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Type de perception : {perception.typeperception}
        </Typography>
      </CardContent>
    </Card>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "16px" }}>
    <p>Réintégrés le {new Date(perception.date).toLocaleDateString()}</p>

    <p>Remarques : {perception.remarques}</p>
      </div>
<div>
  <Box
    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
  >
    <Tabs
      orientation="vertical"
      variant="scrollable"
      value={value}
      onChange={handleChange}
      aria-label="Matériels perçus"
      sx={{ borderRight: 1, borderColor: 'divider', width: '200px', height: '100vh'}}
    >
<Tab
  label={
    <Box>
      <CameraAltIcon sx={{ marginRight: "8px" }} />
      Image
    </Box>
  }
  {...a11yProps(0)}
/>
<Tab
  label={
    <Box>
      <KeyIcon sx={{ marginRight: "8px" }} />
      COF
    </Box>
  }
  {...a11yProps(1)}
/>
  <Tab
    label={
      <Box>
          <AirplanemodeActiveIcon sx={{ marginRight: "8px" }} />
        Rens aérien / Drone
      </Box>
    }
    {...a11yProps(2)}
  />
<Tab
    label={
      <Box>
        <MicIcon sx={{ marginRight: "8px" }} />
        Micro
      </Box>
    }
    {...a11yProps(3)}
  />
  <Tab
    label={
      <Box>
        <ForestIcon sx={{ marginRight: "8px" }} />
        Verte
      </Box>
    }
    {...a11yProps(4)}
  />
  <Tab
    label={
      <Box>
        <MasksIcon sx={{ marginRight: "8px" }} />
        CNP
      </Box>
    }
    {...a11yProps(5)}
  />
  <Tab
    label={
      <Box>
        <LocalFireDepartmentIcon sx={{ marginRight: "8px" }} />
        Interpel / Armement
      </Box>
    }
    {...a11yProps(6)}
  />
  <Tab
    label={
      <Box>
        <DirectionsCarIcon sx={{ marginRight: "8px" }} />
        Mobilité
      </Box>
    }
    {...a11yProps(7)}
  />
  <Tab
    label={
      <Box>
        <ConstructionIcon sx={{ marginRight: "8px" }} />
        Divers
      </Box>
    }
    {...a11yProps(8)}
  />
  <Tab
    label={
      <Box>
        <SettingsInputAntennaIcon sx={{ marginRight: "8px" }} />
        SIC
      </Box>
    }
    {...a11yProps(9)}
  />
  <Tab
    label={
      <Box >
        <LocationOnIcon sx={{ marginRight: "8px" }} />
        Track
      </Box>
    }
    {...a11yProps(10)}
  />
    </Tabs>
    <TabPanel value={value} index={0}>
      <PerceptionList list={perception.image} />
    </TabPanel>
    <TabPanel value={value} index={1}>
      <PerceptionList list={perception.cof} />
    </TabPanel>
    <TabPanel value={value} index={2}>
      <PerceptionList list={perception.drone} />
    </TabPanel>
    <TabPanel value={value} index={3}>
      <PerceptionList list={perception.micro} />
    </TabPanel>
    <TabPanel value={value} index={4}>
      <PerceptionList list={perception.verte} />
    </TabPanel>
    <TabPanel value={value} index={5}>
      <PerceptionList list={perception.cnp} />
    </TabPanel>
    <TabPanel value={value} index={6}>
      <PerceptionList list={perception.interpel} />
    </TabPanel>
    <TabPanel value={value} index={7}>
      <PerceptionList list={perception.mobilite} />
    </TabPanel>
    <TabPanel value={value} index={8}>
      <PerceptionList list={perception.divers} />
    </TabPanel>
    <TabPanel value={value} index={9}>
      <PerceptionList list={perception.sic} />
    </TabPanel>
    <TabPanel value={value} index={10}>
      <PerceptionList list={perception.track} />
    </TabPanel>
  </Box>
  </div>

  {currentUser.roles === "Admin" ? (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
  <IconButton
    edge="end"
    aria-label="delete"
    component="a"
    href="#"
    onClick={() => {
      if (window.confirm("Êtes-vous sûr de vouloir supprimer ce matériel?")) {
        deletePerception(perception._id);
      }
    }}
    sx={{ color: "red" }}
  >
    <DeleteIcon />
  </IconButton>
</Box>
) : (
    ""
  )}

</div>

  );
}

export default DetailHistoPerception;
