import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import authHeader from "../auth-header";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Perception() {
  const [vhl, setVhl] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [currentUser, setCurrentUser] = useState({});

  const PositionOptions = [
    { id: 1, name: 'Disponible' },
    { id: 2, name: 'Mission' },
    { id: 3, name: 'Liaison' },
    { id: 4, name: 'Exercice' },
    { id: 5, name: 'Garage' },
    { id: 6, name: 'SIC' },
    { id: 7, name: 'Diagnostic Garage' },
  ];

  const [selectedPosition, setSelectedPosition] = useState(PositionOptions[6]);


  const onChangePosition = (e) => {
    setSelectedPosition(e);
    if (e.name === 'Disponible') {
      // Si la position est "Disponible", réinitialise Emploi et Utilisateur à des chaînes vides.
      setVhl({
        ...vhl,
        position: e.name,
        emploi: '',
        utilisateur: '',
      });
    } else {
      setVhl({
        ...vhl,
        position: e.name,
      });
    }
  };

  const onChangeEmploi = (e) => {
    setVhl({
      ...vhl,
      emploi: e.target.value,
    });
  };

  const onChangeUtilisateur = (e) => {
    setVhl({
      ...vhl,
      utilisateur: e.target.value,
    });
  };

  const onChangeKm = (e) => {
    const kmValue = e.target.value;
    let revisionprov;
    if (currentUser.service === "FOR" || currentUser.service === "FSP" || currentUser.service === "FI" || currentUser.service === "SMS") {
      revisionprov = Math.ceil(kmValue / 10000) * 10000;
    } else {
      revisionprov = Math.ceil(kmValue / 10000) * 10000 + 10000;
    }
    setVhl({
      ...vhl,
      km: e.target.value,
      prochainerevision: revisionprov,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
  
    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/vhl/update/" + id, vhl, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res.data);
        setVhl((prevVhl) => ({ ...prevVhl, ...vhl }));
  
        if (vhl.position !== "Disponible") {
          const histoperception = {
            ...vhl,
            idvhl: vhl.id,
            nomvhl: vhl.nomvhl,
            service: vhl.service,
            date: new Date(),
            position: vhl.position,
            emploi: vhl.emploi,
            utilisateur: vhl.utilisateur,
            km: vhl.km,
          };
  
          axios
            .post(
              process.env.REACT_APP_BASE_URL + "/api/historiqueperception/add/",
              histoperception,
              { headers: authHeader() }
            )
            .then((res) => {
              window.location = `/pagevhl/${vhl._id}`;
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          window.location = `/pagevhl/${vhl._id}`;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/vhl/" + id, { headers: authHeader() })
        .then((response) => {
          setVhl(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setIsLoading(false);
    }
    axios.get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, isLoading]);

  return (
    <div>
        <div className="flex flex-col items-center">
    <img
      className="h-40 w-40 rounded-full object-cover mb-3"
      src={vhl.image}
      alt={vhl.nomvhl}
    />
    <h3 className="text-2xl font-bold text-center">{vhl.nomvhl}</h3>
    <h2 className="text-lg italic text-center mb-6">{vhl.dossardorigine}</h2>
  </div>

  <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-2 text-gray-500">
          <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
        </span>
      </div>
    </div>


      <form onSubmit={onSubmit} className="flex flex-col items-center justify-center">

      <div className="mb-2 mt-4">
        <Listbox value={selectedPosition} onChange={onChangePosition}>
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Position</Listbox.Label>
              <div className="relative mt-1">
                <Listbox.Button
                  className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <span className="block truncate">{selectedPosition.name}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Listbox.Options
  as="ul"
  className="absolute z-10 max-w-xs w-full py-1 text-base bg-white border border-gray-300 divide-y divide-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
>
  {PositionOptions.map((option) => (
    <Listbox.Option
      key={option.id}
      value={option}
    >
      {({ active, selected }) => (
        <li
          onClick={() => setSelectedPosition(option)}
          className={classNames(
            active ? 'bg-indigo-600 text-white' : 'text-gray-900',
            'cursor-pointer select-none relative px-4 py-2'
          )}
        >
          {option.name}
          {selected ? (
            <span
              className={classNames(
                active ? 'text-white' : 'text-indigo-600',
                'absolute inset-y-0 left-0 flex items-center pl-3'
              )}
            >
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          ) : null}
        </li>
      )}
    </Listbox.Option>
  ))}
</Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        </div>

        <div className="relative mt-5">
      <label
        htmlFor="nom"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Emploi
      </label>
      <input
        type="text"
        name="emploi"
        id="emploi"
        required={vhl.position !== "Disponible"}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={vhl.emploi}
        onChange={onChangeEmploi}
      />
    </div>

    <div className="relative mt-5">
      <label
        htmlFor="nom"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Utilisateur
      </label>
      <input
        type="text"
        name="utilisateur"
        id="utilisateur"
        required={vhl.position !== "Disponible"}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={vhl.utilisateur}
        onChange={onChangeUtilisateur}
      />
    </div>
      
      
    <div className="relative mt-5">
      <label
        htmlFor="km"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Kilométrage
      </label>
      <input
        type="number"
        name="km"
        id="km"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={vhl.km}
        onChange={onChangeKm}
      />
    </div>

        <div className="form-group text-center">
        <button
    type="submit"
    className="mt-8 mx-auto rounded-lg bg-white border-2 border-indigo-900 px-3 py-2 text-base font-semibold text-indigo-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
  >
    Valider
  </button>
        </div>
      </form>
    </div>
  );
}

export default Perception;
