import React, { useEffect, useState } from "react";
import axios from "axios";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { ListItemButton, ListItemSecondaryAction, Chip } from "@mui/material";
import authHeader from "../../auth-header";

export default function ListPerceptionCours() {
  const [perceptions, setPerceptions] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/perceptionmateriel/", { headers: authHeader() })
      .then((response) => {
        setPerceptions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);



  const listStyles = {
    width: "100%",
    maxWidth: "100%",
    bgcolor: "background.paper",
    marginTop: "-25px",
  };

  return (
    <>
        <List sx={listStyles}>
          {perceptions
            .sort((a, b) => a.typeperception.localeCompare(b.typeperception))
            .map((perception, index, array) => (
              <React.Fragment key={perception._id}>
                {index === 0 && (
                  <>
                    <Divider>
                      <Typography variant="h3" fontWeight="bold">
                        <Chip label={perception.typeperception} />
                      </Typography>
                    </Divider>
                  </>
                )}

                {index > 0 &&
                  perception.typeperception !== array[index - 1].typeperception && (
                    <>
                      <Divider>
                        <Typography variant="h3" fontWeight="bold">
                          <Chip label={perception.typeperception} />
                        </Typography>
                      </Divider>
                    </>
                  )}
                <ListItemButton
                  alignItems="center"
                  component="a"
                  href={`/perceptionmateriel/${perception._id}`}
                >
                  <ListItemText
                    primary={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold" }}>
                          {perception.nomperception}
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "lighter" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {new Date(perception.date).toLocaleDateString()}
                        </Typography>
                      </div>
                    }
                  />
                </ListItemButton>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            ))}
        </List>
    </>
  );
}
