import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import authHeader from "../auth-header";
import { Fab } from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export default function ReservationList() {
  const [reservations, setReservations] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL + '/api/reservations/', { headers: authHeader() })
      .then(response => {
        setReservations(response.data);
      })
      .catch(error => {
        console.log(error);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const fabStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
  };

  const filteredVehicles = reservations.filter(
    (reservations) => currentUser && reservations.service === currentUser.service
  );

  filteredVehicles.sort((a, b) => {
    const dateDebutA = new Date(a.datedebut);
    const dateDebutB = new Date(b.datedebut);

    if (dateDebutA < dateDebutB) {
      return -1;
    }
    if (dateDebutA > dateDebutB) {
      return 1;
    }
    return 0;
  }); 

  return (
    <>
   <ul role="list" className="divide-y divide-gray-100">
  {filteredVehicles.map((item) => (
    <li key={item._id} className="py-4 ml-5 mr-5">
      <Link to={`/reservations/${item._id}`} className="block">
        <div className="flex items-center gap-x-3">
          <img src={item.imagevhl} alt="" className="h-6 w-6 flex-none rounded-full bg-gray-50 object-cover" />
          <h3 className="flex-auto truncate text-lg font-bold leading-6 text-gray-900">{item.nomvhl}</h3>
          <span className="flex-none font-semibold text-xs text-gray-500">{item.nom}</span>
        </div>
        <p className="mt-3 truncate text-sm text-gray-500">
          <span className="text-gray-700 italic">Du {new Date(item.datedebut).toLocaleDateString()}</span> au
          <span className="text-gray-700 italic"> {new Date(item.datefin).toLocaleDateString()}</span>
        </p>
      </Link>
    </li>
  ))}
</ul>
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          href="/reservations/calendrier"
          style={fabStyle}
        >
          <CalendarMonthIcon />
        </Fab>
</>
  );
}
