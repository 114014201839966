import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import Datepicker from "tailwind-datepicker-react";
import authHeader from "../auth-header";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const CreateRevisionVHL = () => {
  const [vehicles, setVehicles] = useState([]);
  const [vhl, setVhl] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(new Date());
  const [kmeffectif, setKmEffectif] = useState("");
  const [kmrevision, setKmRevision] = useState("");
  const [remarque, setRemarque] = useState("");

  const revisions = [];
  for (let km = 10000; km <= 350000; km += 10000) {
    revisions.push({ title: `Révision des ${km}km`, km });
  }

  const [selectedRevision, setSelectedRevision] = useState(revisions[0]);

  const options = {
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    maxDate: new Date("2030-01-01"),
    minDate: new Date("2023-01-01"),
    theme: {
      background: "bg-gray-50 dark:bg-gray-700",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "",
      input: "",
      inputIcon: "",
      selected: "bg-gray-100"
    },
    icons: {
      prev: () => <span>Previous</span>,
      next: () => <span>Next</span>,
    },
    datepickerClassNames: "top-12 w-64",
    defaultDate: new Date(),
    language: "fr",
    disabledDates: [],
    weekDays: ["L", "M", "M", "J", "V", "S", "D"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Selectionner la date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/", { headers: authHeader() })
      .then((response) => {
        setVehicles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const [query, setQuery] = useState('')
  const [selectedVHL, setSelectedVHL] = useState([]);

  const filteredVHL = query === ''
    ? vehicles
    : vehicles.filter((vhl) => {
        const lowerCaseQuery = query.toLowerCase();
        return (
          (vhl.nomvhl && vhl.nomvhl.toLowerCase().includes(lowerCaseQuery)) ||
          (vhl.dossardorigine && vhl.dossardorigine.toLowerCase().includes(lowerCaseQuery))
        );
      });

      const handleDateChange = (date) => {
        setDate(date);
      };
    
      const onChangeKm = (e) => {
        setKmEffectif(Number(e.target.value));
        };
    
      const onChangeKMRevision = (e) => {
        setSelectedRevision(e);
        setKmRevision(e.km);
      };
    
      const onChangeRemarque = (e) => {
        setRemarque(e.target.value);
      };

          const onChangeVHL = (vhl) => {
            setSelectedVHL(vhl);
            setVhl(vhl);
            setQuery("");
          };

  const handleDateClick = () => {
    setShow(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const historevision = {
        idvhl: vhl._id,
        nomvhl: vhl.nomvhl,
        service: vhl.service,
        date: date,
        remarque: remarque,
        kmeffectif: Number(kmeffectif),
        km: selectedRevision.km,
      };

      axios
      .post(process.env.REACT_APP_BASE_URL + "/api/historiquerevision/add/", historevision, { headers: authHeader() })
      .then((res) => {
        window.location = `/rdvvhl`;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  

  return (

      <div className="max-w-full md:max-w-xl mx-auto">
        <h3 className="text-2xl font-bold text-center mb-6">Créer une révision sur un VHL</h3>
      
      <form onSubmit={onSubmit}>

      <Combobox as="div" value={selectedVHL} onChange={onChangeVHL}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900">Véhicule</Combobox.Label>
      <div className="relative mt-2">
      <Combobox.Input
        className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        onChange={(event) => setQuery(event.target.value)}
        displayValue={(vhl) => `${selectedVHL?.nomvhl || ''} - ${selectedVHL?.dossardorigine || ''} - ${selectedVHL?.service || ''}`}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredVHL.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredVHL.map((vhl) => (
              <Combobox.Option
                key={vhl.id}
                value={vhl}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex">
                      <img src={vhl.image} alt="" className="h-6 w-6 flex-shrink-0 rounded-full mr-5 object-cover" />
                      <span className={classNames('truncate', selected && 'font-bold')}>{vhl.nomvhl}</span>
                      <span
                        className={classNames(
                          'ml-5 truncate text-gray-500 font-medium italic',
                          active ? 'text-indigo-200' : 'text-gray-500'
                        )}
                      >
                        {vhl.dossardorigine}
                      </span>
                      <span
                        className={classNames(
                          'ml-5 truncate text-gray-500',
                          active ? 'text-indigo-200' : 'text-gray-500'
                        )}
                      >
                        {vhl.service}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>


    <div className="mb-2 mt-4">
        <Listbox value={selectedRevision} onChange={onChangeKMRevision}>
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Révision des ...</Listbox.Label>
              <div className="relative mt-1">
                <Listbox.Button
                  className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <span className="block truncate">{selectedRevision.km}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Listbox.Options
  as="ul"
  className="absolute z-10 max-w-xs w-full py-1 text-base bg-white border border-gray-300 divide-y divide-gray-200 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
>
  {revisions.map((option) => (
    <Listbox.Option
      key={option.id}
      value={option}
    >
      {({ active, selected }) => (
        <li
          onClick={() => setSelectedRevision(option)}
          className={classNames(
            active ? 'bg-indigo-600 text-white' : 'text-gray-900',
            'cursor-pointer select-none relative px-4 py-2'
          )}
        >
          {option.km}
          {selected ? (
            <span
              className={classNames(
                active ? 'text-white' : 'text-indigo-600',
                'absolute inset-y-0 left-0 flex items-center pl-3'
              )}
            >
              <CheckIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          ) : null}
        </li>
      )}
    </Listbox.Option>
  ))}
</Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        </div>


      <div className="mt-5">
	<Datepicker 
      options={options} 
      value={date} 
      onChange={(date) => handleDateChange(date)} 
      show={show} 
      setShow={setShow} 
      onClick={handleDateClick}
      />
		</div>

    <div className="relative mt-5">
      <label
        htmlFor="km"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Kilométrage
      </label>
      <input
        type="number"
        name="kmeffectif"
        id="kmeffectif"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={kmeffectif}
        onChange={onChangeKm}
      />
    </div>

    <div className="relative mt-5">
      <label
        htmlFor="nom"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Remarques
      </label>
      <input
        type="text"
        name="remarques"
        id="remarques"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={remarque}
        onChange={onChangeRemarque}
      />
    </div>

    <div className="form-group text-center">
        <button
    type="submit"
    className="mt-8 mx-auto rounded-lg bg-white border-2 border-indigo-900 px-3 py-2 text-base font-semibold text-indigo-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
  >
    Valider
  </button>
        </div>
      </form>
    </div>
  );
};

export default CreateRevisionVHL;
