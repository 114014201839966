import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpIcon, ChevronDownIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import Datepicker from "tailwind-datepicker-react";
import authHeader from "../auth-header";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const NewTache = () => {
  const [idvhl, setIdVHL] = useState("");
  const [nomvhl, setVHL] = useState("");
  const [dossardorigine, setImmat] = useState("");
  const [service, setService] = useState("");
  const [km, setKm] = useState("");
  const [image, setImage] = useState("");
  const [date, setDate] = useState("");
  const [nomgaragiste, setNom] = useState("");
  const [selectedTravail, setselectedTypetravail] = useState("");
  const [typetravail, setTypetravail] = useState("");
  const [remarques, setRemarques] = useState("");
  const [vehicles, setVehicles] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [selectedVHL, setSelectedVHL] = useState({});
  const [priorite, setPriorite] = useState(0);
  const [couleur, setCouleur] = useState("Absent");
  const [show, setShow] = useState(false);

  const typetravailOptions = [
    { id: 1, categorie: 'Méca', name: 'Check VHL' },
    { id: 2, categorie: 'Méca', name: 'Visite' },
    { id: 3, categorie: 'Méca', name: 'Accident' },
    { id: 4, categorie: 'Méca', name: 'Confection de plaques' },
    { id: 5, categorie: 'Méca', name: 'Liquide de frein' },
    { id: 6, categorie: 'Méca', name: 'Pneumatique' },
    { id: 7, categorie: 'Méca', name: 'Frein' },
    { id: 8, categorie: 'Méca', name: 'Distribution' },
    { id: 9, categorie: 'Méca', name: 'Embrayage' },
    { id: 10, categorie: 'Méca', name: 'Recherche de panne' },
    { id: 11, categorie: 'Autre', name: 'Secrétariat' },
    { id: 12, categorie: 'Autre', name: 'Instruction' },
    { id: 13, categorie: 'Autre', name: 'Garde' },
    { id: 14, categorie: 'Autre', name: 'Repos / Perms' },
    { id: 15, categorie: 'Autre', name: 'Sport' },
    { id: 16, categorie: 'Autre', name: 'Depannage' },
    { id: 17, categorie: 'Autre', name: 'Nettoyage' },
    { id: 18, categorie: 'Autre', name: 'Série 0' },
    { id: 19, categorie: 'Autre', name: 'Cérémonie / Dem' },
    { id: 20, categorie: 'Autre', name: 'Liaison' },
    { id: 21, categorie: 'Autre', name: 'Réunion' },
    { id: 22, categorie: 'Autre', name: 'OPEX' },
    { id: 23, categorie: 'Autre', name: 'Désequipement GIE' },
  ];

  const options = {
    autoHide: true,
    todayBtn: true,
    clearBtn: true,
    maxDate: new Date("2030-01-01"),
    minDate: new Date("2023-01-01"),
    theme: {
      background: "bg-gray-50 dark:bg-gray-700",
      todayBtn: "",
      clearBtn: "",
      icons: "",
      text: "",
      disabledText: "",
      input: "",
      inputIcon: "",
      selected: "bg-gray-100"
    },
    icons: {
      prev: () => <span>Previous</span>,
      next: () => <span>Next</span>,
    },
    datepickerClassNames: "top-12 w-64",
    defaultDate: new Date(),
    language: "fr",
    disabledDates: [],
    weekDays: ["L", "M", "M", "J", "V", "S", "D"],
    inputNameProp: "date",
    inputIdProp: "date",
    inputPlaceholderProp: "Selectionner la date",
    inputDateFormatProp: {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
    dayStyle: {
      margin: "4rem", // Ajustez la valeur selon vos préférences
    },
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/", { headers: authHeader() })
      .then((response) => {
        setVehicles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const groupedTypetravailOptions = typetravailOptions.reduce((acc, option) => {
    if (!acc[option.categorie]) {
      acc[option.categorie] = [];
    }
    acc[option.categorie].push(option);
    return acc;
  }, {});

  const groupedVehicles = vehicles.reduce((acc, vehicle) => {
    if (!acc[vehicle.service]) {
      acc[vehicle.service] = [];
    }
    acc[vehicle.service].push(vehicle);
    return acc;
  }, {});

  const [selectedService, setSelectedService] = useState(null);

  const handleChangeService = (service) => {
    setSelectedService(service);
  };


  const handleChangeVHL = (e) => {
      setSelectedVHL(e);
    const selectedVehicle = vehicles.find(
      (vehicle) => vehicle._id === e._id
    );
    if (selectedVehicle) {
      setIdVHL(selectedVehicle?._id || "");
      setService(selectedVehicle?.service || "");
      setVHL(selectedVehicle?.nomvhl || "");
      setImmat(selectedVehicle?.dossardorigine || "");
      setKm(selectedVehicle?.km || "");
      setImage(selectedVehicle?.image || "");
    }    
  };

  const handleChangeTypeTravail = (e) => {
    setTypetravail(e.name);
    setselectedTypetravail(e);
  };

  const handleChangeRemarques = (e) => {
    setRemarques(e.target.value);
  };

  const handleChangeNom = (e) => {
    setNom(e.target.value);
  };

  const handleChangePriorite = (e) => {
    setPriorite(e.target.value);
  };

  const handleDateChange = (date) => {
      setDate(date);
  };

  const handleDateClick = () => {
    setShow(true);
  };

  
  const handleSubmit = (e) => {
    e.preventDefault();
    const tache = {
      idvhl,
      nomvhl,
      service,
      dossardorigine,
      km,
      date,
      image,
      nomgaragiste,
      typetravail,
      remarques,
      priorite,
      couleur
    };

    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/planninggarage/add", tache, {
        headers: authHeader(),
      })
      .then((res) => {
        window.location = "/calendriertachesv2";
      })
      .catch((error) => {
        console.error(error);
      }); 
  };
  

  return (

      <div className="max-w-full md:max-w-xl mx-auto">
        <h3 className="text-2xl font-bold text-center mb-6">Créer une tâche</h3>
      
      <form onSubmit={handleSubmit}>

      <label htmlFor="vhl" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
        Véhicule
      </label>
      {/* Menu déroulant des services */}
      <Listbox
          value={selectedService}
          onChange={handleChangeService}
        >
          {({ open }) => (
            <div className="relative mt-2">
              <Listbox.Button
                className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
              >
                <span className="flex items-center">
                  {selectedService ? (
                    <span className="ml-2 block truncate font-bold">
                      {selectedService} 
                    </span>
                  ) : (
                    <span className="ml-5 block text-gray-400">Sélectionner un Service</span>
                  )}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  {open ? (
                    <ChevronUpIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  )}
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                  {Object.keys(groupedVehicles).map((service) => (
                    <Listbox.Option
                      key={service}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-blue-900 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={service}
                    >
                      {service}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          )}
        </Listbox>

        {/* Menu déroulant des véhicules pour le service sélectionné */}
        {selectedService && (
          <Listbox value={selectedVHL} onChange={handleChangeVHL}>
          {({ open }) => (
            <div className="relative mt-2">
              <Listbox.Button
                className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
              >
                <span className="flex items-center">
                  <img src={selectedVHL.image} alt="" className="h-8 w-8 flex-none rounded-full bg-gray-50 object-cover" />
                  {selectedVHL ? (
                    <span className="ml-5 block truncate font-bold">
                      {selectedVHL.nomvhl} 
                    </span>
                  ) : (
                    <span className="ml-5 block text-gray-400">Sélectionner un Véhicule</span>
                  )}
                  <span className="ml-4 block truncate italic">
                    {selectedVHL ? selectedVHL.dossardorigine : " "}
                  </span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  {open ? (
                    <ChevronUpIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  )}
                </span>
              </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    {groupedVehicles[selectedService].map((vhl) => (
                      <Listbox.Option
                        key={vhl._id}
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-blue-900 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={vhl}
                      >
                        <div className="flex items-center">
                  <img src={vhl.image} alt="" className="h-8 w-8 flex-none rounded-full bg-gray-50 object-cover" />
                    <span className="ml-5 block truncate font-bold">
                      {vhl.nomvhl} 
                    </span>
                    <span className="ml-4 block truncate italic">
                      {vhl.dossardorigine}
                    </span>
                  </div>
                        </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            )}
          </Listbox>
        )}

<div className="relative mt-8">
      <label
        htmlFor="nom"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Nom du garagiste
      </label>
      <input
        type="text"
        name="nom"
        id="nom"
        required
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={nomgaragiste}
        onChange={handleChangeNom}
      />
    </div>
    
        

    <Listbox value={selectedTravail} onChange={handleChangeTypeTravail}>
          {({ open }) => (
            <>
              <Listbox.Label className="mt-5 block text-sm font-medium leading-6 text-gray-900">Type de travail</Listbox.Label>
              <div className="relative mt-2">
                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-4 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-900 sm:text-sm">
                  <span className="block truncate">{selectedTravail.name}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto font-semibold rounded-md bg-white py-1 text-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {Object.keys(groupedTypetravailOptions).map((categorie) => (
                      <Fragment key={categorie}>
                        <Listbox.Option value={null}>
                          {({ active }) => (
                            <div className={classNames(active ? 'bg-gray-200' : 'bg-gray-100', 'py-1 px-3')}>
                              {categorie}
                            </div>
                          )}
                        </Listbox.Option>
                        {groupedTypetravailOptions[categorie].map((dmd) => (
                          <Listbox.Option
                            key={dmd.id}
                            className={({ active }) =>
                              classNames(
                                active ? 'bg-blue-900 text-white' : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9'
                              )
                            }
                            value={dmd}
                          >
                            {({ selected, active }) => (
                              <>
                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                  {dmd.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-indigo-900',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Fragment>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>

<div className="relative mt-8">
  <label
    htmlFor="details"
    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
  >
    Remarques
  </label>
  <div className="mt-2">
        <textarea
          rows={4}
          name="details"
          id="details"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
          value={remarques}
          onChange={handleChangeRemarques}
        />
      </div>
</div>

<div className="relative mt-5">
      <label
        htmlFor="priorite"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Priorité
      </label>
      <input
        type="number"
        name="priorite"
        id="priorite"
        required
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={priorite}
        onChange={handleChangePriorite}
      />
    </div>

      <div className="mt-4">
      <Datepicker 
        options={options} 
        value={date} 
        onChange={(date) => handleDateChange(date)} 
        show={show} 
        setShow={setShow} 
        onClick={handleDateClick}
        />
        </div>

<div className="form-group text-center">
  <button
    type="submit"
    className="mt-4 mx-auto rounded-lg bg-blue-900 px-3 py-2 text-base font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
  >
    Valider
  </button>
</div>
      </form>
    </div>
  );
};

export default NewTache;
