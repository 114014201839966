import React, { useEffect, useState } from "react";
import axios from "axios";
import authHeader from "../auth-header";
import { TrashIcon, PencilSquareIcon } from '@heroicons/react/20/solid';

export default function ListeAdminVHL() {
  const [vehicles, setVehicles] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  const deleteVHL = (id) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir supprimer ce véhicule ?"
      )
    ) {
    axios
      .delete(process.env.REACT_APP_BASE_URL + "/api/vhl/" + id, { headers: authHeader() })
      .then((res) => console.log(res.data));
    setVehicles(vehicles.filter((el) => el._id !== id));
    }
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/", { headers: authHeader() })
      .then((response) => {
        setVehicles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let filteredVehicles = vehicles;

  if (currentUser.service !== "GARAGE") {
    filteredVehicles = vehicles.filter(
      (vehicle) => currentUser && vehicle.service === currentUser.service
    );
  }
  
  let groupedVehicles = {};

filteredVehicles.forEach((vhl) => {
  if (!groupedVehicles[vhl.service]) {
    groupedVehicles[vhl.service] = [];
  }
  groupedVehicles[vhl.service].push(vhl);
});


  return (
    
    <ul role="list" className="divide-y divide-gray-100 max-w-md mx-auto">
    {Object.entries(groupedVehicles).map(([service, vehiclesInService]) => (
      <React.Fragment key={service}>
        <li className="my-4 text-center text-lg font-semibold">{service}</li>
        {vehiclesInService.map((vhl) => (
          <li key={vhl.dossardorigine} className="ml-4 flex justify-between gap-x-6 py-5">
          <div className="flex min-w-0 gap-x-4">
          <img className="h-12 w-12 flex-none rounded-full bg-gray-50 object-cover" src={vhl.image} alt="" />
            <div className="min-w-0 flex-auto">
              <p className="text-base font-semibold leading-6 text-gray-900">{vhl.nomvhl}</p>
              <p className="mt-1 truncate text-sm italic leading-5 text-gray-500">{vhl.dossardorigine}</p>
            </div>
          </div>
          <div className="flex mr-4">
            <TrashIcon
              onClick={() => deleteVHL(vhl._id)}
              className="w-6 h-6 mr-4 text-red-500 cursor-pointer"
            />
            <PencilSquareIcon
              onClick={() => (window.location.href = `/edit/${vhl._id}`)}
              className="w-6 h-6 text-blue-500 cursor-pointer"
            />
          </div>
          </li>
        ))}
      </React.Fragment>
    ))}
  </ul>
  );
}
