import { React, useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../auth-header";


function PageDivers() {

  const [currentUser, setCurrentUser] = useState({});
  const [dmdGarage, setDmdGarage] = useState([]);


  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios.get(process.env.REACT_APP_BASE_URL + '/api/dmdintervgarage/', { headers: authHeader() })
      .then(response => {
        setDmdGarage(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  function countMissingUsernames() {
    if (currentUser.roles === 'Admin') {
      const filteredDmdGarage = dmdGarage.filter(vehicle => {
        return vehicle.service === currentUser.service && !vehicle.idusers.includes(currentUser.username);
      });
  
      return filteredDmdGarage.length;
    } else {
      return 0;
    }
  }

  const CustomButton = ({ onClick, label }) => (
    <div className="text-center mt-4">
      <button
        type="button"
        onClick={onClick}
        className="mb-4 w-3/4 uppercase inline-flex justify-center items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-blue-900 shadow-sm hover:bg-blue-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-900"
      >
        {label}
      </button>
    </div>
  );

  const Badge = () => (
    <span className="absolute top-0 right-0 -mt-3 -mr-3 inline-flex items-center gap-x-1.5 rounded-md bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700">
      <svg className="h-1.5 w-1.5 fill-gray-400" viewBox="0 0 6 6" aria-hidden="true">
      </svg>
      {countMissingUsernames()} non lus
    </span>
  );
  
  const CustomButton2 = ({ onClick, label }) => (
    <div className="text-center mt-4">
      <button
        type="button"
        onClick={onClick}
        className="mb-4 w-3/4 uppercase relative inline-flex justify-center items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-blue-900 shadow-sm hover:bg-blue-100 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-900"
      >
        <Badge />
        {label}
      </button>
    </div>
  );

  return (
    <div>
      <CustomButton onClick={() => (window.location.href = "/divers/exercice")} label="Véhicules en exercice" />
      <CustomButton onClick={() => (window.location.href = "/divers/liaison")} label="Véhicules en liaison" />
      <CustomButton onClick={() => (window.location.href = "/divers/garage")} label="Véhicules au garage" />
      <CustomButton2 onClick={() => (window.location.href = "/listdmdgarage")} label="Véhicules en demande d'interv. garage" />
      <CustomButton onClick={() => (window.location.href = "/divers/sic")} label="Véhicules aux SIC" />
    </div>
  );
};

export default PageDivers;
