import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import authHeader from "../auth-header";
import { CheckIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function HistoRevision() {
  const [histovhl, setHistoVhl] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [vhl, setVhl] = useState([]);

  const facteurrevision = ["FOR", "FSP", "FI", "SMS", "AMS"].includes(vhl.service)
    ? 10000
    : 20000;

  const revisions = [];
  for (let km = 0; km <= 400000; km += facteurrevision) {
    revisions.push({ title: `Révision des ${km} km`, km, etat: 'grayed', remarque: '', kmeffectif: '', date: '' });
  }

  const { id } = useParams();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/historiquerevision/" + id, {
        headers: authHeader(),
      })
      .then((response) => {
        setHistoVhl(response.data);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/" + id, { headers: authHeader() })
      .then((response) => {
        setVhl(response.data);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      });
  }, []);

  const deleteRevision = (revisionId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette révision ?')) {
      axios
        .delete(process.env.REACT_APP_BASE_URL + "/api/historiquerevision/" + revisionId, { headers: authHeader() })
        .then(() => {
          setHistoVhl(histovhl.filter((entry) => entry._id !== revisionId));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // Mettre à jour les révisions avec les données récupérées
  revisions.forEach((revision) => {
    const matchingEntry = histovhl.find((entry) => entry.km === revision.km);
    if (matchingEntry) {
      revision.etat = 'complete';
      revision.remarque = matchingEntry.remarque;
      revision.kmeffectif = matchingEntry.kmeffectif;
      revision.date = matchingEntry.date;
      revision.id = matchingEntry._id; // Ajout de l'ID pour suppression
    }
  });

  // Trouver le kilométrage de la première révision enregistrée
  const firstRecordedKm = Math.min(...histovhl.map((entry) => entry.km), Infinity);

  // Si aucune révision n'est enregistrée, on garde tout
  const startKm = firstRecordedKm === Infinity ? 0 : firstRecordedKm;

  // Filtrer les révisions pour n'inclure que celles à partir du premier kilométrage enregistré
  const filteredRevisions = revisions.filter((revision) => revision.km >= startKm);

  return (
    <div>
      <nav aria-label="Progress">
        <ol role="list" className="overflow-hidden ml-10 mt-10">
          {filteredRevisions.map((revision, revisionID) => (
            <li key={revision.title} className={classNames(revisionID !== filteredRevisions.length - 1 ? 'pb-10' : '', 'relative')}>
              {revision.etat === 'complete' ? (
                <>
                  {revisionID !== filteredRevisions.length - 1 && (
                    <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600" aria-hidden="true" />
                  )}
                  <div className="group relative flex items-start">
                    <span className="flex h-9 items-center">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600">
                        <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                      </span>
                    </span>
                    <div className="ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium">{revision.title}</span>
                      <span className="text-sm text-gray-500">Révision effectuée le {new Date(revision.date).toLocaleDateString()}</span>
                      <span className="text-sm text-gray-500">Kilométrage : {revision.kmeffectif}</span>
                      <span className="text-sm text-gray-500">Remarques : {revision.remarque}</span>
                      {currentUser.roles === "Admin" && (
                        <button
                        onClick={() => deleteRevision(revision.id)}
                        className="mt-2 inline-flex items-center gap-x-0 px-1 py-0.5 text-xs font-bold text-red-600"
                      >
                        <TrashIcon className="h-4 w-4 text-red-500" aria-hidden="true" />
                        Supprimer
                      </button>
                      )}
                    </div>
                  </div>
                </>
              ) : revision.etat === 'upcoming' ? (
                <>
                  {revisionID !== filteredRevisions.length - 1 && (
                    <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                  )}
                  <div className="group relative flex items-start" aria-current="step">
                    <span className="flex h-9 items-center" aria-hidden="true">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                        <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium text-indigo-600">{revision.title}</span>
                      <span className="text-sm text-gray-500">Prochaine révision</span>
                    </span>
                  </div>
                </>
              ) : (
                <>
                  {revisionID !== filteredRevisions.length - 1 && (
                    <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                  )}
                  <div className="group relative flex items-start">
                    <span className="flex h-9 items-center" aria-hidden="true">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white">
                        <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium text-gray-500">{revision.title}</span>
                      <span className="text-sm text-gray-500">Révision future</span>
                    </span>
                  </div>
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>
      {currentUser.roles === "Admin" && (
        <div className="fixed bottom-6 right-6">
          <button
            type="button"
            onClick={() => (window.location.href = `/newrevision/${vhl._id}`)}
            className="rounded-full bg-indigo-900 p-2 text-white shadow-sm hover:bg-indigo-700"
          >
            <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      )}
    </div>
  );
}
