import React from "react";
import { Link } from "react-router-dom";
import { FolderArrowDownIcon } from '@heroicons/react/20/solid';

function Tutos() {
  const tutos = [
    {
      titre: 'Fiche réflexe Accident',
      description: 'Liste etc etc',
      imageUrl: '/images/accident.jpeg',
      lien: 'https://drive.google.com/file/d/1O7YW6YFoH3JA50gZOQoI-_xgELZHxw69/view?usp=drive_link',
    },
    {
      titre: 'Fiche réflexe Dépannage',
      description: 'Liste etc etc',
      imageUrl: '/images/depannage.avif',
      lien: 'https://drive.google.com/file/d/1l0wvJVVOwUW-UXcjghQOSaiGvxLXMaws/view?usp=drive_link',
    },
  ];

  return (
    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {tutos.map((tuto) => (
        <li key={tuto.titre} className="col-span-1 ml-8 mt-8 mr-8 flex flex-col divide-y divide-gray-200 rounded-lg bg-gray-50 text-center shadow">
          <div className="flex flex-1 flex-col p-8">
            <img className="mx-auto h-50 w-50 flex-shrink-0 rounded-full" src={tuto.imageUrl} alt="" />
            <h3 className="mt-6 text-xl font-semibold text-gray-900">{tuto.titre}</h3>
          </div>
          <div className="mt-4">
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="flex w-0 flex-1">
                <a href={tuto.lien} target="_blank" rel="noopener noreferrer" className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                  <FolderArrowDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  Télécharger
                </a>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default Tutos;
