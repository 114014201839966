import React, { Component } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import authHeader from "../auth-header";

export default class CreateVHL extends Component {
  constructor(props) {
    super(props);

    this.onChangeNomVHL = this.onChangeNomVHL.bind(this);
    this.onChangeTypeVHL = this.onChangeTypeVHL.bind(this);
    this.onChangeService = this.onChangeService.bind(this);
    this.onChangeDossardOrigine = this.onChangeDossardOrigine.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
    this.onChangeKm = this.onChangeKm.bind(this);
    this.onChangePosition = this.onChangePosition.bind(this);
    this.onChangeEmploi = this.onChangeEmploi.bind(this);
    this.onChangeUtilisateur = this.onChangeUtilisateur.bind(this);
    this.onChangeProchainerevision = this.onChangeProchainerevision.bind(this);
    this.onChangeRemarques = this.onChangeRemarques.bind(this);
    this.onChangeDossardbana = this.onChangeDossardbana.bind(this);
    this.onChangeIdentitebana = this.onChangeIdentitebana.bind(this);
    this.onChangeDossardcompl = this.onChangeDossardcompl.bind(this);
    this.onChangePlaquevelcro = this.onChangePlaquevelcro.bind(this);
    this.onChangeMajradio = this.onChangeMajradio.bind(this);
    this.onChangeChargeurintelligent = this.onChangeChargeurintelligent.bind(this);
    this.onChangeBalise = this.onChangeBalise.bind(this);
    this.onChangePneusavant = this.onChangePneusavant.bind(this);
    this.onChangePneusarriere = this.onChangePneusarriere.bind(this);
    this.onChangeTaillepneus = this.onChangeTaillepneus.bind(this);
    this.onChangeCarburant = this.onChangeCarburant.bind(this);
    this.onChangeADblue = this.onChangeADblue.bind(this);
    this.onChangeTailleVHL = this.onChangeTailleVHL.bind(this);
    this.onChangePoidsVHL = this.onChangePoidsVHL.bind(this);
    this.onChangePneusneige = this.onChangePneusneige.bind(this);
    this.onChangeCarteEssSchell = this.onChangeCarteEssSchell.bind(this);
    this.onChangeDateCarteEssSchell = this.onChangeDateCarteEssSchell.bind(this);
    this.onChangeCarteEssTotal = this.onChangeCarteEssTotal.bind(this);
    this.onChangeDateCarteEssTotal = this.onChangeDateCarteEssTotal.bind(this);

    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      nomvhl: "",
      typedevhl: "",
      service: "",
      dossardorigine: "",
      km: "",
      image: "",
      position: "",
      emploi: "",
      utilisateur: "",
      prochainerevision: "",
      remarques: "",
      dossardbana: "",
      identitebana: "",
      dossardcompl: "",
      plaquevelcro: false,
      majradio: "",
      chargeurintelligent: false,
      balise: false,
      pneusavant: "",
      pneusarriere: "",
      taillepneus: "",
      carburant: "",
      adblue: false,
      taillevhl: "",
      poidsvhl: "",
      pneusneige: false,
      currentUser: "",
      carteEssSchell: "",
      dateCarteEssSchell: "",
      carteEssTotal: "",
      dateCarteEssTotal: "",
    };
  }

  componentDidMount() {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        this.setState({
          currentUser: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onChangeNomVHL(e) {
    this.setState({
      nomvhl: e.target.value,
    });
  }

  onChangeTypeVHL(e) {
    this.setState({
      typedevhl: e.target.value,
    });
  }

  onChangeService(e) {
    this.setState({
      service: e.target.value,
    });
  }

  onChangeDossardOrigine(e) {
    this.setState({
      dossardorigine: e.target.value,
    });
  }

  onChangeKm(e) {
    this.setState({
      km: e.target.value,
    });
  }

  onChangeImage(e) {
    this.setState({
      image: e.target.value,
    });
  }

  onChangePosition(position) {
    this.setState({
      position: position,
    });
  }

  onChangeEmploi(emploi) {
    this.setState({
      emploi: emploi,
    });
  }

  onChangeUtilisateur(utilisateur) {
    this.setState({
      utilisateur: utilisateur,
    });
  }

  onChangeProchainerevision(prochainerevision) {
    this.setState({
      prochainerevision: prochainerevision,
    });
  }

  onChangeRemarques(e) {
    this.setState({
      remarques: e.target.value,
    });
  }

  onChangeDossardbana(e) {
    this.setState({
      dossardbana: e.target.value,
    });
  }

  onChangeIdentitebana(e) {
    this.setState({
      identitebana: e.target.value,
    });
  }

  onChangeDossardcompl(e) {
    this.setState({
      dossardcompl: e.target.value,
    });
  }

  onChangePlaquevelcro(e) {
    console.log(e);
    this.setState({
      plaquevelcro: e.target.checked,
    });
  }

  onChangeMajradio(e) {
    this.setState({
      majradio: e.target.value,
    });
  }

  onChangeChargeurintelligent(e) {
    console.log(e);
    this.setState({
      chargeurintelligent: e.target.checked,
    });
  }

  onChangeBalise(e) {
    console.log(e);
    this.setState({
      balise: e.target.checked,
    });
  }

  onChangePneusavant(e) {
    this.setState({
      pneusavant: e.target.value,
    });
  }

  onChangePneusarriere(e) {
    this.setState({
      pneusarriere: e.target.value,
    });
  }

  onChangePneusneige(e) {
    this.setState({
      pneusneige: e.target.checked,
    });
  }

  onChangeTaillepneus(e) {
    this.setState({
      taillepneus: e.target.value,
    });
  }

  onChangeCarburant(e) {
    this.setState({
      carburant: e.target.value,
    });
  }

  onChangeADblue(e) {
    console.log(e);
    this.setState({
      adblue: e.target.checked,
    });
  }

  onChangeTailleVHL(e) {
    this.setState({
      taillevhl: e.target.value,
    });
  }

  onChangePoidsVHL(e) {
    this.setState({
      poidsvhl: e.target.value,
    });
  }

  onChangeCarteEssSchell(e) {
    this.setState({
      carteEssSchell: e.target.value,
    });
  }

  onChangeDateCarteEssSchell(e) {
    this.setState({
      dateCarteEssSchell: e.target.value,
    });
  }

  onChangeCarteEssTotal(e) {
    this.setState({
      carteEssTotal: e.target.value,
    });
  }

  onChangeDateCarteEssTotal(e) {
    this.setState({
      dateCarteEssTotal: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const vhl = {
      nomvhl: this.state.nomvhl,
      typedevhl: this.state.typedevhl,
      service: this.state.service,
      dossardorigine: this.state.dossardorigine,
      km: this.state.km,
      image: this.state.image,
      position: this.state.position,
      emploi: this.state.emploi,
      utilisateur: this.state.utilisateur,
      prochainerevision: this.state.prochainerevision,
      remarques: this.state.remarques,
      dossardbana: this.state.dossardbana,
      identitebana: this.state.identitebana,
      dossardcompl: this.state.dossardcompl,
      plaquevelcro: this.state.plaquevelcro,
      majradio: this.state.majradio,
      chargeurintelligent: this.state.chargeurintelligent,
      balise: this.state.balise,
      pneusavant: this.state.pneusavant,
      pneusarriere: this.state.pneusarriere,
      taillepneus: this.state.taillepneus,
      carburant: this.state.carburant,
      adblue: this.state.adblue,
      taillevhl: this.state.taillevhl,
      poidsvhl: this.state.poidsvhl,
      pneusneige: this.state.pneusneige,
      carteEssSchell: this.state.carteEssSchell,
      dateCarteEssSchell: this.state.dateCarteEssSchell,
      carteEssTotal: this.state.carteEssTotal,
      dateCarteEssTotal: this.state.dateCarteEssTotal
    };

    console.log(vhl);

    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/vhl/add", vhl, { headers: authHeader() })
      .then((res) => {
        console.log(res.data);
        console.log(res.vhl);
        window.location = "/pageadmin";
      })
      .catch((error) => {
        console.error(error);
      });
  }

  
  render() {
    return (
      this.state.currentUser.roles === "Admin" && (
        <div class="centered-page">
          <h3>Ajouter un nouveau véhicule :</h3>
          <form onSubmit={this.onSubmit}>
            <TextField
              id="outlined-password-input"
              label="Nom du véhicule"
              type="text"
              autoComplete="current-password"
              size="small"
              value={this.state.nomvhl}
              onChange={this.onChangeNomVHL}
              className="field-margin"
              sx={{ marginBottom: "10px", width: "95%" }}
            />
            <TextField
              id="outlined-password-input"
              label="Image"
              type="url"
              autoComplete="current-password"
              size="small"
              value={this.state.image}
              onChange={this.onChangeImage}
              className="field-margin"
              sx={{ marginBottom: "10px", width: "95%" }}
            />
            <FormControl fullWidth className="field-margin">
              <InputLabel id="demo-simple-select-label">
                Service
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.service}
                label="typevhl"
                onChange={(event) => this.onChangeService(event)}
                sx={{ marginBottom: "10px", width: "95%" }}
              >
                <MenuItem value="FOR">FOR</MenuItem>
                <MenuItem value="FSP">FSP</MenuItem>
                <MenuItem value="FI">FI</MenuItem>
                <MenuItem value="SMS">SMS</MenuItem>
                <MenuItem value="DT">DT</MenuItem>
                <MenuItem value="CNFIS">CNFIS</MenuItem>
                <MenuItem value="EMOPS">EMOPS</MenuItem>
                <MenuItem value="EMSF">EMSF</MenuItem>
                <MenuItem value="GARAGE">GARAGE</MenuItem>
                <MenuItem value="R&D">R&D</MenuItem>
                <MenuItem value="MAG">MAG</MenuItem>
                <MenuItem value="FA">FA</MenuItem>
                <MenuItem value="CAB">CAB</MenuItem>
                <MenuItem value="AMS">AMS</MenuItem>
                <MenuItem value="CYNO">CYNO</MenuItem>
                <MenuItem value="DPA">DPA</MenuItem>
                <MenuItem value="CIO">CIO</MenuItem>
                <MenuItem value="CTAO">CTAO</MenuItem>
                <MenuItem value="GAV">GAV</MenuItem>
                <MenuItem value="XPRH">XPRH</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth className="field-margin">
              <InputLabel id="demo-simple-select-label">
                Type de véhicule
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.typedevhl}
                label="typevhl"
                onChange={(event) => this.onChangeTypeVHL(event)}
                sx={{ marginBottom: "10px", width: "95%" }}
              >
                <MenuItem value="Classique">Classique</MenuItem>
                <MenuItem value="Track">Track</MenuItem>
                <MenuItem value="Moto">Moto</MenuItem>
                <MenuItem value="SUV">SUV</MenuItem>
                <MenuItem value="Utilitaire">Utilitaire</MenuItem>
                <MenuItem value="Blindé">Blindé</MenuItem>
                <MenuItem value="IMSI">IMSI</MenuItem>
                <MenuItem value="Brouilleur">Brouilleur</MenuItem>
                <MenuItem value="Liaison">Liaison</MenuItem>
              </Select>
            </FormControl>
            <TextField
              id="outlined-password-input"
              label="Dossard d'origine"
              type="text"
              autoComplete="current-password"
              size="small"
              value={this.state.dossardorigine}
              onChange={this.onChangeDossardOrigine}
              className="field-margin"
              sx={{ marginBottom: "10px", width: "95%" }}
            />
            <TextField
              id="outlined-number"
              label="Kilometrage"
              type="number"
              InputLabelProps={{ shrink: true }}
              value={this.state.km}
              onChange={this.onChangeKm}
              className="field-margin"
              sx={{ marginBottom: "10px", width: "95%" }}
            />
            <TextField
              id="outlined-multiline-static"
              label="Remarques"
              type="text"
              multiline
              rows={4}
              value={this.state.remarques}
              onChange={this.onChangeRemarques}
              className="field-margin"
              sx={{ marginBottom: "10px", width: "95%" }}
            />
            <FormControl fullWidth className="field-margin">
              <InputLabel id="demo-simple-select-label">Carburant</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.carburant}
                label="carburant"
                onChange={(event) => this.onChangeCarburant(event)}
                sx={{ marginBottom: "10px", width: "95%" }}
              >
                <MenuItem value="Diesel">Diesel</MenuItem>
                <MenuItem value="Essence">Essence</MenuItem>
              </Select>
            </FormControl>
          
          <TextField
            id="outlined-password-input"
            label="Carte Essence Shell"
            type="text"
            size="small"
            value={this.state.carteEssSchell}
            onChange={this.onChangeCarteEssSchell}
            className="field-margin"
            sx={{ marginBottom: "10px", width: "95%" }}
          />

          <TextField
            id="date"
            label="Expiration Carte Essence Shell"
            type="date"
            size="small"
            value={this.state.dateCarteEssSchell}
            onChange={this.onChangeDateCarteEssSchell}
            className="field-margin"
            sx={{ marginBottom: "10px", width: "95%" }}
          />

          <TextField
            id="outlined-password-input"
            label="Carte Essence Total"
            type="text"
            size="small"
            value={this.state.carteEssTotal}
            onChange={this.onChangeCarteEssTotal}
            className="field-margin"
            sx={{ marginBottom: "10px", width: "95%" }}
          />

          <TextField
            id="date"
            label="Expiration Carte Essence Total"
            type="date"
            size="small"
            value={this.state.dateCarteEssTotal}
            onChange={this.onChangeDateCarteEssTotal}
            className="field-margin"
            sx={{ marginBottom: "10px", width: "95%" }}
          /> 


            {this.state.currentUser.service === "FOR" && (
  <>
    <TextField
      id="outlined-password-input"
      label="Dossard Bana Totale"
      type="text"
      autoComplete="current-password"
      size="small"
      value={this.state.dossardbana}
      onChange={this.onChangeDossardbana}
      className="field-margin"
      sx={{ marginBottom: "10px", width: "95%" }}
    />
    <TextField
      id="outlined-multiline-static"
      label="Identité Bana Totale"
      type="text"
      multiline
      rows={4}
      value={this.state.identitebana}
      onChange={this.onChangeIdentitebana}
      className="field-margin"
      sx={{ marginBottom: "10px", width: "95%" }}
    />
    <TextField
      id="outlined-password-input"
      label="Dossard complémentaire"
      type="text"
      autoComplete="current-password"
      size="small"
      value={this.state.dossardcompl}
      onChange={this.onChangeDossardcompl}
      className="field-margin"
      sx={{ marginBottom: "10px", width: "95%" }}
    />
    <TextField
      id="outlined-password-input"
      label="Pneus avants"
      type="text"
      autoComplete="current-password"
      size="small"
      value={this.state.pneusavant}
      onChange={this.onChangePneusavant}
      className="field-margin"
      sx={{ marginBottom: "10px", width: "95%" }}
    />
    <TextField
      id="outlined-password-input"
      label="Pneus arrières"
      type="text"
      autoComplete="current-password"
      size="small"
      value={this.state.pneusarriere}
      onChange={this.onChangePneusarriere}
      className="field-margin"
      sx={{ marginBottom: "10px", width: "95%" }}
    />
    <TextField
      id="outlined-password-input"
      label="Taille Pneus"
      type="text"
      autoComplete="current-password"
      size="small"
      value={this.state.taillepneus}
      onChange={this.onChangeTaillepneus}
      className="field-margin"
      sx={{ marginBottom: "10px", width: "95%" }}
    />
    <TextField
      id="outlined-password-input"
      label="MAJ Crypto Radio"
      type="text"
      autoComplete="current-password"
      size="small"
      value={this.state.majradio}
      onChange={this.onChangeMajradio}
      className="field-margin"
      sx={{ marginBottom: "10px", width: "95%" }}
    />
    <TextField
      id="outlined-password-input"
      label="Taille VHL"
      type="text"
      autoComplete="current-password"
      size="small"
      value={this.state.taillevhl}
      onChange={this.onChangeTailleVHL}
      className="field-margin"
      sx={{ marginBottom: "10px", width: "95%" }}
    />
    <TextField
      id="outlined-password-input"
      label="Poids VHL"
      type="text"
      autoComplete="current-password"
      size="small"
      value={this.state.poidsvhl}
      onChange={this.onChangePoidsVHL}
      className="field-margin"
      sx={{ marginBottom: "10px", width: "95%" }}
    />
    <FormControlLabel
      control={<Checkbox defaultChecked />}
      label="Balise"
      checked={this.state.balise}
      onChange={this.onChangeBalise}
      className="field-margin"
      sx={{ marginBottom: "10px", width: "95%" }}
    />
    <FormControlLabel
      control={<Checkbox defaultChecked />}
      label="Plaque Magnétique"
      checked={this.state.plaquevelcro}
      onChange={this.onChangePlaquevelcro}
      className="field-margin"
      sx={{ marginBottom: "10px", width: "95%" }}
    />
    <FormControlLabel
      control={<Checkbox defaultChecked />}
      label="Chargeur intelligent"
      checked={this.state.chargeurintelligent}
      onChange={this.onChangeChargeurintelligent}
      className="field-margin"
      sx={{ marginBottom: "10px", width: "95%" }}
    />
    <FormControlLabel
      control={<Checkbox defaultChecked />}
      label="AD-Blue"
      checked={this.state.adblue}
      onChange={this.onChangeADblue}
      className="field-margin"
      sx={{ marginBottom: "10px", width: "95%" }}
    />
    <FormControlLabel
      control={<Checkbox defaultChecked />}
      label="Pneus neige"
      checked={this.state.pneusneige}
      onChange={this.onChangePneusneige}
      className="field-margin"
      sx={{ marginBottom: "10px", width: "95%" }}
    />
  </>
)}

            <div className="form-group text-center">
              <input
                type="submit"
                value="Créer le VHL"
                className="btn btn-primary"
              />
            </div>
          </form>
        </div>
      )
    );
  }
}
