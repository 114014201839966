import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import KeyIcon from '@mui/icons-material/Key';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import MicIcon from '@mui/icons-material/Mic';
import ForestIcon from '@mui/icons-material/Forest';
import MasksIcon from '@mui/icons-material/Masks';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ConstructionIcon from '@mui/icons-material/Construction';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { groupBy } from 'lodash';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListItemAvatar,
  Avatar,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material';
import authHeader from '../../auth-header';

const NewPerception = () => {
  const [typePerception, setTypePerception] = useState('');
  const [nomPerception, setNomPerception] = useState('');
  const [remarques, setRemarques] = useState('');
  const [materiels, setMateriels] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openCOFDialog, setOpenCOFDialog] = useState(false);
  const [openDroneDialog, setOpenDroneDialog] = useState(false);
  const [openMicroDialog, setOpenMicroDialog] = useState(false);
  const [openVerteDialog, setOpenVerteDialog] = useState(false);
  const [openCNPDialog, setOpenCNPDialog] = useState(false);
  const [openInterpelDialog, setOpenInterpelDialog] = useState(false);
  const [openMobiliteDialog, setOpenMobiliteDialog] = useState(false);
  const [openDiversDialog, setOpenDiversDialog] = useState(false);
  const [openSICDialog, setOpenSICDialog] = useState(false);
  const [openTrackDialog, setOpenTrackDialog] = useState(false);
  const [selectedImageMateriels, setSelectedImageMateriels] = useState([]);
  const [selectedDroneMateriels, setSelectedDroneMateriels] = useState([]);
  const [selectedMicroMateriels, setSelectedMicroMateriels] = useState([]);
  const [selectedVerteMateriels, setSelectedVerteMateriels] = useState([]);
  const [selectedCNPMateriels, setSelectedCNPMateriels] = useState([]);
  const [selectedInterpelMateriels, setSelectedInterpelMateriels] = useState([]);
  const [selectedMobiliteMateriels, setSelectedMobiliteMateriels] = useState([]);
  const [selectedDiversMateriels, setSelectedDiversMateriels] = useState([]);
  const [selectedSICMateriels, setSelectedSICMateriels] = useState([]);
  const [selectedTrackMateriels, setSelectedTrackMateriels] = useState([]);
  const [selectedCOFMateriels, setSelectedCOFMateriels] = useState([]);

  const groupedMateriels = {};

  const handleChangeTypePerception = (e) => {
    setTypePerception(e.target.value);
  };

  const handleChangeNomPerception = (e) => {
    setNomPerception(e.target.value);
  };

  const handleChangeRemarques = (e) => {
    setRemarques(e.target.value);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + '/api/materiels/', { headers: authHeader() })
      .then((response) => {
        setMateriels(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + '/api/users/CurrentUser', { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (nomPerception && typePerception) {
      const perceptionMateriel = {
        nomperception: nomPerception,
        typeperception: typePerception,
        remarques,
        date: new Date(),
        image: selectedImageMateriels.map((materiel) => ({
          materiel: {
            _id: materiel._id,
            nom: materiel.nom,
            image: materiel.image,
            nbperception: materiel.nbperception,
            imageperception: materiel.imageperception,
          },
        })),
        cof: selectedCOFMateriels.map((materiel) => ({
          materiel: {
            _id: materiel._id,
            nom: materiel.nom,
            image: materiel.image,
            nbperception: materiel.nbperception,
            imageperception: materiel.imageperception,
          },
        })),
        drone: selectedDroneMateriels.map((materiel) => ({
            materiel: {
              _id: materiel._id,
              nom: materiel.nom,
              image: materiel.image,
              nbperception: materiel.nbperception,
              imageperception: materiel.imageperception,
            },
          })),
          micro: selectedMicroMateriels.map((materiel) => ({
            materiel: {
              _id: materiel._id,
              nom: materiel.nom,
              image: materiel.image,
              nbperception: materiel.nbperception,
              imageperception: materiel.imageperception,
            },
          })),
          verte: selectedVerteMateriels.map((materiel) => ({
            materiel: {
              _id: materiel._id,
              nom: materiel.nom,
              image: materiel.image,
              nbperception: materiel.nbperception,
              imageperception: materiel.imageperception,
            },
          })),
          cnp: selectedCNPMateriels.map((materiel) => ({
            materiel: {
              _id: materiel._id,
              nom: materiel.nom,
              image: materiel.image,
              nbperception: materiel.nbperception,
              imageperception: materiel.imageperception,
            },
          })),
          interpel: selectedInterpelMateriels.map((materiel) => ({
            materiel: {
              _id: materiel._id,
              nom: materiel.nom,
              image: materiel.image,
              nbperception: materiel.nbperception,
              imageperception: materiel.imageperception,
            },
          })),
          mobilite: selectedMobiliteMateriels.map((materiel) => ({
            materiel: {
              _id: materiel._id,
              nom: materiel.nom,
              image: materiel.image,
              nbperception: materiel.nbperception,
              imageperception: materiel.imageperception,
            },
          })),
          divers: selectedDiversMateriels.map((materiel) => ({
            materiel: {
              _id: materiel._id,
              nom: materiel.nom,
              image: materiel.image,
              nbperception: materiel.nbperception,
              imageperception: materiel.imageperception,
            },
          })),
          sic: selectedSICMateriels.map((materiel) => ({
            materiel: {
              _id: materiel._id,
              nom: materiel.nom,
              image: materiel.image,
              nbperception: materiel.nbperception,
              imageperception: materiel.imageperception,
            },
          })),
          track: selectedTrackMateriels.map((materiel) => ({
            materiel: {
              _id: materiel._id,
              nom: materiel.nom,
              image: materiel.image,
              nbperception: materiel.nbperception,
              imageperception: materiel.imageperception,
            },
          })),
      };
  
      axios
        .post(process.env.REACT_APP_BASE_URL + '/api/perceptionmateriel/add', perceptionMateriel, {
          headers: authHeader(),
        })
        .then((res) => {
          window.location = '/materiel';
          console.log(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
  
      
    } else {
      console.log('Veuillez remplir tous les champs obligatoires');
    }
  };  

  const handleOpenImageDialog = () => {
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };

  const handleOpenCOFDialog = () => {
    setOpenCOFDialog(true);
  };

  const handleCloseCOFDialog = () => {
    setOpenCOFDialog(false);
  };

  const handleOpenDroneDialog = () => {
    setOpenDroneDialog(true);
  };

  const handleCloseDroneDialog = () => {
    setOpenDroneDialog(false);
  };

  const handleOpenMicroDialog = () => {
    setOpenMicroDialog(true);
  };

  const handleCloseMicroDialog = () => {
    setOpenMicroDialog(false);
  };

  const handleOpenVerteDialog = () => {
    setOpenVerteDialog(true);
  };

  const handleCloseVerteDialog = () => {
    setOpenVerteDialog(false);
  };

  const handleOpenCNPDialog = () => {
    setOpenCNPDialog(true);
  };

  const handleCloseCNPDialog = () => {
    setOpenCNPDialog(false);
  };

  const handleOpenInterpelDialog = () => {
    setOpenInterpelDialog(true);
  };

  const handleCloseInterpelDialog = () => {
    setOpenInterpelDialog(false);
  };

  const handleOpenMobiliteDialog = () => {
    setOpenMobiliteDialog(true);
  };

  const handleCloseMobiliteDialog = () => {
    setOpenMobiliteDialog(false);
  };

  const handleOpenDiversDialog = () => {
    setOpenDiversDialog(true);
  };

  const handleCloseDiversDialog = () => {
    setOpenDiversDialog(false);
  };

  const handleOpenSICDialog = () => {
    setOpenSICDialog(true);
  };

  const handleCloseSICDialog = () => {
    setOpenSICDialog(false);
  };

  const handleOpenTrackDialog = () => {
    setOpenTrackDialog(true);
  };

  const handleCloseTrackDialog = () => {
    setOpenTrackDialog(false);
  };

  const handleMaterielSelection = (selectedMaterielId, categorie) => {
    const selectedMateriel = materiels.find((materiel) => materiel._id === selectedMaterielId);
    
    if (categorie === 'Image') {
      setSelectedImageMateriels((prevSelectedImageMateriels) => {
        if (prevSelectedImageMateriels.some((materiel) => materiel._id === selectedMaterielId)) {
          return prevSelectedImageMateriels.filter((materiel) => materiel._id !== selectedMaterielId);
        } else {
          selectedMateriel.nbperception = 1;
          return [...prevSelectedImageMateriels, selectedMateriel];
        }
      });
    } else if (categorie === 'COF / Perben') {
      setSelectedCOFMateriels((prevSelectedCOFMateriels) => {
        if (prevSelectedCOFMateriels.some((materiel) => materiel._id === selectedMaterielId)) {
          return prevSelectedCOFMateriels.filter((materiel) => materiel._id !== selectedMaterielId);
        } else {
          selectedMateriel.nbperception = 1;
          return [...prevSelectedCOFMateriels, selectedMateriel];
        }
      });
    } else if (categorie === 'Rens aérien / Drones') {
        setSelectedDroneMateriels((prevSelectedDroneMateriels) => {
          if (prevSelectedDroneMateriels.some((materiel) => materiel._id === selectedMaterielId)) {
            return prevSelectedDroneMateriels.filter((materiel) => materiel._id !== selectedMaterielId);
          } else {
            selectedMateriel.nbperception = 1;
            return [...prevSelectedDroneMateriels, selectedMateriel];
          }
        });
      } else if (categorie === 'Micro') {
        setSelectedMicroMateriels((prevSelectedMicroMateriels) => {
          if (prevSelectedMicroMateriels.some((materiel) => materiel._id === selectedMaterielId)) {
            return prevSelectedMicroMateriels.filter((materiel) => materiel._id !== selectedMaterielId);
          } else {
            selectedMateriel.nbperception = 1;
            return [...prevSelectedMicroMateriels, selectedMateriel];
          }
        });
      } else if (categorie === 'Verte') {
        setSelectedVerteMateriels((prevSelectedVerteMateriels) => {
          if (prevSelectedVerteMateriels.some((materiel) => materiel._id === selectedMaterielId)) {
            return prevSelectedVerteMateriels.filter((materiel) => materiel._id !== selectedMaterielId);
          } else {
            selectedMateriel.nbperception = 1;
            return [...prevSelectedVerteMateriels, selectedMateriel];
          }
        });
      } else if (categorie === 'CNP') {
        setSelectedCNPMateriels((prevSelectedCNPMateriels) => {
          if (prevSelectedCNPMateriels.some((materiel) => materiel._id === selectedMaterielId)) {
            return prevSelectedCNPMateriels.filter((materiel) => materiel._id !== selectedMaterielId);
          } else {
            selectedMateriel.nbperception = 1;
            return [...prevSelectedCNPMateriels, selectedMateriel];
          }
        });
      } else if (categorie === 'Interpel / Armement') {
        setSelectedInterpelMateriels((prevSelectedInterpelMateriels) => {
          if (prevSelectedInterpelMateriels.some((materiel) => materiel._id === selectedMaterielId)) {
            return prevSelectedInterpelMateriels.filter((materiel) => materiel._id !== selectedMaterielId);
          } else {
            selectedMateriel.nbperception = 1;
            return [...prevSelectedInterpelMateriels, selectedMateriel];
          }
        });
      } else if (categorie === 'Mobilité') {
        setSelectedMobiliteMateriels((prevSelectedMobiliteMateriels) => {
          if (prevSelectedMobiliteMateriels.some((materiel) => materiel._id === selectedMaterielId)) {
            return prevSelectedMobiliteMateriels.filter((materiel) => materiel._id !== selectedMaterielId);
          } else {
            selectedMateriel.nbperception = 1;
            return [...prevSelectedMobiliteMateriels, selectedMateriel];
          }
        });
      } else if (categorie === 'Divers') {
        setSelectedDiversMateriels((prevSelectedDiversMateriels) => {
          if (prevSelectedDiversMateriels.some((materiel) => materiel._id === selectedMaterielId)) {
            return prevSelectedDiversMateriels.filter((materiel) => materiel._id !== selectedMaterielId);
          } else {
            selectedMateriel.nbperception = 1;
            return [...prevSelectedDiversMateriels, selectedMateriel];
          }
        });
      } else if (categorie === 'SIC') {
        setSelectedSICMateriels((prevSelectedSICMateriels) => {
          if (prevSelectedSICMateriels.some((materiel) => materiel._id === selectedMaterielId)) {
            return prevSelectedSICMateriels.filter((materiel) => materiel._id !== selectedMaterielId);
          } else {
            selectedMateriel.nbperception = 1;
            return [...prevSelectedSICMateriels, selectedMateriel];
            
          }
        });
      } else if (categorie === 'Track') {
        setSelectedTrackMateriels((prevSelectedTrackMateriels) => {
          if (prevSelectedTrackMateriels.some((materiel) => materiel._id === selectedMaterielId)) {
            return prevSelectedTrackMateriels.filter((materiel) => materiel._id !== selectedMaterielId);
          } else {
            selectedMateriel.nbperception = 1;
            return [...prevSelectedTrackMateriels, selectedMateriel];
          }
        });
      }
  };
  
  const handleNbPerceptionChange = (event, materielId, categorie) => {
    const value = parseInt(event.target.value);
    
    if (categorie === 'Image') {
      setSelectedImageMateriels((prevSelectedImageMateriels) => {
        return prevSelectedImageMateriels.map((materiel) => {
          if (materiel._id === materielId) {
            return {
              ...materiel,
              nbperception: value
            };
          }
          return materiel;
        });
      });
    } else if (categorie === 'COF / Perben') {
      setSelectedCOFMateriels((prevSelectedCOFMateriels) => {
        return prevSelectedCOFMateriels.map((materiel) => {
          if (materiel._id === materielId) {
            return {
              ...materiel,
              nbperception: value
            };
          }
          return materiel;
        });
      });
    } else if (categorie === 'Rens aérien / Drones') {
        setSelectedDroneMateriels((prevSelectedDroneMateriels) => {
          return prevSelectedDroneMateriels.map((materiel) => {
            if (materiel._id === materielId) {
              return {
                ...materiel,
                nbperception: value
              };
            }
            return materiel;
          });
        });
      } else if (categorie === 'Micro') {
        setSelectedMicroMateriels((prevSelectedMicroMateriels) => {
          return prevSelectedMicroMateriels.map((materiel) => {
            if (materiel._id === materielId) {
              return {
                ...materiel,
                nbperception: value
              };
            }
            return materiel;
          });
        });
      } else if (categorie === 'Verte') {
        setSelectedVerteMateriels((prevSelectedVerteMateriels) => {
          return prevSelectedVerteMateriels.map((materiel) => {
            if (materiel._id === materielId) {
              return {
                ...materiel,
                nbperception: value
              };
            }
            return materiel;
          });
        });
      } else if (categorie === 'CNP') {
        setSelectedCNPMateriels((prevSelectedCNPMateriels) => {
          return prevSelectedCNPMateriels.map((materiel) => {
            if (materiel._id === materielId) {
              return {
                ...materiel,
                nbperception: value
              };
            }
            return materiel;
          });
        });
      } else if (categorie === 'Interpel / Armement') {
        setSelectedInterpelMateriels((prevSelectedInterpelMateriels) => {
          return prevSelectedInterpelMateriels.map((materiel) => {
            if (materiel._id === materielId) {
              return {
                ...materiel,
                nbperception: value
              };
            }
            return materiel;
          });
        });
      } else if (categorie === 'Mobilité') {
        setSelectedMobiliteMateriels((prevSelectedMobiliteMateriels) => {
          return prevSelectedMobiliteMateriels.map((materiel) => {
            if (materiel._id === materielId) {
              return {
                ...materiel,
                nbperception: value
              };
            }
            return materiel;
          });
        });
      } else if (categorie === 'Divers') {
        setSelectedDiversMateriels((prevSelectedDiversMateriels) => {
          return prevSelectedDiversMateriels.map((materiel) => {
            if (materiel._id === materielId) {
              return {
                ...materiel,
                nbperception: value
              };
            }
            return materiel;
          });
        });
      } else if (categorie === 'SIC') {
        setSelectedSICMateriels((prevSelectedSICMateriels) => {
          return prevSelectedSICMateriels.map((materiel) => {
            if (materiel._id === materielId) {
              return {
                ...materiel,
                nbperception: value
              };
            }
            return materiel;
          });
        });
      } else if (categorie === 'Track') {
        setSelectedTrackMateriels((prevSelectedTrackMateriels) => {
          return prevSelectedTrackMateriels.map((materiel) => {
            if (materiel._id === materielId) {
              return {
                ...materiel,
                nbperception: value
              };
            }
            return materiel;
          });
        });
      }
  };

  return (
    <div className="centered-page">
      <h2 style={{ marginBottom: '20px', fontWeight: 'bold' }}>Nouvelle perception :</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          id="outlined-password-input"
          label="Nom de la perception"
          type="text"
          autoComplete="current-password"
          size="small"
          required
          value={nomPerception}
          onChange={handleChangeNomPerception}
          className="field-margin"
          sx={{ marginBottom: '10px', width: '95%' }}
        />

        <FormControl fullWidth className="field-margin">
          <InputLabel id="demo-simple-select-label">Type de perception</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={typePerception}
            label="typeperception"
            onChange={handleChangeTypePerception}
            sx={{ marginBottom: '10px', width: '95%' }}
          >
            <MenuItem value="Mission">Mission</MenuItem>
            <MenuItem value="Exercice">Exercice</MenuItem>
            <MenuItem value="Réparation">Réparation</MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="outlined-password-input"
          label="Remarques"
          type="text"
          autoComplete="current-password"
          size="small"
          value={remarques}
          multiline
          rows={4}
          onChange={handleChangeRemarques}
          className="field-margin"
          sx={{ marginBottom: '10px', width: '95%' }}
        />
        <div>
        <Button variant="text" startIcon={<CameraAltIcon />} onClick={handleOpenImageDialog}>
          Matériel Image
        </Button>
        <Dialog onClose={handleCloseImageDialog} open={openImageDialog}>
          <DialogTitle>Matériel Image</DialogTitle>
          <DialogContent>
          <List>
  {Object.entries(
    groupBy(
      materiels.filter((materiel) => materiel.categorie === 'Image'),
      'imageperception'
    )
  )
    .sort(([a], [b]) => b.localeCompare(a)) // Trie les groupes par imageperception
    .map(([imageperception, groupe]) => (
      <React.Fragment key={imageperception}>
        {groupe
          .sort((a, b) => (a.nom ?? '').localeCompare(b.nom ?? '')) // Trie les éléments du groupe par nom
          .map((materiel) => {
            const isMaterielSelected = selectedImageMateriels.some((selectedMateriel) => selectedMateriel._id === materiel._id);
            const nbPerceptionValue = isMaterielSelected ? selectedImageMateriels.find((selectedMateriel) => selectedMateriel._id === materiel._id).nbperception : 1;

            return (
              <ListItem disableGutters key={materiel._id}>
                <ListItemAvatar>
                  <Avatar src={materiel.imageperception} />
                </ListItemAvatar>
                <ListItemText primary={materiel.nom} />
                <Checkbox
                  checked={isMaterielSelected}
                  onChange={() => handleMaterielSelection(materiel._id, 'Image')}
                />
                {isMaterielSelected && (
                  <TextField
                    id={`nbPerception_${materiel._id}`}
                    label="Nombre de perceptions"
                    type="number"
                    value={nbPerceptionValue}
                    onChange={(e) => handleNbPerceptionChange(e, materiel._id, 'Image')}
                    sx={{ marginLeft: '10px', width: 40 }}
                  />
                )}
              </ListItem>
            );
          })}
      </React.Fragment>
    ))}
</List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImageDialog}>Fermer</Button>
          </DialogActions>
        </Dialog>
        </div>
        <div>
        <Button variant="text" startIcon={<KeyIcon />} onClick={handleOpenCOFDialog}>
          Matériel COF / Perben
        </Button>
        <Dialog onClose={handleCloseCOFDialog} open={openCOFDialog}>
          <DialogTitle>Matériel COF / Perben</DialogTitle>
          <DialogContent>
          <List>
  {materiels
    .filter((materiel) => materiel.categorie === 'COF / Perben')
    .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
    .map((materiel) => {
      const isMaterielSelected = selectedCOFMateriels.some((selectedMateriel) => selectedMateriel._id === materiel._id);
      const nbPerceptionValue = isMaterielSelected ? selectedCOFMateriels.find((selectedMateriel) => selectedMateriel._id === materiel._id).nbperception : 1;
      
      return (
        <ListItem disableGutters key={materiel._id}>
          <ListItemAvatar>
            <Avatar src={materiel.imageperception} />
          </ListItemAvatar>
          <ListItemText primary={materiel.nom} />
          <Checkbox
            checked={isMaterielSelected}
            onChange={() => handleMaterielSelection(materiel._id, 'COF / Perben')}
          />
          {isMaterielSelected && (
            <TextField
              id={`nbPerception_${materiel._id}`}
              label="Nombre de perceptions"
              type="number"
              value={nbPerceptionValue}
              onChange={(e) => handleNbPerceptionChange(e, materiel._id, 'COF / Perben')}
              sx={{ marginLeft: '10px', width: 40  }}
            />
          )}
        </ListItem>
      );
    })}
</List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCOFDialog}>Fermer</Button>
          </DialogActions>
        </Dialog>
        </div>
        <div>
        <Button variant="text" startIcon={<AirplanemodeActiveIcon />} onClick={handleOpenDroneDialog}>
          Matériel Rens aérien / Drone
        </Button>
        <Dialog onClose={handleCloseDroneDialog} open={openDroneDialog}>
          <DialogTitle>Matériel Rens aérien / Drone</DialogTitle>
          <DialogContent>
          <List>
  {materiels
    .filter((materiel) => materiel.categorie === 'Rens aérien / Drones')
    .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
    .map((materiel) => {
      const isMaterielSelected = selectedDroneMateriels.some((selectedMateriel) => selectedMateriel._id === materiel._id);
      const nbPerceptionValue = isMaterielSelected ? selectedDroneMateriels.find((selectedMateriel) => selectedMateriel._id === materiel._id).nbperception : 1;
      
      return (
        <ListItem disableGutters key={materiel._id}>
          <ListItemAvatar>
            <Avatar src={materiel.imageperception} />
          </ListItemAvatar>
          <ListItemText primary={materiel.nom} />
          <Checkbox
            checked={isMaterielSelected}
            onChange={() => handleMaterielSelection(materiel._id, 'Rens aérien / Drones')}
          />
          {isMaterielSelected && (
            <TextField
              id={`nbPerception_${materiel._id}`}
              label="Nombre de perceptions"
              type="number"
              value={nbPerceptionValue}
              onChange={(e) => handleNbPerceptionChange(e, materiel._id, 'Rens aérien / Drones')}
              sx={{ marginLeft: '10px', width: 40 }}
            />
          )}
        </ListItem>
      );
    })}
</List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDroneDialog}>Fermer</Button>
          </DialogActions>
        </Dialog>
        </div>
        <div>
        <Button variant="text" startIcon={<MicIcon />} onClick={handleOpenMicroDialog}>
          Matériel Micro
        </Button>
        <Dialog onClose={handleCloseMicroDialog} open={openMicroDialog}>
          <DialogTitle>Matériel Micro</DialogTitle>
          <DialogContent>
          <List>
  {materiels
    .filter((materiel) => materiel.categorie === 'Micro')
    .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
    .map((materiel) => {
      const isMaterielSelected = selectedMicroMateriels.some((selectedMateriel) => selectedMateriel._id === materiel._id);
      const nbPerceptionValue = isMaterielSelected ? selectedMicroMateriels.find((selectedMateriel) => selectedMateriel._id === materiel._id).nbperception : 1;
      
      return (
        <ListItem disableGutters key={materiel._id}>
          <ListItemAvatar>
            <Avatar src={materiel.imageperception} />
          </ListItemAvatar>
          <ListItemText primary={materiel.nom} />
          <Checkbox
            checked={isMaterielSelected}
            onChange={() => handleMaterielSelection(materiel._id, 'Micro')}
          />
          {isMaterielSelected && (
            <TextField
              id={`nbPerception_${materiel._id}`}
              label="Nombre de perceptions"
              type="number"
              value={nbPerceptionValue}
              onChange={(e) => handleNbPerceptionChange(e, materiel._id, 'Micro')}
              sx={{ marginLeft: '10px', width: 40 }}
            />
          )}
        </ListItem>
      );
    })}
</List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMicroDialog}>Fermer</Button>
          </DialogActions>
        </Dialog>
        </div>
        <div>
        <Button variant="text" startIcon={<ForestIcon />} onClick={handleOpenVerteDialog}>
          Matériel Verte
        </Button>
        <Dialog onClose={handleCloseVerteDialog} open={openVerteDialog}>
          <DialogTitle>Matériel Verte</DialogTitle>
          <DialogContent>
          <List>
  {materiels
    .filter((materiel) => materiel.categorie === 'Verte')
    .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
    .map((materiel) => {
      const isMaterielSelected = selectedVerteMateriels.some((selectedMateriel) => selectedMateriel._id === materiel._id);
      const nbPerceptionValue = isMaterielSelected ? selectedVerteMateriels.find((selectedMateriel) => selectedMateriel._id === materiel._id).nbperception : 1;
      
      return (
        <ListItem disableGutters key={materiel._id}>
          <ListItemAvatar>
            <Avatar src={materiel.imageperception} />
          </ListItemAvatar>
          <ListItemText primary={materiel.nom} />
          <Checkbox
            checked={isMaterielSelected}
            onChange={() => handleMaterielSelection(materiel._id, 'Verte')}
          />
          {isMaterielSelected && (
            <TextField
              id={`nbPerception_${materiel._id}`}
              label="Nombre de perceptions"
              type="number"
              value={nbPerceptionValue}
              onChange={(e) => handleNbPerceptionChange(e, materiel._id, 'Verte')}
              sx={{ marginLeft: '10px', width: 40 }}
            />
          )}
        </ListItem>
      );
    })}
</List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseVerteDialog}>Fermer</Button>
          </DialogActions>
        </Dialog>
        </div>
        <div>
        <Button variant="text" startIcon={<MasksIcon />} onClick={handleOpenCNPDialog}>
          Matériel CNP
        </Button>
        <Dialog onClose={handleCloseCNPDialog} open={openCNPDialog}>
          <DialogTitle>Matériel CNP</DialogTitle>
          <DialogContent>
          <List>
  {materiels
    .filter((materiel) => materiel.categorie === 'CNP')
    .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
    .map((materiel) => {
      const isMaterielSelected = selectedCNPMateriels.some((selectedMateriel) => selectedMateriel._id === materiel._id);
      const nbPerceptionValue = isMaterielSelected ? selectedCNPMateriels.find((selectedMateriel) => selectedMateriel._id === materiel._id).nbperception : 1;
      
      return (
        <ListItem disableGutters key={materiel._id}>
          <ListItemAvatar>
            <Avatar src={materiel.imageperception} />
          </ListItemAvatar>
          <ListItemText primary={materiel.nom} />
          <Checkbox
            checked={isMaterielSelected}
            onChange={() => handleMaterielSelection(materiel._id, 'CNP')}
          />
          {isMaterielSelected && (
            <TextField
              id={`nbPerception_${materiel._id}`}
              label="Nombre de perceptions"
              type="number"
              value={nbPerceptionValue}
              onChange={(e) => handleNbPerceptionChange(e, materiel._id, 'CNP')}
              sx={{ marginLeft: '10px', width: 40 }}
            />
          )}
        </ListItem>
      );
    })}
</List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCNPDialog}>Fermer</Button>
          </DialogActions>
        </Dialog>
        </div>
        <div>
        <Button variant="text" startIcon={<LocalFireDepartmentIcon />} onClick={handleOpenInterpelDialog}>
          Matériel Interpel / Armement
        </Button>
        <Dialog onClose={handleCloseInterpelDialog} open={openInterpelDialog}>
          <DialogTitle>Matériel Interpel / Armement</DialogTitle>
          <DialogContent>
          <List>
  {materiels
    .filter((materiel) => materiel.categorie === 'Interpel / Armement')
    .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
    .map((materiel) => {
      const isMaterielSelected = selectedInterpelMateriels.some((selectedMateriel) => selectedMateriel._id === materiel._id);
      const nbPerceptionValue = isMaterielSelected ? selectedInterpelMateriels.find((selectedMateriel) => selectedMateriel._id === materiel._id).nbperception : 1;
      
      return (
        <ListItem disableGutters key={materiel._id}>
          <ListItemAvatar>
            <Avatar src={materiel.imageperception} />
          </ListItemAvatar>
          <ListItemText primary={materiel.nom} />
          <Checkbox
            checked={isMaterielSelected}
            onChange={() => handleMaterielSelection(materiel._id, 'Interpel / Armement')}
          />
          {isMaterielSelected && (
            <TextField
              id={`nbPerception_${materiel._id}`}
              label="Nombre de perceptions"
              type="number"
              value={nbPerceptionValue}
              onChange={(e) => handleNbPerceptionChange(e, materiel._id, 'Interpel / Armement')}
              sx={{ marginLeft: '10px', width: 40 }}
            />
          )}
        </ListItem>
      );
    })}
</List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseInterpelDialog}>Fermer</Button>
          </DialogActions>
        </Dialog>
        </div>
        <div>
        <Button variant="text" startIcon={<DirectionsCarIcon />} onClick={handleOpenMobiliteDialog}>
          Matériel Mobilité
        </Button>
        <Dialog onClose={handleCloseMobiliteDialog} open={openMobiliteDialog}>
          <DialogTitle>Matériel Mobilité</DialogTitle>
          <DialogContent>
          <List>
  {materiels
    .filter((materiel) => materiel.categorie === 'Mobilité')
    .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
    .map((materiel) => {
      const isMaterielSelected = selectedMobiliteMateriels.some((selectedMateriel) => selectedMateriel._id === materiel._id);
      const nbPerceptionValue = isMaterielSelected ? selectedMobiliteMateriels.find((selectedMateriel) => selectedMateriel._id === materiel._id).nbperception : 1;
      
      return (
        <ListItem disableGutters key={materiel._id}>
          <ListItemAvatar>
            <Avatar src={materiel.imageperception} />
          </ListItemAvatar>
          <ListItemText primary={materiel.nom} />
          <Checkbox
            checked={isMaterielSelected}
            onChange={() => handleMaterielSelection(materiel._id, 'Mobilité')}
          />
          {isMaterielSelected && (
            <TextField
              id={`nbPerception_${materiel._id}`}
              label="Nombre de perceptions"
              type="number"
              value={nbPerceptionValue}
              onChange={(e) => handleNbPerceptionChange(e, materiel._id, 'Mobilité')}
              sx={{ marginLeft: '10px', width: 40 }}
            />
          )}
        </ListItem>
      );
    })}
</List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMobiliteDialog}>Fermer</Button>
          </DialogActions>
        </Dialog>
        </div>
        <div>
        <Button variant="text" startIcon={<ConstructionIcon />} onClick={handleOpenDiversDialog}>
          Matériel Divers
        </Button>
        <Dialog onClose={handleCloseDiversDialog} open={openDiversDialog}>
          <DialogTitle>Matériel Divers</DialogTitle>
          <DialogContent>
          <List>
  {materiels
    .filter((materiel) => materiel.categorie === 'Divers')
    .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
    .map((materiel) => {
      const isMaterielSelected = selectedDiversMateriels.some((selectedMateriel) => selectedMateriel._id === materiel._id);
      const nbPerceptionValue = isMaterielSelected ? selectedDiversMateriels.find((selectedMateriel) => selectedMateriel._id === materiel._id).nbperception : 1;
      
      return (
        <ListItem disableGutters key={materiel._id}>
          <ListItemAvatar>
            <Avatar src={materiel.imageperception} />
          </ListItemAvatar>
          <ListItemText primary={materiel.nom} />
          <Checkbox
            checked={isMaterielSelected}
            onChange={() => handleMaterielSelection(materiel._id, 'Divers')}
          />
          {isMaterielSelected && (
            <TextField
              id={`nbPerception_${materiel._id}`}
              label="Nombre de perceptions"
              type="number"
              value={nbPerceptionValue}
              onChange={(e) => handleNbPerceptionChange(e, materiel._id, 'Divers')}
              sx={{ marginLeft: '10px', width: 40 }}
            />
          )}
        </ListItem>
      );
    })}
</List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDiversDialog}>Fermer</Button>
          </DialogActions>
        </Dialog>
        </div>
        <div>
        <Button variant="text" startIcon={<SettingsInputAntennaIcon />} onClick={handleOpenSICDialog}>
          Matériel SIC
        </Button>
        <Dialog onClose={handleCloseSICDialog} open={openSICDialog}>
          <DialogTitle>Matériel SIC</DialogTitle>
          <DialogContent>
          <List>
  {materiels
    .filter((materiel) => materiel.categorie === 'SIC')
    .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
    .map((materiel) => {
      const isMaterielSelected = selectedSICMateriels.some((selectedMateriel) => selectedMateriel._id === materiel._id);
      const nbPerceptionValue = isMaterielSelected ? selectedSICMateriels.find((selectedMateriel) => selectedMateriel._id === materiel._id).nbperception : 1;
      
      return (
        <ListItem disableGutters key={materiel._id}>
          <ListItemAvatar>
            <Avatar src={materiel.imageperception} />
          </ListItemAvatar>
          <ListItemText primary={materiel.nom} />
          <Checkbox
            checked={isMaterielSelected}
            onChange={() => handleMaterielSelection(materiel._id, 'SIC')}
          />
          {isMaterielSelected && (
            <TextField
              id={`nbPerception_${materiel._id}`}
              label="Nombre de perceptions"
              type="number"
              value={nbPerceptionValue}
              onChange={(e) => handleNbPerceptionChange(e, materiel._id, 'SIC')}
              sx={{ marginLeft: '10px', width: 40 }}
            />
          )}
        </ListItem>
      );
    })}
</List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSICDialog}>Fermer</Button>
          </DialogActions>
        </Dialog>
        </div>
        <div>
        <Button variant="text" startIcon={<LocationOnIcon />} onClick={handleOpenTrackDialog}>
          Matériel Track
        </Button>
        <Dialog onClose={handleCloseTrackDialog} open={openTrackDialog}>
          <DialogTitle>Matériel Track</DialogTitle>
          <DialogContent>
          <List>
  {materiels
    .filter((materiel) => materiel.categorie === 'Track')
    .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
    .map((materiel) => {
      const isMaterielSelected = selectedTrackMateriels.some((selectedMateriel) => selectedMateriel._id === materiel._id);
      const nbPerceptionValue = isMaterielSelected ? selectedTrackMateriels.find((selectedMateriel) => selectedMateriel._id === materiel._id).nbperception : 1;
      
      return (
        <ListItem disableGutters key={materiel._id}>
          <ListItemAvatar>
            <Avatar src={materiel.imageperception} />
          </ListItemAvatar>
          <ListItemText primary={materiel.nom} />
          <Checkbox
            checked={isMaterielSelected}
            onChange={() => handleMaterielSelection(materiel._id, 'Track')}
          />
          {isMaterielSelected && (
            <TextField
              id={`nbPerception_${materiel._id}`}
              label="Nombre de perceptions"
              type="number"
              value={nbPerceptionValue}
              onChange={(e) => handleNbPerceptionChange(e, materiel._id, 'Track')}
              sx={{ marginLeft: '10px', width: 40 }}
            />
          )}
        </ListItem>
      );
    })}
</List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseTrackDialog}>Fermer</Button>
          </DialogActions>
        </Dialog>
        </div>
        <div className="form-group text-center">
          <Button variant="contained" color="primary" type="submit" className="btn btn-primary">
            Enregistrer
          </Button>
        </div>
      </form>
    </div>
  );
};

export default NewPerception;
