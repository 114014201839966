import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import authHeader from "../auth-header";
import { CalendarDaysIcon, PencilSquareIcon, TrashIcon } from "@heroicons/react/20/solid";

function DetailMateriel(props) {
  const [materiel, setMateriel] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const [newRemarques, setNewRemarques] = useState(materiel.remarques);
  const [currentUser, setCurrentUser] = useState({});
  const cancelButtonRef = useRef(null)
  const cancelButtonRefRemarques = useRef(null)

  const { id } = useParams();

  const [openRemarques, setOpenRemarques] = React.useState(false);


  const handleClickOpenRemarques = () => {
    setOpenRemarques(true);
  };

  const handleCloseRemarques = () => {
    setOpenRemarques(false);
  };

  const deleteMateriel = (id) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir supprimer ce matériel ?"
      )
    ) {
    axios
      .delete(process.env.REACT_APP_BASE_URL + "/api/materiels/" + id, { headers: authHeader() })
      .then((res) => {
        window.location = '/materiel/toutmateriel';
      })
    }
  };


  function ValiderRemarques(e) {
    setOpenRemarques(false);
    const updatedValue = {
      ...materiel,
      remarques: newRemarques,
    };
    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/materiels/update/" + id, updatedValue, {
        headers: authHeader(),
      })
      .then((res) => {
        setMateriel((prevMateriel) => ({ ...prevMateriel, ...updatedValue }));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/materiels/" + id, { headers: authHeader() })
        .then((response) => {
          setMateriel(response.data);
          setNewRemarques(response.data.remarques);
        })
        .catch((error) => {
          console.log(error);
        });
      setisLoading(false);
    }
  }, [id, isLoading]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="page-container">
        <div className="flex flex-col items-center">
    <img
      className="h-40 w-40 rounded-full object-cover mb-3"
      src={materiel.image}
      alt={materiel.nom}
    />
    <h3 className="text-2xl font-bold text-center">{materiel.nom}</h3>
    <h2 className="text-lg italic text-center mb-6">{materiel.categorie}</h2>
  </div>

  <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
    <dt className="text-sm font-semibold leading-6 text-gray-900">Catégorie Alerte</dt>
    <dd className="ml-auto text-sm leading-6 text-gray-700">
      {materiel.alerte}
    </dd>
  </div>
  <div className="bg-white px-8 py-2 flex flex-row items-center">
    <dt className="text-sm font-semibold leading-6 text-gray-900">Emplacement stockage</dt>
    <dd className="ml-auto text-sm leading-6 text-gray-700">
      {materiel.emplacement}
    </dd>
  </div>

  <div>
  <div className="flex flex-col items-center justify-center">
  <button
    type="button"
    onClick={handleClickOpenRemarques}
    className="text-sm -mb-2 mt-4 uppercase inline-flex items-center justify-center rounded-lg bg-white opacity-90 px-4 py-1.5 text-indigo-900 font-medium shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    style={{ width: "300px" }}
  >
    <span className="flex items-center">
    <PencilSquareIcon className="ml-1 mr-3 h-5 w-5" aria-hidden="true" />
      Remarques
      </span>
  </button>
</div>

<Transition.Root show={openRemarques} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={handleCloseRemarques}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                <Dialog.Panel className="relative transform translate-y-0 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Remarques / Détails
                    </Dialog.Title>
                    <div className="mt-2">
                      <textarea
                      rows={5}
                      name="remarques"
                      id="remarques"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-800 sm:text-sm sm:leading-6"
                      value={newRemarques}
                      onChange={(e) => setNewRemarques(e.target.value)}
                      onClick={(e) => e.stopPropagation()}
                      onMouseDown={(e) => e.stopPropagation()}
                        />
                      </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={ValiderRemarques}
                  >
                    Valider
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={handleCloseRemarques}
                    ref={cancelButtonRefRemarques}
                  >
                    Annuler
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </div>


    <div className="mt-2 mb-4 flex justify-center items-center">
      <textarea
        rows={4}
        name="comment"
        id="comment"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-900 sm:text-sm sm:leading-6"
        defaultValue={''}
        style={{ width: "300px" }}
        value={newRemarques}
        disabled
      />
    </div>

    <div className="mt-4 flex flex-col items-center justify-center">
  <button
    type="button"
    onClick={() => (window.location.href = `/histoperceptionmateriel/${materiel._id}`)}
    className="mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white opacity-90 border-2 border-indigo-900 px-4 py-1.5 text-indigo-900 font-medium shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    style={{ width: "300px" }}
  >
    <span className="flex items-center">
    <CalendarDaysIcon className="ml-1 mr-3 h-5 w-5" aria-hidden="true" />
      Historique des perceptions</span>
  </button>
</div>
      
      
      {currentUser.roles === "Admin" ? (
      <div className="flex item-center justify-center">
      <TrashIcon
        onClick={() => deleteMateriel(materiel._id)}
        className="w-6 h-6 mr-4 text-red-500 cursor-pointer"
      />
      <PencilSquareIcon
        onClick={() => (window.location.href = `/editmateriel/${materiel._id}`)}
        className="w-6 h-6 text-blue-500 cursor-pointer"
      />
      </div>
      ) : (
          ""
        )}
    </div>
  );
}

export default DetailMateriel;
