import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import authHeader from "../auth-header";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function EditVHL(props) {

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/vhl/" + id, { headers: authHeader() })
        .then((response) => {
          setVhl(response.data);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
      setisLoading(false);
      axios
        .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", {
          headers: authHeader(),
        })
        .then((response) => {
          setCurrentUser(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });

  const [vhl, setVhl] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const { id } = useParams();


  function onChangeNomVHL(e) {
    let updatedvalue = { nomvhl: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeTypeVHL(e) {
    let updatedvalue = { typedevhl: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeService(e) {
    let updatedvalue = { service: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeDossardOrigine(e) {
    let updatedvalue = { dossardorigine: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeImage(e) {
    let updatedvalue = { image: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeKm(e) {
    let updatedvalue = { km: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }
  
  function onChangeRemarques(e) {
    let updatedvalue = { remarques: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeDossardbana(e) {
    let updatedvalue = { dossardbana: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeIdentitebana(e) {
    let updatedvalue = { identitebana: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeDossardcompl(e) {
    let updatedvalue = { dossardcompl: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangePlaquevelcro(e) {
    let updatedvalue = { plaquevelcro: e.target.checked };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeMajradio(e) {
    let updatedvalue = { majradio: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeChargeurintelligent(e) {
    let updatedvalue = { chargeurintelligent: e.target.checked };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeBalise(e) {
    let updatedvalue = { balise: e.target.checked };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangePneusavant(e) {
    let updatedvalue = { pneusavant: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangePneusarriere(e) {
    let updatedvalue = { pneusarriere: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeTaillepneus(e) {
    let updatedvalue = { taillepneus: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeCarburant(e) {
    let updatedvalue = { carburant: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeADblue(e) {
    let updatedvalue = { adblue: e.target.checked };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeTailleVHL(e) {
    let updatedvalue = { taillevhl: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangePoidsVHL(e) {
    let updatedvalue = { poidsvhl: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangePneusneige(e) {
    let updatedvalue = { pneusneige: e.target.checked };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeCarteEssShell(e) {
    let updatedvalue = { carteEssSchell: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeDateCarteEssShell(e) {
    let updatedvalue = { dateCarteEssSchell: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeCarteEssTotal(e) {
    let updatedvalue = { carteEssTotal: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onChangeDateCarteEssTotal(e) {
    let updatedvalue = { dateCarteEssTotal: e.target.value };
    setVhl((vhl) => ({
      ...vhl,
      ...updatedvalue,
    }));
  }

  function onSubmit(e) {
    e.preventDefault();

    console.log(vhl);

    axios
      .post(process.env.REACT_APP_BASE_URL + "/api/vhl/update/" + id, vhl, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res.data)
        window.location = "/pageadmin/listadminvhl";
      });
  }

  return (
    <div>
    <h3 className="text-2xl font-bold text-center mb-6">Editer {vhl.nomvhl}</h3>
    <form onSubmit={(e) => onSubmit(e)}>
      <div className="relative mt-5">
      <label
        htmlFor="nom"
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        Nom VHL
      </label>
      <input
        type="text"
        name="nom"
        id="nom"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
        value={vhl.nomvhl}
        onChange={(e) => onChangeNomVHL(e)}
      />
    </div>

    <div className="relative mt-5">
<label
  htmlFor="service"
  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
>
  Service
</label>
<input
  type="text"
  name="service"
  id="service"
  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
  value={vhl.service}
  onChange={(e) => onChangeService(e)}
/>
</div>

<div className="relative mt-5">
<label
  htmlFor="image"
  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
>
  Image VHL (URL)
</label>
<input
  type="text"
  name="image"
  id="image"
  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
  value={vhl.image}
  onChange={(e) => onChangeImage(e)}
/>
</div>

<div className="relative mt-5">
<label
  htmlFor="image"
  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
>
  Type de VHL
</label>
<input
  type="text"
  name="image"
  id="image"
  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
  value={vhl.typedevhl}
  onChange={(e) => onChangeTypeVHL(e)}
/>
</div>


<div className="relative mt-5">
<label
  htmlFor="dossard"
  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
>
  Immatriculation d'origine
</label>
<input
  type="text"
  name="dossard"
  id="dossard"
  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
  value={vhl.dossardorigine}
  onChange={(e) => onChangeDossardOrigine(e)}
/>
</div>

<div className="relative mt-5">
<label
  htmlFor="km"
  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
>
  Kilométrage
</label>
<input
  type="number"
  name="km"
  id="km"
  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
  value={vhl.km}
  onChange={(e) => onChangeKm(e)}
/>
</div>

<div className="relative mt-5">
<label
  htmlFor="remarques"
  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
>
  Remarques
</label>
<input
  type="text"
  name="remarques"
  id="remarques"
  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
  value={vhl.remarques}
  onChange={(e) => onChangeRemarques(e)}
/>
</div>

<div className="relative mt-5">
<label
  htmlFor="carburant"
  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
>
  Carburant
</label>
<input
  type="text"
  name="carburant"
  id="carburant"
  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
  value={vhl.carburant}
  onChange={(e) => onChangeCarburant(e)}
/>
</div>

<div className="relative mt-5">
<label
  htmlFor="carburant"
  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
>
  Carte Essence Shell
</label>
<input
  type="text"
  name="carteEss"
  id="carteEss"
  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
  value={vhl.carteEssSchell}
  onChange={(e) => onChangeCarteEssShell(e)}
/>
</div>

<div className="relative mt-5">
<label
  htmlFor="carburant"
  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
>
  Date Exp. Carte Shell
</label>
<input
  type="date"
  name="carteEss"
  id="carteEss"
  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
  value={vhl.dateCarteEssSchell}
  onChange={(e) => onChangeDateCarteEssShell(e)}
/>
</div>

<div className="relative mt-5">
<label
  htmlFor="carburant"
  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
>
  Carte Essence Total
</label>
<input
  type="text"
  name="carteEss"
  id="carteEss"
  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
  value={vhl.carteEssTotal}
  onChange={(e) => onChangeCarteEssTotal(e)}
/>
</div>

<div className="relative mt-5">
<label
  htmlFor="carburant"
  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
>
  Date Exp. Total
</label>
<input
  type="date"
  name="carteEss"
  id="carteEss"
  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
  value={vhl.dateCarteEssTotal}
  onChange={(e) => onChangeDateCarteEssTotal(e)}
/>
</div>

          {(currentUser.service === "FOR"  || currentUser.service === "SMS")  && (
<div>
  <div className="relative mt-5">
  <label
    htmlFor="dossardbana"
    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
  >
    Dossard Bana Totale
  </label>
  <input
    type="text"
    name="dossardbana"
    id="dossardbana"
    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
    value={vhl.dossardbana}
    onChange={(e) => onChangeDossardbana(e)}
  />
  </div>

<div className="relative mt-5">
<label
  htmlFor="identite"
  className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
>
  Identité Bana Totale
</label>
<input
  type="text"
  name="identite"
  id="identite"
  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
  value={vhl.identitebana}
  onChange={(e) => onChangeIdentitebana(e)}
/>
</div>

        <div className="relative mt-5">
        <label
          htmlFor="dossardcompl"
          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
        >
          Dossard Complémentaire
        </label>
        <input
          type="text"
          name="dossardcompl"
          id="dossardcompl"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
          value={vhl.dossardcompl}
          onChange={(e) => onChangeDossardcompl(e)}
        />
        </div> 
          </div>
          )}
          {(currentUser.service === "FOR")  && (
            <div>
          <div className="relative mt-5">
          <label
            htmlFor="radio"
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
          >
            MAJ Radio
          </label>
          <input
            type="text"
            name="radio"
            id="radio"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
            value={vhl.majradio}
            onChange={(e) => onChangeMajradio(e)}
          />
          </div>
         
          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>
              {" "}
              Plaques Magnétiques :
              <input
                type="checkbox"
                checked={vhl.plaquevelcro}
                onChange={onChangePlaquevelcro}
              />
            </label>
          </div>

          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>
              {" "}
              Chargeur intelligent :
              <input
                type="checkbox"
                checked={vhl.chargeurintelligent}
                onChange={onChangeChargeurintelligent}
              />
            </label>
          </div>
          
          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>
              {" "}
              Balise :
              <input
                type="checkbox"
                checked={vhl.balise}
                onChange={onChangeBalise}
              />
            </label>
          </div>
          
          <div className="relative mt-5">
          <label
            htmlFor="pneusav"
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
          >
            Pneus avant Crossclimate
          </label>
          <input
            type="text"
            name="pneusav"
            id="pneusav"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
            value={vhl.pneusavant}
            onChange={(e) => onChangePneusavant(e)}
          />
          </div>
          <div className="relative mt-5">
          <label
            htmlFor="pneusar"
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
          >
            Pneus arrières Crossclimate
          </label>
          <input
            type="text"
            name="pneusar"
            id="pneusar"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
            value={vhl.pneusarriere}
            onChange={(e) => onChangePneusarriere(e)}
          />
          </div>
          <div className="relative mt-5">
          <label
            htmlFor="taillepneus"
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
          >
            Taille Pneus
          </label>
          <input
            type="text"
            name="taillepneus"
            id="taillepneus"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
            value={vhl.taillepneus}
            onChange={(e) => onChangeTaillepneus(e)}
          />
          </div>
          <div className="form-group">
            <label style={{ fontWeight: "bold" }}>
              {" "}
              AD-Blue :
              <input
                type="checkbox"
                checked={vhl.adblue}
                onChange={onChangeADblue}
              />
            </label>
          </div>
          </div>
            )}
            {(currentUser.service === "FOR"  || currentUser.service === "FI" || currentUser.service === "SMS")  && (
              <div>

          <div className="relative mt-5">
          <label
            htmlFor="taillevhl"
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
          >
            Taille VHL
          </label>
          <input
            type="text"
            name="taillevhl"
            id="taillevhl"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
            value={vhl.taillevhl}
            onChange={(e) => onChangeTailleVHL(e)}
          />
          </div>
         <div className="relative mt-5">
         <label
           htmlFor="poids"
           className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
         >
           Poids VHL
         </label>
         <input
           type="text"
           name="poids"
           id="poids"
           className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
           value={vhl.poidsvhl}
           onChange={(e) => onChangePoidsVHL(e)}
         />
         </div>
         <div className="form-group">
            <label style={{ fontWeight: "bold" }}>
              {" "}
              Pneus neige :
              <input
                type="checkbox"
                checked={vhl.pneusneige}
                onChange={onChangePneusneige}
              />
            </label>
          </div>
       </div>
)}
          <div className="form-group text-center">
  <button
    type="submit"
    className="mt-8 mx-auto rounded-lg bg-blue-900 px-3 py-2 text-base font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
  >
    Valider la demande
  </button>
</div>
        </form>
        </div>
  );
}

export default EditVHL;
