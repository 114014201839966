import * as React from "react";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import axios from "axios";
import { PlusIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/20/solid'
import authHeader from "../auth-header";

const UserList = () => {
  const [users, setUsers] = React.useState([]);
  const [currentUser, setCurrentUser] = React.useState({});

  React.useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/", { headers: authHeader() })
      .then((response) => {
      setUsers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const deleteUser = (id) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
      )
    ) {
      axios
        .delete(`${process.env.REACT_APP_BASE_URL}/api/users/${id}`, { headers: authHeader() })
        .then((res) => {
          console.log(res.data);
          setUsers(users.filter((user) => user._id !== id));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const fabStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
  };

  return (
    currentUser.roles === "Admin" && (
<div className="px-4 sm:px-6 lg:px-8">
<div className="sm:flex sm:items-center">
  <div className="sm:flex-auto">
    <h1 className="text-base font-semibold leading-6 text-gray-900">Utilisateurs</h1>
  </div>
  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
    <button
      type="button"
      onClick={() => (window.location.href = "/user")}
      className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
    >
      Ajouter un utilisateur
    </button>
  </div>
</div>
<div className="mt-8 flow-root">
  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
              Nom
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Service
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              Role
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
              <span className="sr-only">Editer</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {users.map((user) => (
            <tr key={user.username}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                {user.username}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.service}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.roles}</td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <div style={{ display: "flex" }}>
            <>
              <TrashIcon
                onClick={() => deleteUser(user._id)}
                className="w-6 h-6 text-red-500 cursor-pointer"
              />
              <PencilSquareIcon
                onClick={() => (window.location.href = `/edituser/${user._id}`)}
                className="w-6 h-6 text-blue-500 cursor-pointer"
              />
            </>
        </div>
        </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
</div>
</div>
    )
  );
};

export default UserList;
