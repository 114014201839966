import { React, useState, useEffect } from "react";
import axios from "axios";
import authHeader from "../auth-header";
import { Avatar, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

function ToutMateriel() {
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const logOut = () => {
    localStorage.removeItem("user");
  };

  const fabStyle = {
    position: "fixed",
    bottom: "20px",
    right: "20px",
  };

  return (
    <div>
  <div style={{ display: "flex", justifyContent: "center", margin: "15px" }}>
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
    <Link to="/listing-cnp">
        <Avatar
            alt="CNP"
            src="/images/cnp.jpeg"
            sx={{ width: 100, height: 100 }}
        />
         </Link>
        <Typography align="center" fontWeight="bold">CNP</Typography>
    </Box>

    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "15px" }}>
    <Link to="/listing-cof">
      <Avatar
        alt="COF"
        src="/images/cof.jpeg"
        sx={{ width: 100, height: 100 }}
      />
      </Link>
      <Typography align="center" fontWeight="bold">COF / Perben</Typography>
    </Box>

    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "15px" }}>
    <Link to="/listing-divers">
      <Avatar
        alt="COF"
        src="/images/divers.jpeg"
        sx={{ width: 100, height: 100 }}
      />
      </Link>
      <Typography align="center" fontWeight="bold">Divers</Typography>
    </Box>
  </div>

  <div style={{ display: "flex", justifyContent: "center", margin: "15px" }}>
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
    <Link to="/listing-image">
      <Avatar
        alt="COF"
        src="/images/image.jpeg"
        sx={{ width: 100, height: 100 }}
      />
      </Link>
      <Typography align="center" fontWeight="bold">Image</Typography>
    </Box>

    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "15px" }}>
    <Link to="/listing-interpel">
      <Avatar
        alt="COF"
        src="/images/interpel.jpeg"
        sx={{ width: 100, height: 100 }}
      />
      </Link>
      <Typography align="center" fontWeight="bold">Interpel / <br /> Armement</Typography>
    </Box>

    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "15px" }}>
    <Link to="/listing-micro">
      <Avatar
        alt="COF"
        src="/images/micro.jpeg"
        sx={{ width: 100, height: 100 }}
      />
      </Link>
      <Typography align="center" fontWeight="bold">Micro</Typography>
    </Box>
  </div>

  <div style={{ display: "flex", justifyContent: "center", margin: "15px" }}>
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
    <Link to="/listing-vhl">
      <Avatar
        alt="COF"
        src="/images/vhl.jpeg"
        sx={{ width: 100, height: 100 }}
      />
      </Link>
      <Typography align="center" fontWeight="bold">Mobilité</Typography>
    </Box>

    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "15px" }}>
    <Link to="/listing-drone">
      <Avatar
        alt="COF"
        src="/images/drone.jpeg"
        sx={{ width: 100, height: 100 }}
      />
      </Link>
      <Typography align="center" fontWeight="bold">Rens aérien / <br /> Drones</Typography>
    </Box>

    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "15px" }}>
    <Link to="/listing-sic">
      <Avatar
        alt="COF"
        src="/images/sic.jpeg"
        sx={{ width: 100, height: 100 }}
      />
      </Link>
      <Typography align="center" fontWeight="bold">SIC</Typography>
    </Box>
  </div>

  <div style={{ display: "flex", justifyContent: "center", margin: "15px" }}>
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
    <Link to="/listing-track">
      <Avatar
        alt="COF"
        src="/images/track.jpeg"
        sx={{ width: 100, height: 100 }}
      />
      </Link>
      <Typography align="center" fontWeight="bold">Track</Typography>
    </Box>

    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "15px" }}>
    <Link to="/listing-verte">
      <Avatar
        alt="COF"
        src="/images/verte.jpeg"
        sx={{ width: 100, height: 100 }}
      />
      </Link>
      <Typography align="center" fontWeight="bold">Verte</Typography>
    </Box>
  </div>

  {currentUser.roles === "Admin" && (
        <div>
          <Fab
            color="primary"
            aria-label="add"
            size="small"
            href="/newmateriel"
            style={fabStyle}
          >
            <AddIcon />
          </Fab>
        </div>
      )}
</div>



  );
}

export default ToutMateriel;
