import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import authHeader from "../auth-header";

export default function Garage() {
  const [vhlgarage, setVhlGarage] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/vhl/garage", { headers: authHeader() })
      .then((response) => {
        setVhlGarage(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const filteredVehicles = vhlgarage.filter(
    (vhlgarage) => currentUser && vhlgarage.service === currentUser.service
  );

  const groupedVehicles = {};

  filteredVehicles.forEach((vhl) => {
    if (!groupedVehicles[vhl.typedevhl]) {
      groupedVehicles[vhl.typedevhl] = [];
    }
    groupedVehicles[vhl.typedevhl].push(vhl);
  });
  

  return (
    <div>
    <h3 className="text-2xl font-bold text-center mb-6">Véhicules au Garage</h3>
<nav className="h-full overflow-y-auto" aria-label="Directory">
  {Object.keys(groupedVehicles).map((typedevhl) => (
    <div key={typedevhl} className="relative">
      <div className="text-center sticky top-0 z-10 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900">
        <h3>{typedevhl}</h3>
      </div>
      <ul role="list" className="divide-y divide-gray-100">
        {groupedVehicles[typedevhl].map((vhl) => (
          <Link to={`/pagevhl/${vhl._id}`} className="block">
            <li key={vhl._id} className="flex gap-x-4 px-3 py-5">
              <img className="h-12 w-12 flex-none rounded-full bg-gray-50 object-cover" src={vhl.image} alt="" />
              <div className="min-w-0 flex-1">
                <p className="text-base font-semibold leading-6 text-gray-900">{vhl.nomvhl}</p>
                <p className="mt-1 truncate text-sm italic leading-5 text-gray-500">{vhl.dossardorigine}</p>
              </div>
              <ChevronRightIcon className="self-center h-5 w-5 text-gray-400" aria-hidden="true" />
            </li>
          </Link>
        ))}
      </ul>
    </div>
  ))}
</nav>
</div>
  );
}
