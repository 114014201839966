import React, { useEffect, useState } from "react";
import axios from "axios";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { ListItemButton } from "@mui/material";
import authHeader from "../auth-header";

export default function ListHistoPerceptions() {
  const [perceptions, setPerceptions] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/histoperceptionmateriel/", { headers: authHeader() })
      .then((response) => {
        setPerceptions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Tri des éléments par date décroissante
  const sortedPerceptions = [...perceptions].sort((a, b) => new Date(b.date) - new Date(a.date));

  const listStyles = {
    width: "100%",
    maxWidth: "100%",
    bgcolor: "background.paper",
    marginTop: "-25px",
  };

  return (
    <List sx={listStyles}>
      {sortedPerceptions.map((perception, index) => (
        <React.Fragment key={index}>
          <ListItemButton
            alignItems="center"
            component="a"
            href={`/detailhistoperception/${perception._id}`}
          >
            <ListItemText
              primary={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {perception.nomperception}
                    </Typography>
                    <Typography
                      sx={{
                        fontStyle: "italic",
                        fontSize: "0.8rem",
                        color: "text.secondary",
                      }}
                      component="span"
                    >
                      {perception.typeperception}
                    </Typography>
                  </div>
                  <Typography
                    sx={{ fontWeight: "lighter" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {new Date(perception.date).toLocaleDateString()}
                  </Typography>
                </div>
              }
            />
          </ListItemButton>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </List>
  );
}
