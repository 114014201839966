import React, { useEffect, useState } from "react";
import axios from "axios";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { ListItemButton, Chip } from "@mui/material";
import authHeader from "../../auth-header";

export default function ListeImage() {
  const [materiels, setMateriels] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/materiels/", { headers: authHeader() })
      .then((response) => {
        const filteredMateriels = response.data.filter(
          (materiel) => materiel.categorie === "Image"
        );
        setMateriels(filteredMateriels);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const materielsByAlerte = {};

  // Regroupe les éléments par catégorie d'alerte
  materiels.forEach((materiel) => {
    if (!materielsByAlerte[materiel.alerte]) {
      materielsByAlerte[materiel.alerte] = [];
    }
    materielsByAlerte[materiel.alerte].push(materiel);
  });

  // Trie chaque groupe par ordre alphabétique
  for (const alerte in materielsByAlerte) {
    materielsByAlerte[alerte].sort((a, b) => a.nom.localeCompare(b.nom));
  }

  return (
    <List
        sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper",
            marginTop: "-25px",
        }}
    >
        {Object.keys(materielsByAlerte).map((alerte) => (
            <React.Fragment key={alerte}>
                <Divider>
                    <Typography variant="h3" fontWeight="bold">
                        <Chip label={alerte} />
                    </Typography>
                </Divider>
                {materielsByAlerte[alerte].map((materiel) => (
                    <React.Fragment key={materiel._id}>
                        <ListItemButton
                            alignItems="center"
                            component="a"
                            href={`/pagemateriel/${materiel._id}`}
                            key={materiel._id}
                        >
                            <ListItemAvatar>
                                <Avatar alt={materiel.nom} src={materiel.image} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography sx={{ fontWeight: "bold" }}>
                                            {materiel.nom}
                                        </Typography>
                                    </div>
                                }
                            />
                        </ListItemButton>
                        <Divider variant="inset" component="li" />
                    </React.Fragment>
                ))}
            </React.Fragment>
        ))}
    </List>
);
}
