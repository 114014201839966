import { React, useState, useEffect } from "react";
import { Button } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import axios from "axios";
import authHeader from "../auth-header";
import { CalendarDaysIcon } from "@heroicons/react/20/solid";

function HomeMateriel() {
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const logOut = () => {
    localStorage.removeItem("user");
  };

  return (
    <div>
      
        <img className="home-img" src="/images/homemateriel.png" />

        <div className="flex flex-col items-center mt-8">
      <button
        type="button"
        onClick={() => (window.location.href = "/materiel/toutmateriel")}
        className={"mb-4 text-base uppercase inline-flex items-center justify-center rounded-lg bg-indigo-900 opacity-90 border-1 border-indigo-900 px-4 py-1.5 text-white font-medium shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible-outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          Tout le matériel
        </span>
      </button>
      <button
        type="button"
        onClick={() => (window.location.href = "/newperception")}
        className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-green-700 opacity-90 border-1 border-green-900 px-4 py-1.5 text-white font-medium shadow-sm hover.bg-red-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
        Nouvelle perception / Mission
        </span>
      </button>
      <button
        type="button"
        onClick={() => (window.location.href = "/listperceptions")}
        className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-red-700 border-2 border-red-700 px-4 py-1.5 text-white font-medium shadow-sm hover.bg-red-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          Perceptions / Missions en cours
        </span>
      </button>

      <button
        type="button"
        onClick={() => (window.location.href = "/listhistoperceptions")}
        className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white border-2 border-indigo-700 px-4 py-1.5 text-indigo-900 font-medium shadow-sm hover.bg-red-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
        <CalendarDaysIcon className="ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          Historique des perceptions
        </span>
      </button>
      </div>


        
        
        <p></p>
        <div className="flex flex-col items-center mt-10">
    {currentUser.username === undefined ? (
      <button
        type="button"
        onClick={() => (window.location.href = "/login")}
        className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white px-4 py-1.5 text-blue-900 font-medium shadow-sm hover.bg-blue-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          Se connecter
        </span>
      </button>
    ) : (
      <button
        type="button"
        onClick={() => {
          logOut();
          window.location.href = "/login";
        }}        className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white px-4 py-1.5 text-blue-900 font-medium shadow-sm hover.bg-blue-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          Se déconnecter
        </span>
      </button>
    )}
  </div>
      </div>
  );
}

export default HomeMateriel;
