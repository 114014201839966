import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';
import authHeader from "../auth-header";




function DetailTache(props) {
  const [tache, setTache] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  
  const { id } = useParams();

  useEffect(() => {
    if (isLoading) {
      axios
        .get(process.env.REACT_APP_BASE_URL + '/api/planninggarage/' + id, { headers: authHeader() })
        .then(response => {
          setTache({
            ...response.data,
          });
        })
        .catch(error => {
          console.log(error);
        });

        axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then(response => {
        setCurrentUser(response.data);
      })
      .catch(error => {
        console.log(error);
      });

      setisLoading(false);
    }
  }, [id, isLoading]);

  const deleteTache = (id) => {
    axios
        .delete(process.env.REACT_APP_BASE_URL + "/api/planninggarage/" + id, { headers: authHeader() })
        .then((res) => {
          window.location = '/calendriertachesv2';
        })
    setisLoading(true);
  }

  return (
    <div >
        <div className="flex flex-col items-center">
      <img
        className="h-40 w-40 rounded-full object-cover mb-3"
        src={tache.image}
        alt={tache.nomvhl}
      />
      </div>
      <h3 className="text-2xl font-bold text-center mb-2">{tache.nomvhl}</h3>
      <h2 className="text-lg italic text-center">{tache.dossardorigine}</h2>
      <h2 className="text-lg italic text-center">Service : {tache.service}</h2>
      <h2 className="text-lg italic text-center mb-5">Kilométrage VHL : {tache.km}</h2>


        <div className='max-w-lg mx-auto my-auto'>
      <div className="bg-white px-8 py-2 flex flex-row items-center">
  <dt className="text-sm font-medium leading-6 text-gray-900">Type de travail</dt>
  <dd className="ml-auto text-sm leading-6 text-gray-700">
    {tache.typetravail}
  </dd>
</div>
      <div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
  <dt className="text-sm font-medium leading-6 text-gray-900">Priorité</dt>
  <dd className="ml-auto text-sm leading-6 text-gray-700">
    {tache.priorite}
  </dd>
</div>
<div className="bg-white px-8 py-2 flex flex-row items-center">
  <dt className="text-sm font-medium leading-6 text-gray-900">Remarques</dt>
  <dd className="ml-auto text-sm leading-6 text-gray-700">
    {tache.remarques}
  </dd>
</div>
<div className="bg-gray-50 px-8 py-2 flex flex-row items-center">
  <dt className="text-sm font-medium leading-6 text-gray-900">Garagiste</dt>
  <dd className="ml-auto text-sm leading-6 text-gray-700">
    {tache.nomgaragiste}
  </dd>
</div>
<div className="bg-white px-8 py-2 flex flex-row items-center">
  <dt className="text-sm font-medium leading-6 text-gray-900">Date</dt>
  <dd className="ml-auto text-sm leading-6 text-gray-700">
  {new Date(tache.date).toLocaleDateString()}  </dd>
</div>
</div>


{currentUser.roles === "Admin" && (
  <div className="flex flex-col justify-center items-center mt-8">
        <button
      type="button"
      onClick={() => (window.location.href = "/edittache/" + tache._id)}
      className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-green-900 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-800 focus:outline-none"
    >
      <PencilSquareIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
      Editer la tâche
    </button>

    <button
      type="button"
      onClick={() => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer cette tâche ?')) {
          deleteTache(tache._id);
        }
      }}
      className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-red-900 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus:outline-none"
    >
      <TrashIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
      Supprimer la tâche
    </button>
  </div>
)}

    </div>
  );
}

export default DetailTache;
