import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import authHeader from "../auth-header";
import { TrashIcon } from '@heroicons/react/20/solid';

function DetailResa() {
  const [reservation, setReservation] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const { id } = useParams();

  const deleteResa = (id) => {
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/reservations/${id}`, { headers: authHeader() })
      .then((res) => {
        console.log(res.data);
        setIsLoading(true);
        window.location = '/reservations/list';
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isLoading) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/reservations/${id}`, { headers: authHeader() })
        .then((response) => {
          setReservation(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/users/CurrentUser`, { headers: authHeader() })
        .then((response) => {
          setCurrentUser(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      setIsLoading(false);
    }
  }, [id, isLoading]);

  const formatDate = (date) => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    const formattedDate = new Date(date).toLocaleDateString(undefined, options);

    // Ajouter la condition pour afficher l'heure si currentUser.service est égal à "CAB"
    return currentUser.service === "CAB" ? formattedDate : formattedDate.split(' ')[0];
  };

  return (
    <div>
      <div className="flex flex-col items-center">
        <img
          className="h-40 w-40 rounded-full object-cover mb-3"
          src={reservation.imagevhl}
          alt={reservation.nomvhl}
        />
      </div>
      <h3 className="text-2xl font-bold text-center mb-2">{reservation.nomvhl}</h3>
      <h2 className="text-lg italic text-center mb-6">{reservation.dossardorigine}</h2>

      <DetailRow label="Type de réservation" value={reservation.typereservation} />
      <DetailRow label="Date de début" value={formatDate(reservation.datedebut)} />
      <DetailRow label="Date de fin" value={formatDate(reservation.datefin)} />
      <DetailRow label="Nom" value={reservation.nom} />

      {currentUser.roles === "Admin" || currentUser.username === reservation.nom ? (
        <div className="flex justify-center items-center mt-8">
          <button
            type="button"
            onClick={() => {
              if (window.confirm('Êtes-vous sûr de vouloir supprimer cette réservation ?')) {
                deleteResa(reservation._id);
              }
            }}
            className="mb-4 inline-flex items-center gap-x-1.5 rounded-md bg-red-900 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-800 focus-visible:outline-none"
          >
            <TrashIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
            Supprimer la réservation
          </button>
        </div>
      ) : null}
    </div>
  );
}

const DetailRow = ({ label, value }) => (
  <div className="bg-white px-8 py-2 flex flex-row items-center">
    <dt className="text-sm font-medium leading-6 text-gray-900">{label}</dt>
    <dd className="ml-auto text-sm leading-6 text-gray-700">{value}</dd>
  </div>
);

export default DetailResa;
