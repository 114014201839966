import { React, useState, useEffect } from "react";
import { CalendarDaysIcon, ChevronDownIcon, NewspaperIcon, WrenchScrewdriverIcon } from '@heroicons/react/20/solid'
import axios from "axios";
import authHeader from "./auth-header";

import { initializeApp } from "firebase/app";
import { getDatabase, ref, runTransaction } from "firebase/database";
import addNotification from 'react-push-notifiy';


function Home() {
  const [currentUser, setCurrentUser] = useState({});
  const [dmdGarage, setDmdGarage] = useState([]);
  const [rdvGarage, setRdvGarage] = useState([]);
  const [vhl, setVhl] = useState([]);


  // Firebase RealTime Database pour l'ouverture de l'armoire FOR
const firebaseConfig = {
  apiKey: "AIzaSyBTxx5fm13x85Gege_yYgdjFM5GgiogREo",
  authDomain: "armoire-33c3b.firebaseapp.com",
  databaseURL: 'https://armoire-33c3b-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: "armoire-33c3b",
  storageBucket: "armoire-33c3b.appspot.com",
  messagingSenderId: "869239049975",
  appId: "1:869239049975:web:715bde33ef2f9ba2e7cdf2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);


// Lorsque le bouton est pressé
function handleButtonClick() {
  const histoarmoire = {
    
    user: currentUser.username,
    service: currentUser.service,
    date: new Date(),
  };

  axios
        .post(
          process.env.REACT_APP_BASE_URL + "/api/histoarmoire/add/",
          histoarmoire,
          { headers: authHeader() }
        )
        .catch((error) => {
          console.log(error);
        });

  const solenoidRef = ref(database, 'Armoire/solenoid');

  // Utilisation d'une transaction pour mettre à jour la valeur
  runTransaction(solenoidRef, (currentData) => {
    return currentData === true ? false : true;
  })
    .then((result) => {
      console.log('Mise à jour réussie ! Nouvelle valeur de solenoid :', result.snapshot.val());
    })
    .catch((error) => {
      console.error('Erreur lors de la mise à jour :', error);
    });
}

  const serviceImages = {
    FOR: "/images/home.jpeg",
    EMOPS: "/images/home2.jpeg",
    FSP: "/images/home3.jpeg",
    FI: "/images/home4.jpg",
    SMS: "/images/home5.jpg",
    CNFIS: "/images/home7.jpg",
    GARAGE: "/images/home6.jpg",
    DT: "/images/home8.jpg",
    "R&D": "/images/home8.jpg",
    AMS: "/images/home9.jpeg",
    CYNO: "/images/home10.jpeg",
    CAB: "/images/home11.jpeg",
    MAG: "/images/home11.jpeg",
    FA: "/images/home11.jpeg",
    EMSF: "/images/home11.jpeg",
  };

  const imagePath = serviceImages[currentUser.service];

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "/api/users/CurrentUser", { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
      axios.get(process.env.REACT_APP_BASE_URL + '/api/dmdintervgarage/', { headers: authHeader() })
      .then(response => {
        setDmdGarage(response.data);
      })
      .catch(error => {
        console.log(error);
      });
      axios
      .get(process.env.REACT_APP_BASE_URL + "/api/rdvgarage/", { headers: authHeader() })
      .then((response) => {
        setRdvGarage(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function countMissingUsernames() {
    if (currentUser.roles === 'Admin') {
      const filteredDmdGarage = dmdGarage.filter(vehicle => {
        return vehicle.service === currentUser.service && !vehicle.idusers.includes(currentUser.username);
      });
  
      return filteredDmdGarage.length;
    } else {
      return 0;
    }
  }

  function countMissingUsernamesGarage() {
      const filteredDmdGarage = dmdGarage.filter(vehicle => {
        return !vehicle.idusers.includes(currentUser.username);
      });
  
      return filteredDmdGarage.length;
  }

  const Badge = () => (
    <span className="absolute top-0 right-0 -mt-3 -mr-3 inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-700">
      {countMissingUsernames()} non lus
    </span>
  );

  const BadgeGarage = () => (
    <span className="absolute top-0 right-0 -mt-3 -mr-3 inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-700">
      {countMissingUsernamesGarage()} non lus
    </span>
  );

  function countMissingUsernamesRDV() {
    if (currentUser.roles === 'Admin') {
      const filteredRDVGarage = rdvGarage.filter(vehicle => {
        return vehicle.service === currentUser.service && !vehicle.idusers.includes(currentUser.username);
      });
  
      return filteredRDVGarage.length;
    } else {
      return 0;
    }
  }

  const BadgeRDV = () => (
    <span className="absolute top-0 right-0 -mt-3 -mr-3 inline-flex items-center gap-x-1.5 rounded-md bg-yellow-100 px-1.5 py-0.5 text-xs font-medium text-yellow-700">
      {countMissingUsernamesRDV()} non lus
    </span>
  );

  const logOut = () => {
    localStorage.removeItem("user");
  };

  return (
    <div >
  <div className="flex flex-col items-center mb-6">
    {imagePath && (
      <img className="home-img" src={imagePath} alt="Home" />
    )}
  </div>

  {currentUser.service === "FOR" ? (
    <div className="flex flex-col items-center">
      <button
        type="button"
        onClick={handleButtonClick}
        className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white opacity-90 border-2 border-indigo-900 px-4 py-1.5 text-indigo-900 font-medium shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          Ouvrir l'armoire clés
        </span>
      </button>
    </div>
  ) : (
    ""
  )}

  <div className="relative mb-4">
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t border-gray-300" />
    </div>
    <div className="relative flex justify-center">
      <span className="bg-white px-2 text-gray-500">
        <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
      </span>
    </div>
  </div>

<div className="flex flex-col items-center">
  <button
    type="button"
    onClick={() => (window.location.href = "/listvhl")}
    className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-indigo-900 opacity-90 border-1 border-indigo-900 px-4 py-1.5 text-white font-medium shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"}
    style={{ width: "300px" }}
  >
    <span className="flex items-center">
      Liste des véhicules
    </span>
  </button>
  </div>

  {currentUser.service !== "GARAGE" && (
    <>
    <div className="flex flex-col items-center">
      <button
        type="button"
        onClick={() => (window.location.href = "/disponibles")}
        className={"mb-4 text-base uppercase inline-flex items-center justify-center rounded-lg bg-green-700 opacity-90 border-1 border-green-900 px-4 py-1.5 text-white font-medium shadow-sm hover:bg-green-500 hover:text-white focus-visible:outline focus-visible-outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          Véhicules disponibles
        </span>
      </button>
      <button
        type="button"
        onClick={() => (window.location.href = "/mission")}
        className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-red-700 opacity-90 border-1 border-red-900 px-4 py-1.5 text-white font-medium shadow-sm hover.bg-red-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          Véhicules en mission
        </span>
      </button>
      <button
        type="button"
        onClick={() => (window.location.href = "/dmdintervgarage")}
        className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white border-2 border-red-700 px-4 py-1.5 text-red-700 font-medium shadow-sm hover.bg-red-500 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          <WrenchScrewdriverIcon className="ml-1 mr-3 h-5 w-5" aria-hidden="true" />
          Demande d'interv. Garage
        </span>
      </button>
      </div>
      
      {currentUser.service === "FOR" ? (
        <div className="flex flex-col items-center">
        <button
          type="button"
          onClick={() => (window.location.href = "/reservations")}
          className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white border-2 border-blue-900 px-4 py-1.5 text-blue-900 font-medium shadow-sm hover.bg-blue-500 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"}
          style={{ width: "300px" }}
        >
          <span className="flex items-center">
            <CalendarDaysIcon className="ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            Réservation VHL liaison
          </span>
        </button>
        </div>
      ) : (
        <div className="flex flex-col items-center">
        <button
          type="button"
          onClick={() => (window.location.href = "/reservations")}
          className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white border-2 border-blue-900 px-4 py-1.5 text-blue-900 font-medium shadow-sm hover.bg-blue-500 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"}
          style={{ width: "300px" }}
        >
          <span className="flex items-center">
            <CalendarDaysIcon className="ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            Réservation VHL
          </span>
        </button>
        </div>
      )}
      
      <div className="flex flex-col items-center">
      <button
        type="button"
        onClick={() => (window.location.href = "/divers")}
        className={"mb-4 text-sm uppercase relative inline-flex items-center justify-center rounded-lg bg-white border-2 border-blue-900 px-4 py-1.5 text-blue-900 font-medium shadow-sm hover.bg-blue-500 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          <Badge />
          Divers
        </span>
      </button>
      </div>
    </>
  )}
  

  {currentUser.service === "GARAGE" ? (
    <div className="flex flex-col items-center">
    <button
      type="button"
      onClick={() => (window.location.href = "/calendriertachesv2")}
      className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white border-2 border-blue-900 px-4 py-1.5 text-blue-900 font-medium shadow-sm hover.bg-blue-500 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"}
      style={{ width: "300px" }}
    >
      <span className="flex items-center">
        Planning
      </span>
    </button>

    <button
      type="button"
      onClick={() => (window.location.href = "/rdvvhl")}
      className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white border-2 border-green-600 px-4 py-1.5 text-green-600 font-medium shadow-sm hover.bg-blue-500 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"}
      style={{ width: "300px" }}
    >
      <span className="flex items-center">
        RDV VHL
      </span>
    </button>

    <div className="flex flex-col items-center">
      <button
        type="button"
        onClick={() => (window.location.href = "/listdmdgarageall")}
        className={"mb-4 text-sm uppercase relative inline-flex items-center justify-center rounded-lg bg-white border-2 border-red-600 px-4 py-1.5 text-red-600 font-medium shadow-sm hover.bg-blue-500 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          <BadgeGarage />
          Demandes d'intervention Garage
        </span>
      </button>
      </div>

    <button
      type="button"
      onClick={() => (window.location.href = "/vhlsgarage")}
      className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white border-2 border-yellow-900 px-4 py-1.5 text-yellow-900 font-medium shadow-sm hover.bg-blue-500 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"}
      style={{ width: "300px" }}
    >
      <span className="flex items-center">
        Véhicules au garage
      </span>
    </button>
    </div>
  ) : (
    ""
  )}
<div className="flex flex-col items-center">
  <button
    type="button"
    onClick={() => (window.location.href = "/tutos")}
    className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white border-2 border-blue-900 px-4 py-1.5 text-blue-900 font-medium shadow-sm hover.bg-blue-500 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"}
    style={{ width: "300px" }}
  >
    <span className="flex items-center">
      <NewspaperIcon className="ml-1 mr-3 h-5 w-5" aria-hidden="true" />
      Fiches reflexes
    </span>
  </button>
  </div>

  {currentUser.roles === "Admin" && currentUser.service !== "GARAGE" ? (
    <div className="flex flex-col items-center">
  <button
      type="button"
      onClick={() => (window.location.href = "/rdvvhlservice")}
      className={"mb-4 text-sm uppercase relative inline-flex items-center justify-center rounded-lg bg-white border-2 border-green-500 px-4 py-1.5 text-green-600 font-medium shadow-sm hover.bg-blue-500 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-400"}
      style={{ width: "300px" }}
    >
      <span className="flex items-center">
      <BadgeRDV />
        RDV VHL
      </span>
    </button>
    </div>
    ) : (
    ""
  )}

  {currentUser.roles === "Admin" ? (
    <div className="flex flex-col items-center">
    <button
      type="button"
      onClick={() => (window.location.href = "/pageadmin")}
      className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white border-2 border-red-700 px-4 py-1.5 text-red-700 font-medium shadow-sm hover.bg-red-500 hover:text-black focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"}
      style={{ width: "300px" }}
    >
      <span className="flex items-center">
        ADMIN
      </span>
    </button>
    </div>
  ) : (
    ""
  )}
  <p></p>
  <div className="flex flex-col items-center mt-10">
    {currentUser.username === undefined ? (
      <button
        type="button"
        onClick={() => (window.location.href = "/login")}
        className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white px-4 py-1.5 text-blue-900 font-medium shadow-sm hover.bg-blue-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          Se connecter
        </span>
      </button>
    ) : (
      <button
        type="button"
        onClick={() => {
          logOut();
          window.location.href = "/login";
        }}        className={"mb-4 text-sm uppercase inline-flex items-center justify-center rounded-lg bg-white px-4 py-1.5 text-blue-900 font-medium shadow-sm hover.bg-blue-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"}
        style={{ width: "300px" }}
      >
        <span className="flex items-center">
          Se déconnecter
        </span>
      </button>
    )}
  </div>
</div>

  );
}

export default Home;
