import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import KeyIcon from '@mui/icons-material/Key';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import MicIcon from '@mui/icons-material/Mic';
import ForestIcon from '@mui/icons-material/Forest';
import MasksIcon from '@mui/icons-material/Masks';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ConstructionIcon from '@mui/icons-material/Construction';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useParams } from 'react-router-dom';
import { groupBy } from 'lodash';
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListItemAvatar,
  Avatar,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
} from '@mui/material';
import authHeader from '../../auth-header';

const EditPerception = () => {
  const [typePerception, setTypePerception] = useState('');
  const [nomPerception, setNomPerception] = useState('');
  const [remarques, setRemarques] = useState('');
  const [materiels, setMateriels] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openCOFDialog, setOpenCOFDialog] = useState(false);
  const [openDroneDialog, setOpenDroneDialog] = useState(false);
  const [openMicroDialog, setOpenMicroDialog] = useState(false);
  const [openVerteDialog, setOpenVerteDialog] = useState(false);
  const [openCNPDialog, setOpenCNPDialog] = useState(false);
  const [openInterpelDialog, setOpenInterpelDialog] = useState(false);
  const [openMobiliteDialog, setOpenMobiliteDialog] = useState(false);
  const [openDiversDialog, setOpenDiversDialog] = useState(false);
  const [openSICDialog, setOpenSICDialog] = useState(false);
  const [openTrackDialog, setOpenTrackDialog] = useState(false);
  const [selectedImageMateriels, setSelectedImageMateriels] = useState([]);
  const [selectedDroneMateriels, setSelectedDroneMateriels] = useState([]);
  const [selectedMicroMateriels, setSelectedMicroMateriels] = useState([]);
  const [selectedVerteMateriels, setSelectedVerteMateriels] = useState([]);
  const [selectedCNPMateriels, setSelectedCNPMateriels] = useState([]);
  const [selectedInterpelMateriels, setSelectedInterpelMateriels] = useState([]);
  const [selectedMobiliteMateriels, setSelectedMobiliteMateriels] = useState([]);
  const [selectedDiversMateriels, setSelectedDiversMateriels] = useState([]);
  const [selectedSICMateriels, setSelectedSICMateriels] = useState([]);
  const [selectedTrackMateriels, setSelectedTrackMateriels] = useState([]);
  const [selectedCOFMateriels, setSelectedCOFMateriels] = useState([]);

  const { id } = useParams();

  const label = { inputProps: { 'aria-label': 'Switch' } };

  const handleChangeTypePerception = (e) => {
    setTypePerception(e.target.value);
  };

  const handleChangeNomPerception = (e) => {
    setNomPerception(e.target.value);
  };

  const handleChangeRemarques = (e) => {
    setRemarques(e.target.value);
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + '/api/materiels/', { headers: authHeader() })
      .then((response) => {
        setMateriels(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + '/api/perceptionmateriel/' + id, { headers: authHeader() })
      .then((response) => {
        setNomPerception(response.data.nomperception);
        setTypePerception(response.data.typeperception);
        setRemarques(response.data.remarques);
        setSelectedImageMateriels(response.data.image);
        setSelectedCNPMateriels(response.data.cnp);
        setSelectedCOFMateriels(response.data.cof);
        setSelectedDiversMateriels(response.data.divers);
        setSelectedDroneMateriels(response.data.drone);
        setSelectedInterpelMateriels(response.data.interpel);
        setSelectedMicroMateriels(response.data.micro);
        setSelectedMobiliteMateriels(response.data.mobilite);
        setSelectedSICMateriels(response.data.sic);
        setSelectedTrackMateriels(response.data.track);
        setSelectedVerteMateriels(response.data.verte);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(process.env.REACT_APP_BASE_URL + '/api/users/CurrentUser', { headers: authHeader() })
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (nomPerception && typePerception) {
      const imageMateriels = selectedImageMateriels.map((materiel) => ({
        materiel: {
          _id: materiel.materiel._id,
          nom: materiel.materiel.nom,
          image: materiel.materiel.image,
          nbperception: materiel.materiel.nbperception,
          imageperception: materiel.materiel.imageperception,
        },
      }));
  
      const droneMateriels = selectedDroneMateriels.map((materiel) => ({
        materiel: {
          _id: materiel.materiel._id,
          nom: materiel.materiel.nom,
          image: materiel.materiel.image,
          nbperception: materiel.materiel.nbperception,
          imageperception: materiel.materiel.imageperception,
        },
      }));
  
      const microMateriels = selectedMicroMateriels.map((materiel) => ({
        materiel: {
          _id: materiel.materiel._id,
          nom: materiel.materiel.nom,
          image: materiel.materiel.image,
          nbperception: materiel.materiel.nbperception,
          imageperception: materiel.materiel.imageperception,
        },
      }));

      const verteMateriels = selectedVerteMateriels.map((materiel) => ({
        materiel: {
          _id: materiel.materiel._id,
          nom: materiel.materiel.nom,
          image: materiel.materiel.image,
          nbperception: materiel.materiel.nbperception,
          imageperception: materiel.materiel.imageperception,
        },
      }));
      const CnpMateriels = selectedCNPMateriels.map((materiel) => ({
        materiel: {
          _id: materiel.materiel._id,
          nom: materiel.materiel.nom,
          image: materiel.materiel.image,
          nbperception: materiel.materiel.nbperception,
          imageperception: materiel.materiel.imageperception,
        },
      }));
      const interpelMateriels = selectedInterpelMateriels.map((materiel) => ({
        materiel: {
          _id: materiel.materiel._id,
          nom: materiel.materiel.nom,
          image: materiel.materiel.image,
          nbperception: materiel.materiel.nbperception,
          imageperception: materiel.materiel.imageperception,
        },
      }));
      const mobiliteMateriels = selectedMobiliteMateriels.map((materiel) => ({
        materiel: {
          _id: materiel.materiel._id,
          nom: materiel.materiel.nom,
          image: materiel.materiel.image,
          nbperception: materiel.materiel.nbperception,
          imageperception: materiel.materiel.imageperception,
        },
      }));
      const diversMateriels = selectedDiversMateriels.map((materiel) => ({
        materiel: {
          _id: materiel.materiel._id,
          nom: materiel.materiel.nom,
          image: materiel.materiel.image,
          nbperception: materiel.materiel.nbperception,
          imageperception: materiel.materiel.imageperception,
        },
      }));
      const sicMateriels = selectedSICMateriels.map((materiel) => ({
        materiel: {
          _id: materiel.materiel._id,
          nom: materiel.materiel.nom,
          image: materiel.materiel.image,
          nbperception: materiel.materiel.nbperception,
          imageperception: materiel.materiel.imageperception,
        },
      }));
      const trackMateriels = selectedTrackMateriels.map((materiel) => ({
        materiel: {
          _id: materiel.materiel._id,
          nom: materiel.materiel.nom,
          image: materiel.materiel.image,
          nbperception: materiel.materiel.nbperception,
          imageperception: materiel.materiel.imageperception,
        },
      }));
      const cofMateriels = selectedCOFMateriels.map((materiel) => ({
        materiel: {
          _id: materiel.materiel._id,
          nom: materiel.materiel.nom,
          image: materiel.materiel.image,
          nbperception: materiel.materiel.nbperception,
          imageperception: materiel.imageperception,
        },
      }));
  
      const perceptionMateriel = {
        nomperception: nomPerception,
        typeperception: typePerception,
        remarques,
        date: new Date(),
        image: imageMateriels,
        drone: droneMateriels,
        micro: microMateriels,
        verte: verteMateriels,
        cnp: CnpMateriels,
        interpel: interpelMateriels,
        mobilite: mobiliteMateriels,
        divers: diversMateriels,
        sic: sicMateriels,
        track: trackMateriels,
        cof: cofMateriels
      };

  
      axios
        .post(process.env.REACT_APP_BASE_URL + '/api/perceptionmateriel/update/' + id, perceptionMateriel, {
          headers: authHeader(),
        })
        .then((res) => {
          window.location = '/listperceptions';
          console.log(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
  
      
    } else {
      console.log('Veuillez remplir tous les champs obligatoires');
    }
  };
  

  const handleOpenImageDialog = () => {
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };

  const handleOpenCOFDialog = () => {
    setOpenCOFDialog(true);
  };

  const handleCloseCOFDialog = () => {
    setOpenCOFDialog(false);
  };

  const handleOpenDroneDialog = () => {
    setOpenDroneDialog(true);
  };

  const handleCloseDroneDialog = () => {
    setOpenDroneDialog(false);
  };

  const handleOpenMicroDialog = () => {
    setOpenMicroDialog(true);
  };

  const handleCloseMicroDialog = () => {
    setOpenMicroDialog(false);
  };

  const handleOpenVerteDialog = () => {
    setOpenVerteDialog(true);
  };

  const handleCloseVerteDialog = () => {
    setOpenVerteDialog(false);
  };

  const handleOpenCNPDialog = () => {
    setOpenCNPDialog(true);
  };

  const handleCloseCNPDialog = () => {
    setOpenCNPDialog(false);
  };

  const handleOpenInterpelDialog = () => {
    setOpenInterpelDialog(true);
  };

  const handleCloseInterpelDialog = () => {
    setOpenInterpelDialog(false);
  };

  const handleOpenMobiliteDialog = () => {
    setOpenMobiliteDialog(true);
  };

  const handleCloseMobiliteDialog = () => {
    setOpenMobiliteDialog(false);
  };

  const handleOpenDiversDialog = () => {
    setOpenDiversDialog(true);
  };

  const handleCloseDiversDialog = () => {
    setOpenDiversDialog(false);
  };

  const handleOpenSICDialog = () => {
    setOpenSICDialog(true);
  };

  const handleCloseSICDialog = () => {
    setOpenSICDialog(false);
  };

  const handleOpenTrackDialog = () => {
    setOpenTrackDialog(true);
  };

  const handleCloseTrackDialog = () => {
    setOpenTrackDialog(false);
  };

  const handleMaterielSelection = (selectedMaterielId, categorie) => {
    const selectedMateriel = materiels.find((materiel) => materiel._id === selectedMaterielId);
    let updateSelectedMateriels;
  
    switch (categorie) {
      case 'Image':
        updateSelectedMateriels = setSelectedImageMateriels;
        break;
      case 'COF / Perben':
        updateSelectedMateriels = setSelectedCOFMateriels;
        break;
      case 'Rens aérien / Drones':
        updateSelectedMateriels = setSelectedDroneMateriels;
        break;
      case 'Micro':
        updateSelectedMateriels = setSelectedMicroMateriels;
        break;
      case 'Verte':
        updateSelectedMateriels = setSelectedVerteMateriels;
        break;
      case 'CNP':
        updateSelectedMateriels = setSelectedCNPMateriels;
        break;      
      case 'Interpel / Armement':
        updateSelectedMateriels = setSelectedInterpelMateriels;
        break; 
      case 'Mobilité':
        updateSelectedMateriels = setSelectedMobiliteMateriels;
        break;      
      case 'Divers':
        updateSelectedMateriels = setSelectedDiversMateriels;
        break;
      case 'SIC':
        updateSelectedMateriels = setSelectedSICMateriels;
        break;      
      case 'Track':
        updateSelectedMateriels = setSelectedTrackMateriels;
        break;   
      default:
        return;
    }
  
    updateSelectedMateriels((prevSelectedMateriels) => {
      const updatedSelectedMateriels = [...prevSelectedMateriels];
      const existingMateriel = updatedSelectedMateriels.find(
        (materiel) => materiel.materiel._id === selectedMaterielId
      );
  
      if (existingMateriel) {
        // Matériel déjà sélectionné, le désélectionner en le retirant du tableau
        return updatedSelectedMateriels.filter((materiel) => materiel.materiel._id !== selectedMaterielId);
      } else if (selectedMateriel) {
        // Matériel non sélectionné, l'ajouter au tableau
        selectedMateriel.nbperception = selectedMateriel.nbperception || 0;
        updatedSelectedMateriels.push({ materiel: selectedMateriel });
      }
  
      return updatedSelectedMateriels;
    });
  };
   
  
  const handleNbPerceptionChange = (event, materielId, categorie) => {
    const value = parseInt(event.target.value);
    let updateSelectedMateriels;
  
    switch (categorie) {
      case 'Image':
        updateSelectedMateriels = setSelectedImageMateriels;
        break;
      case 'COF / Perben':
        updateSelectedMateriels = setSelectedCOFMateriels;
        break;
      case 'Rens aérien / Drones':
        updateSelectedMateriels = setSelectedDroneMateriels;
        break;
      case 'Micro':
        updateSelectedMateriels = setSelectedMicroMateriels;
        break;
      case 'Verte':
        updateSelectedMateriels = setSelectedVerteMateriels;
        break;
      case 'CNP':
        updateSelectedMateriels = setSelectedCNPMateriels;
        break;
      case 'Interpel / Armement':
        updateSelectedMateriels = setSelectedInterpelMateriels;
        break;
      case 'Mobilité':
        updateSelectedMateriels = setSelectedMobiliteMateriels;
        break;
      case 'Divers':
        updateSelectedMateriels = setSelectedDiversMateriels;
        break;
      case 'SIC':
        updateSelectedMateriels = setSelectedSICMateriels;
        break;
      case 'Track':
        updateSelectedMateriels = setSelectedTrackMateriels;
        break;
      default:
        return;
    }
  
    updateSelectedMateriels((prevSelectedMateriels) => {
        return prevSelectedMateriels.map((selectedMateriel) => {
            if (selectedMateriel && selectedMateriel.materiel && selectedMateriel.materiel._id === materielId) {
              return {
                ...selectedMateriel,
                materiel: {
                  ...selectedMateriel.materiel,
                  nbperception: value,
                },
              };
            }
            return selectedMateriel;
      });
    });
  };
  


  return (
    <div className="centered-page">
      <h2 style={{ marginBottom: '20px', fontWeight: 'bold' }}>Modifier la perception :</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          id="outlined-password-input"
          label="Nom de la perception"
          type="text"
          autoComplete="current-password"
          size="small"
          required
          value={nomPerception}
          onChange={handleChangeNomPerception}
          className="field-margin"
          sx={{ marginBottom: '10px', width: '95%' }}
        />

        <FormControl fullWidth className="field-margin">
          <InputLabel id="demo-simple-select-label">Type de perception</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={typePerception}
            label="typeperception"
            onChange={handleChangeTypePerception}
            sx={{ marginBottom: '10px', width: '95%' }}
          >
            <MenuItem value="Mission">Mission</MenuItem>
            <MenuItem value="Exercice">Exercice</MenuItem>
            <MenuItem value="Réparation">Réparation</MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="outlined-password-input"
          label="Remarques"
          type="text"
          autoComplete="current-password"
          size="small"
          value={remarques}
          multiline
          rows={4}
          onChange={handleChangeRemarques}
          className="field-margin"
          sx={{ marginBottom: '10px', width: '95%' }}
        />
        <div>
          <Button variant="text" startIcon={<CameraAltIcon />} onClick={handleOpenImageDialog}>
            Matériel Image
          </Button>
          <Dialog onClose={handleCloseImageDialog} open={openImageDialog}>
            <DialogTitle>Matériel Image</DialogTitle>
            <DialogContent>
            <List>
  {Object.entries(
    groupBy(
      materiels
        .filter((materiel) => materiel.categorie === 'Image')
        .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? '')),
      'imageperception'
    )
  )
    .sort(([a], [b]) => b.localeCompare(a)) // Trie les groupes par imageperception
    .map(([imageperception, groupe]) => (
      <React.Fragment key={imageperception}>
        {groupe
          .sort((a, b) => (a.nom ?? '').localeCompare(b.nom ?? '')) // Trie les éléments du groupe par nom
          .map((materiel) => {
            const selectedMateriel = selectedImageMateriels.find(
              (selectedMateriel) => selectedMateriel.materiel._id === materiel._id
            );
            const isMaterielSelected = Boolean(selectedMateriel);
            const nbPerceptionValue = isMaterielSelected && selectedMateriel && selectedMateriel.materiel && selectedMateriel.materiel.nbperception ? selectedMateriel.materiel.nbperception : 1;

            return (
              <ListItem disableGutters key={materiel._id}>
                <ListItemAvatar>
                  <Avatar src={materiel.imageperception} />
                </ListItemAvatar>
                <ListItemText primary={materiel.nom} />
                <Checkbox
                  checked={isMaterielSelected}
                  onChange={() => handleMaterielSelection(materiel._id, 'Image')}
                />
                {isMaterielSelected && (
                  <TextField
                    id={`nbPerception_${materiel._id}`}
                    label="Nombre en perception"
                    type="number"
                    value={nbPerceptionValue}
                    onChange={(e) => handleNbPerceptionChange(e, materiel._id, 'Image')}
                    sx={{ marginLeft: '10px', width: 40 }}
                  />
                )}
              </ListItem>
            );
          })}
      </React.Fragment>
    ))}
</List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseImageDialog}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Button variant="text" startIcon={<KeyIcon />} onClick={handleOpenCOFDialog}>
            Matériel COF / Perben
          </Button>
          <Dialog onClose={handleCloseCOFDialog} open={openCOFDialog}>
            <DialogTitle>Matériel COF / Perben</DialogTitle>
            <DialogContent>
              <List>
                {materiels
                  .filter((materiel) => materiel.categorie === 'COF / Perben')
                  .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
                  .map((materiel) => {
                    const selectedMateriel = selectedCOFMateriels.find(
                      (selectedMateriel) => selectedMateriel.materiel._id === materiel._id
                    );
                    const isMaterielSelected = Boolean(selectedMateriel);
                    const nbPerceptionValue = isMaterielSelected && selectedMateriel && selectedMateriel.materiel && selectedMateriel.materiel.nbperception ? selectedMateriel.materiel.nbperception : 1;

                    return (
                      <ListItem disableGutters key={materiel._id}>
                        <ListItemAvatar>
                          <Avatar src={materiel.imageperception} />
                        </ListItemAvatar>
                        <ListItemText primary={materiel.nom} />
                        <Checkbox
                          checked={isMaterielSelected}
                          onChange={() => handleMaterielSelection(materiel._id, 'COF / Perben')}
                        />
                        {isMaterielSelected && (
                          <TextField
                            id={`nbPerception_${materiel._id}`}
                            label="Nombre en perception"
                            type="number"
                            value={nbPerceptionValue}
                            onChange={(e) => handleNbPerceptionChange(e, materiel.materiel._id, 'COF / Perben')}
                            sx={{ marginLeft: '10px', width: 40 }}
                          />
                        )}
                      </ListItem>
                    );
                  })}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCOFDialog}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Button variant="text" startIcon={<AirplanemodeActiveIcon />} onClick={handleOpenDroneDialog}>
            Matériel Rens aérien / Drones
          </Button>
          <Dialog onClose={handleCloseDroneDialog} open={openDroneDialog}>
            <DialogTitle>Matériel Rens aérien / Drones</DialogTitle>
            <DialogContent>
              <List>
                {materiels
                  .filter((materiel) => materiel.categorie === 'Rens aérien / Drones')
                  .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
                  .map((materiel) => {
                    const selectedMateriel = selectedDroneMateriels.find(
                      (selectedMateriel) => selectedMateriel.materiel._id === materiel._id
                    );
                    const isMaterielSelected = Boolean(selectedMateriel);
                    const nbPerceptionValue = isMaterielSelected && selectedMateriel && selectedMateriel.materiel && selectedMateriel.materiel.nbperception ? selectedMateriel.materiel.nbperception : 1;

                    return (
                      <ListItem disableGutters key={materiel._id}>
                        <ListItemAvatar>
                          <Avatar src={materiel.imageperception} />
                        </ListItemAvatar>
                        <ListItemText primary={materiel.nom} />
                        <Checkbox
                          checked={isMaterielSelected}
                          onChange={() => handleMaterielSelection(materiel._id, 'Rens aérien / Drones')}
                        />
                        {isMaterielSelected && (
                          <TextField
                            id={`nbPerception_${materiel._id}`}
                            label="Nombre en perception"
                            type="number"
                            value={nbPerceptionValue}
                            onChange={(e) => handleNbPerceptionChange(e, materiel.materiel._id, 'Rens aérien / Drones')}
                            sx={{ marginLeft: '10px', width: 40 }}
                          />
                        )}
                      </ListItem>
                    );
                  })}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDroneDialog}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Button variant="text" startIcon={<MicIcon />} onClick={handleOpenMicroDialog}>
            Matériel Micro
          </Button>
          <Dialog onClose={handleCloseMicroDialog} open={openMicroDialog}>
            <DialogTitle>Matériel Micro</DialogTitle>
            <DialogContent>
              <List>
                {materiels
                  .filter((materiel) => materiel.categorie === 'Micro')
                  .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
                  .map((materiel) => {
                    const selectedMateriel = selectedMicroMateriels.find(
                      (selectedMateriel) => selectedMateriel.materiel._id === materiel._id
                    );
                    const isMaterielSelected = Boolean(selectedMateriel);
                    const nbPerceptionValue = isMaterielSelected && selectedMateriel && selectedMateriel.materiel && selectedMateriel.materiel.nbperception ? selectedMateriel.materiel.nbperception : 1;

                    return (
                      <ListItem disableGutters key={materiel._id}>
                        <ListItemAvatar>
                          <Avatar src={materiel.imageperception} />
                        </ListItemAvatar>
                        <ListItemText primary={materiel.nom} />
                        <Checkbox
                          checked={isMaterielSelected}
                          onChange={() => handleMaterielSelection(materiel._id, 'Micro')}
                        />
                        {isMaterielSelected && (
                          <TextField
                            id={`nbPerception_${materiel._id}`}
                            label="Nombre en perception"
                            type="number"
                            value={nbPerceptionValue}
                            onChange={(e) => handleNbPerceptionChange(e, materiel.materiel._id, 'Micro')}
                            sx={{ marginLeft: '10px', width: 40 }}
                          />
                        )}
                      </ListItem>
                    );
                  })}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMicroDialog}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Button variant="text" startIcon={<ForestIcon />} onClick={handleOpenVerteDialog}>
            Matériel Verte
          </Button>
          <Dialog onClose={handleCloseVerteDialog} open={openVerteDialog}>
            <DialogTitle>Matériel Verte</DialogTitle>
            <DialogContent>
              <List>
                {materiels
                  .filter((materiel) => materiel.categorie === 'Verte')
                  .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
                  .map((materiel) => {
                    const selectedMateriel = selectedVerteMateriels.find(
                      (selectedMateriel) => selectedMateriel.materiel._id === materiel._id
                    );
                    const isMaterielSelected = Boolean(selectedMateriel);
                    const nbPerceptionValue = isMaterielSelected && selectedMateriel && selectedMateriel.materiel && selectedMateriel.materiel.nbperception ? selectedMateriel.materiel.nbperception : 1;

                    return (
                      <ListItem disableGutters key={materiel._id}>
                        <ListItemAvatar>
                          <Avatar src={materiel.imageperception} />
                        </ListItemAvatar>
                        <ListItemText primary={materiel.nom} />
                        <Checkbox
                          checked={isMaterielSelected}
                          onChange={() => handleMaterielSelection(materiel._id, 'Verte')}
                        />
                        {isMaterielSelected && (
                          <TextField
                            id={`nbPerception_${materiel._id}`}
                            label="Nombre en perception"
                            type="number"
                            value={nbPerceptionValue}
                            onChange={(e) => handleNbPerceptionChange(e, materiel.materiel._id, 'Verte')}
                            sx={{ marginLeft: '10px', width: 40 }}
                          />
                        )}
                      </ListItem>
                    );
                  })}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseVerteDialog}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Button variant="text" startIcon={<MasksIcon />} onClick={handleOpenCNPDialog}>
            Matériel CNP
          </Button>
          <Dialog onClose={handleCloseCNPDialog} open={openCNPDialog}>
            <DialogTitle>Matériel CNP</DialogTitle>
            <DialogContent>
              <List>
                {materiels
                  .filter((materiel) => materiel.categorie === 'CNP')
                  .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
                  .map((materiel) => {
                    const selectedMateriel = selectedCNPMateriels.find(
                      (selectedMateriel) => selectedMateriel.materiel._id === materiel._id
                    );
                    const isMaterielSelected = Boolean(selectedMateriel);
                    const nbPerceptionValue = isMaterielSelected && selectedMateriel && selectedMateriel.materiel && selectedMateriel.materiel.nbperception ? selectedMateriel.materiel.nbperception : 1;

                    return (
                      <ListItem disableGutters key={materiel._id}>
                        <ListItemAvatar>
                          <Avatar src={materiel.imageperception} />
                        </ListItemAvatar>
                        <ListItemText primary={materiel.nom} />
                        <Checkbox
                          checked={isMaterielSelected}
                          onChange={() => handleMaterielSelection(materiel._id, 'CNP')}
                        />
                        {isMaterielSelected && (
                          <TextField
                            id={`nbPerception_${materiel._id}`}
                            label="Nombre en perception"
                            type="number"
                            value={nbPerceptionValue}
                            onChange={(e) => handleNbPerceptionChange(e, materiel.materiel._id, 'CNP')}
                            sx={{ marginLeft: '10px', width: 40 }}
                          />
                        )}
                      </ListItem>
                    );
                  })}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCNPDialog}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Button variant="text" startIcon={<LocalFireDepartmentIcon />} onClick={handleOpenInterpelDialog}>
            Matériel Interpel / Armement
          </Button>
          <Dialog onClose={handleCloseInterpelDialog} open={openInterpelDialog}>
            <DialogTitle>Matériel Interpel / Armement</DialogTitle>
            <DialogContent>
              <List>
                {materiels
                  .filter((materiel) => materiel.categorie === 'Interpel / Armement')
                  .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
                  .map((materiel) => {
                    const selectedMateriel = selectedInterpelMateriels.find(
                      (selectedMateriel) => selectedMateriel.materiel._id === materiel._id
                    );
                    const isMaterielSelected = Boolean(selectedMateriel);
                    const nbPerceptionValue = isMaterielSelected && selectedMateriel && selectedMateriel.materiel && selectedMateriel.materiel.nbperception ? selectedMateriel.materiel.nbperception : 1;

                    return (
                      <ListItem disableGutters key={materiel._id}>
                        <ListItemAvatar>
                          <Avatar src={materiel.imageperception} />
                        </ListItemAvatar>
                        <ListItemText primary={materiel.nom} />
                        <Checkbox
                          checked={isMaterielSelected}
                          onChange={() => handleMaterielSelection(materiel._id, 'Interpel / Armement')}
                        />
                        {isMaterielSelected && (
                          <TextField
                            id={`nbPerception_${materiel._id}`}
                            label="Nombre en perception"
                            type="number"
                            value={nbPerceptionValue}
                            onChange={(e) => handleNbPerceptionChange(e, materiel.materiel._id, 'Interpel / Armement')}
                            sx={{ marginLeft: '10px', width: 40 }}
                          />
                        )}
                      </ListItem>
                    );
                  })}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseInterpelDialog}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Button variant="text" startIcon={<DirectionsCarIcon />} onClick={handleOpenMobiliteDialog}>
            Matériel Mobilité
          </Button>
          <Dialog onClose={handleCloseMobiliteDialog} open={openMobiliteDialog}>
            <DialogTitle>Matériel Mobilité</DialogTitle>
            <DialogContent>
              <List>
                {materiels
                  .filter((materiel) => materiel.categorie === 'Mobilité')
                  .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
                  .map((materiel) => {
                    const selectedMateriel = selectedMobiliteMateriels.find(
                      (selectedMateriel) => selectedMateriel.materiel._id === materiel._id
                    );
                    const isMaterielSelected = Boolean(selectedMateriel);
                    const nbPerceptionValue = isMaterielSelected && selectedMateriel && selectedMateriel.materiel && selectedMateriel.materiel.nbperception ? selectedMateriel.materiel.nbperception : 1;

                    return (
                      <ListItem disableGutters key={materiel._id}>
                        <ListItemAvatar>
                          <Avatar src={materiel.imageperception} />
                        </ListItemAvatar>
                        <ListItemText primary={materiel.nom} />
                        <Checkbox
                          checked={isMaterielSelected}
                          onChange={() => handleMaterielSelection(materiel._id, 'Mobilité')}
                        />
                        {isMaterielSelected && (
                          <TextField
                            id={`nbPerception_${materiel._id}`}
                            label="Nombre en perception"
                            type="number"
                            value={nbPerceptionValue}
                            onChange={(e) => handleNbPerceptionChange(e, materiel.materiel._id, 'Mobilité')}
                            sx={{ marginLeft: '10px', width: 40 }}
                          />
                        )}
                      </ListItem>
                    );
                  })}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMobiliteDialog}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Button variant="text" startIcon={<ConstructionIcon />} onClick={handleOpenDiversDialog}>
            Matériel Divers
          </Button>
          <Dialog onClose={handleCloseDiversDialog} open={openDiversDialog}>
            <DialogTitle>Matériel Divers</DialogTitle>
            <DialogContent>
              <List>
                {materiels
                  .filter((materiel) => materiel.categorie === 'Divers')
                  .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
                  .map((materiel) => {
                    const selectedMateriel = selectedDiversMateriels.find(
                      (selectedMateriel) => selectedMateriel.materiel._id === materiel._id
                    );
                    const isMaterielSelected = Boolean(selectedMateriel);
                    const nbPerceptionValue = isMaterielSelected && selectedMateriel && selectedMateriel.materiel && selectedMateriel.materiel.nbperception ? selectedMateriel.materiel.nbperception : 1;

                    return (
                      <ListItem disableGutters key={materiel._id}>
                        <ListItemAvatar>
                          <Avatar src={materiel.imageperception} />
                        </ListItemAvatar>
                        <ListItemText primary={materiel.nom} />
                        <Checkbox
                          checked={isMaterielSelected}
                          onChange={() => handleMaterielSelection(materiel._id, 'Divers')}
                        />
                        {isMaterielSelected && (
                          <TextField
                            id={`nbPerception_${materiel._id}`}
                            label="Nombre en perception"
                            type="number"
                            value={nbPerceptionValue}
                            onChange={(e) => handleNbPerceptionChange(e, materiel.materiel._id, 'Divers')}
                            sx={{ marginLeft: '10px', width: 40 }}
                          />
                        )}
                      </ListItem>
                    );
                  })}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDiversDialog}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Button variant="text" startIcon={<SettingsInputAntennaIcon />} onClick={handleOpenSICDialog}>
            Matériel SIC
          </Button>
          <Dialog onClose={handleCloseSICDialog} open={openSICDialog}>
            <DialogTitle>Matériel SIC</DialogTitle>
            <DialogContent>
              <List>
                {materiels
                  .filter((materiel) => materiel.categorie === 'SIC')
                  .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
                  .map((materiel) => {
                    const selectedMateriel = selectedSICMateriels.find(
                      (selectedMateriel) => selectedMateriel.materiel._id === materiel._id
                    );
                    const isMaterielSelected = Boolean(selectedMateriel);
                    const nbPerceptionValue = isMaterielSelected && selectedMateriel && selectedMateriel.materiel && selectedMateriel.materiel.nbperception ? selectedMateriel.materiel.nbperception : 1;

                    return (
                      <ListItem disableGutters key={materiel._id}>
                        <ListItemAvatar>
                          <Avatar src={materiel.imageperception} />
                        </ListItemAvatar>
                        <ListItemText primary={materiel.nom} />
                        <Checkbox
                          checked={isMaterielSelected}
                          onChange={() => handleMaterielSelection(materiel._id, 'SIC')}
                        />
                        {isMaterielSelected && (
                          <TextField
                            id={`nbPerception_${materiel._id}`}
                            label="Nombre en perception"
                            type="number"
                            value={nbPerceptionValue}
                            onChange={(e) => handleNbPerceptionChange(e, materiel.materiel._id, 'SIC')}
                            sx={{ marginLeft: '10px', width: 40 }}
                          />
                        )}
                      </ListItem>
                    );
                  })}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseSICDialog}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
        <div>
          <Button variant="text" startIcon={<LocationOnIcon />} onClick={handleOpenTrackDialog}>
            Matériel Track
          </Button>
          <Dialog onClose={handleCloseTrackDialog} open={openTrackDialog}>
            <DialogTitle>Matériel Track</DialogTitle>
            <DialogContent>
              <List>
                {materiels
                  .filter((materiel) => materiel.categorie === 'Track')
                  .sort((a, b) => (b.imageperception ?? '').localeCompare(a.imageperception ?? ''))
                  .map((materiel) => {
                    const selectedMateriel = selectedTrackMateriels.find(
                      (selectedMateriel) => selectedMateriel.materiel._id === materiel._id
                    );
                    const isMaterielSelected = Boolean(selectedMateriel);
                    const nbPerceptionValue = isMaterielSelected && selectedMateriel && selectedMateriel.materiel && selectedMateriel.materiel.nbperception ? selectedMateriel.materiel.nbperception : 1;

                    return (
                      <ListItem disableGutters key={materiel._id}>
                        <ListItemAvatar>
                          <Avatar src={materiel.imageperception} />
                        </ListItemAvatar>
                        <ListItemText primary={materiel.nom} />
                        <Checkbox
                          checked={isMaterielSelected}
                          onChange={() => handleMaterielSelection(materiel._id, 'Track')}
                        />
                        {isMaterielSelected && (
                          <TextField
                            id={`nbPerception_${materiel._id}`}
                            label="Nombre en perception"
                            type="number"
                            value={nbPerceptionValue}
                            onChange={(e) => handleNbPerceptionChange(e, materiel.materiel._id, 'Track')}
                            sx={{ marginLeft: '10px', width: 40 }}
                          />
                        )}
                      </ListItem>
                    );
                  })}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseTrackDialog}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
        

        <div className="form-group text-center">
          <Button variant="contained" color="primary" type="submit" className="btn btn-primary">
            Enregistrer
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EditPerception;